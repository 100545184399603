import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../env";
const ProfessionalProfileEditModal = ({ profileData, onClose, onSave }) => {
  const [editedProfileData, setEditedProfileData] = useState({
    ...profileData,
  });
  const [professionalCategories, setProfessionalCategories] = useState([]);

  useEffect(() => {
    const fetchProfessionalCategories = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/admin/professional/category`
        );
        // console.log(response.data,"professional category")
        setProfessionalCategories(response.data);
      } catch (error) {
        console.error("Error fetching professional categories:", error.message);
      }
    };

    fetchProfessionalCategories();
  }, []);
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setEditedProfileData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    console.log("edited profile data from modal", editedProfileData);
    onSave(editedProfileData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Your Profile Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          
          {/* <Form.Group controlId="storename">
            <Form.Label>Store Name</Form.Label>
            <Form.Control
              type="text"
              value={editedProfileData.storename}
              onChange={(e) => handleInputChange(e, "storename")}
            />
          </Form.Group> */}
          <Form.Group controlId="professionalcategory">
            <Form.Label>Professional Category</Form.Label>
            <Form.Control
              as="select"
              value={editedProfileData.professionalcategory}
              onChange={(e) => handleInputChange(e, "professionalcategory")}
            >
              <option value="" disabled>
                Select Professional Category
              </option>
              {professionalCategories.map((category) => (
                <option key={category.id} value={category.professionalCategory}>
                  {category.professionalCategory}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="serviceDetails">
            <Form.Label>Service Detail</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editedProfileData.serviceDetails}
              onChange={(e) => handleInputChange(e, "serviceDetails")}
            />
          </Form.Group>
          {/* <Form.Group controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              value={editedProfileData.price}
              onChange={(e) => handleInputChange(e, "price")}
            />
          </Form.Group> */}
          <Form.Group controlId="years_of_experience">
            <Form.Label> Years Of Experience</Form.Label>
            <Form.Control
              type="number"
              value={editedProfileData.years_of_experience}
              onChange={(e) => handleInputChange(e, "years_of_experience")}
            />
          </Form.Group>

          <Form.Group controlId="reviews">
            <Form.Label>Review</Form.Label>
            <Form.Control type="text" value="No Reviews" readOnly />
          </Form.Group>

          <Form.Group controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              value={editedProfileData.location}
              onChange={(e) => handleInputChange(e, "location")}
            />
          </Form.Group>

          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="number"
              value={editedProfileData.phoneNumber}
              onChange={(e) => handleInputChange(e, "phoneNumber")}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-danger" onClick={onClose}>
          Cancel
        </Button>
        <Button className="bg-primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfessionalProfileEditModal;
