import React, { useState, useEffect } from 'react';
import AdminNavigation from '../../AdminNavigation';
import { AdminSidebarMain,IoMdArrowRoundBack} from '../../../../imports';
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import '../../AdminDashboard.css';
import '../../demo/AdminSidebar.css';

import { useNavigate } from 'react-router-dom';

const ProfessionalUnverifiedList = () => {
  const navigate = useNavigate();
  const [professionals, setProfessionals] = useState([]);
  // const [isDashboardHeaderVisible, setDashboardHeaderVisible] = useState(true);

  // const toggleNavigation = () => {
  //   setDashboardHeaderVisible(!isDashboardHeaderVisible);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/professional/unverified/list`);
        setProfessionals(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleView = (id) => {
    const selectedProfessional = professionals.find((professional) => professional.id === id);
    navigate('/admin/unverified/professional/detailing', { state: { professional: selectedProfessional } });
  };

  return (
    <div className="admin-dashboard-page">
      <AdminSidebarMain />
    

      <h2 className="text-center admin-title-heading">Unauthorized Professional List</h2>


      <div className="dashboardmain">

        {/* table */}
        <div className="responsive-table mt-2 custom-overflow-x">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Location</th>
                  {/* <th>Verification</th> */}
                  <th>Store Name</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {professionals.map((professional) => (
                  <tr key={professional.id} className="pt-3">
                    <td>{professional.name}</td>
                    <td>{professional.phoneNumber}</td>
                    <td>{professional.location}</td>
                    <td>{professional.storename}</td>
                    <td>
                      <button className="btn btn-primary" onClick={() => handleView(professional.id)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalUnverifiedList;
