import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import { useNavigate } from "react-router-dom";
import {
  IoMdArrowRoundBack, 
} from "../../../../imports";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfessionalCategoryListing from "./ProfessionalCategoryListing";


const ProfessionalMasterHome = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [existingCategories, setExistingCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);


  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/professional/categories`);
      console.log(response.data,"in professional master home")
      setCategories(response.data);
      setExistingCategories(response.data.map(category => category.professionalCategory));
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCategoryImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  const handleAddCategory = () => {
    console.log(categoryImage,"category iameg in profesional")
    if (!categoryName || !categoryImage) {
      toast.error("Please select both category name and image.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const formData = new FormData();
    formData.append("professionalCategory", categoryName);
    formData.append("categoryimg", categoryImage);

    axios
      .post(`${BASE_URL}/professional/add/category`, formData)
      .then((response) => {
        toast.success("Category added successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchCategories();
        setShowModal(false);
        setCategoryName(null);
        setCategoryImage(null);
        setPreviewImage(null);
      
      })
      .catch((error) => {
        toast.error("Error in adding category", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  return (
    <div>
    <p
      className="d-flex align-items-center  cursor-pointer "
      onClick={() => navigate("/admin/master/category")}
    >
      {" "}
      <IoMdArrowRoundBack className="" />
      <ins>Back</ins>
    </p>

    <h2 className="text-center admin-title-heading mt-2">
      Add Categories to Professionals
    </h2>
    <Button onClick={handleShowModal} className="m-4 bg-primary">
      + Add
    </Button>
    <ProfessionalCategoryListing
      categories={categories}
      fetchCategories={fetchCategories}
    />

    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <p className="add-martcategory-title">Add Main Category</p>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <p className="add-martcategory-label">Category Name</p>
          <Form.Control
            type="text"
            placeholder="Enter Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <p className="add-martcategory-label">Category Image</p>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>
        {previewImage && (
          <img
            src={previewImage}
            alt="Preview"
            style={{ maxWidth: "100%", marginTop: "10px" }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="bg-danger" onClick={handleCloseModal}>
          Close
        </Button>

        <Button className="bg-primary" onClick={handleAddCategory}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
    <ToastContainer />
  </div>
  )
}

export default ProfessionalMasterHome