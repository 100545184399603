import React from "react";
import { Card, Button } from "react-bootstrap";
import "../vegetablesession/ProfileCreation.css";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { FcCurrencyExchange } from "react-icons/fc";
import { FcBookmark } from "react-icons/fc";
import {
  MdOutlineProductionQuantityLimits,
  MdOutlineEmojiTransportation,
} from "react-icons/md";
const TransportProductCard = ({ service, onEdit, onDelete }) => {
  return (
    <div>
      <div className="vendorProduct-box">
        <div className="vendorProduct-icon">
          <MdOutlineEdit
            className="vendorProduct-edit "
            onClick={() => onEdit(service)}
          />
          <MdOutlineDelete
            className="vendorProduct-delete"
            onClick={() => onDelete(service.id)}
          />
        </div>
        <h6 className="vendorProduct-heading d-flex gap-1">
          <span className="service-heading-vendor d-flex gap-1">
            <MdOutlineEmojiTransportation
              className="vendorProduct-iconbar"
              color="#FDBF60"
            />
            {service.category}
          </span>
        </h6>

        <div className=" d-flex gap-1 p-1">
          <span>
            <FcBookmark
              className="vendorProduct-iconbar"
              style={{ color: "#294B29" }}
            />
          </span>
          <span>
            <span className="service-heading-vendor">Service : </span>
            {service.vehicle_service}
          </span>
        </div>

        <div className=" d-flex gap-1 p-1">
          <span>
            <FcCurrencyExchange
              className="vendorProduct-iconbar"
              color="#4169E1"
            />
          </span>
          <span>
            <span className="service-heading-vendor">Price : </span>
            {service.rate}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TransportProductCard;
