import React,{useState,useEffect} from 'react'
import "../../vegetablesession/postlogin/StoreDetail.css";
import { professionalProfileValidationSchema ,professionals, SuccessModal, FailureModal, } from "../../../../imports";
import { useVendorAuth } from '../../VendorAuthContext';
import { useFormik } from "formik";
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import CallRequestModal from './CallRequestModal';


const ProfessionalProfileDetail = () => {
  const { vendorUser } = useVendorAuth();
  const [professionalCategories, setProfessionalCategories] = useState([]);
  const [callRequestModalOpen, setCallRequestModalOpen] = useState(false);
  
  const fetchProfessionalCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/professional/category`); 
      // console.log(response.data,"professional category")
      setProfessionalCategories(response.data);
    } catch (error) {
      console.error('Error fetching professional categories:', error.message);
    }
  };
  useEffect(() => {
  

    fetchProfessionalCategories();
  }, []);
  const libraries = ['places'];
  const mapContainerStyle = {
    width: '100%',
    height: '300px',
    marginTop: "10px",
  };
  const cityCoordinates = {
    Dindigul: { lat: 10.3659, lng: 77.9695 },
    Palani: { lat: 10.4413, lng: 77.5205 },
    Coimbatore: { lat: 11.0168, lng: 76.9558 },
    Pollachi: { lat: 10.6576, lng: 77.0107 },
  };

  const city = vendorUser.city || 'Palani';
  const defaultCityCenter = cityCoordinates[city] || { lat: 10.4413, lng: 77.5205 };

  const [mapCenter, setMapCenter] = useState(defaultCityCenter);
  const [markerPosition, setMarkerPosition] = useState(null);


  const formik = useFormik({
    initialValues: {
      professionalcategory: '',  
      reviews: 0,
      price: 0,
      serviceDetails: '',
      your_location: vendorUser.location ,  
      number: vendorUser.phoneNumber ,
      direction: '',
      years_of_experience: 0, 
    },
    validationSchema: professionalProfileValidationSchema,  
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  
  const handleSubmit = async (values) => {
    try {
      const formData = {
        ...values,
        user_id: vendorUser.id,
        category: vendorUser.category,
      };
      const response = await axios.post(`${BASE_URL}/vendor/professional/profile/create`, formData);

      if (response.status === 200) {
        setSuccessMessage(response.data.message)
        setSuccessModalOpen(true);
      } else {
        console.error('Error creating profile:', response.statusText);
        setErrorMessage("Error in Creating Profile. Please try again.");
        setFailureModalOpen(true)
      }
    } catch (error) {
      console.error('Error creating profile:', error.message);
    }
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);

  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);

  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB9HRWEcSk53RuyZC_TJ2EsqC93hUnzqns',
    libraries,
  });

  const handleMapClick = (event) => {
    const { lat, lng } = event.latLng.toJSON();
    formik.setValues({
      ...formik.values,
      direction: `${lat}, ${lng}`, 
    });
    setMarkerPosition({ lat, lng }); 
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div className='store-detail-page'>
    <div className='store-form-area'>
      <div className='container'>
        {successModalOpen && <SuccessModal onClose={closeSuccessModal} message={successMessage} redirectTo="/vendor/professional/image" />}
        {failureModalOpen && <FailureModal onClose={closeFailureModal} message={errorMessage} />}
        <div className='row store-detailform g-0'>
          <div className='col-sm-12 col-lg-6'>
            <div className='store-left d-flex flex-column'>
            <h2 className=" text-center" style={{fontSize:"22px"}}>
                  <span> Experience a new level of success  in your professional services  </span> <br /> with Whistles{" "}
                </h2>
              <img src={professionals} className='veg-form-img img-fluid fade-in' />
           
              
            </div>
            
          </div>
          {/* right content */}
          <div className='col-sm-12 col-lg-6'>
            <div className='store-right'>
              <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                    <label htmlFor="professionalcategory" className="form-label">
                      Professional Category
                    </label>
                    <select
                      id="professionalcategory"
                      name="professionalcategory"
                      className="form-select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.professionalcategory}
                      onClick={()=>fetchProfessionalCategories()}
                    >
                      <option value="" disabled>Select Professional Category</option>
                      {professionalCategories.map((category) => (
                      <option key={category.id} value={category.professionalCategory}>
                        {category.professionalCategory}
                      </option>
                    ))}
                    </select>
                    {formik.touched.professionalcategory && formik.errors.professionalcategory && (
                      <div className="error">{formik.errors.professionalcategory}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="serviceDetails" className="form-label">
                      Service Detail
                    </label>
                    <input
                      type="text"
                      id="serviceDetails"
                      name="serviceDetails"
                      placeholder="Service Details"
                      className="form-control py-4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceDetails}
                    />
                    {formik.touched.serviceDetails && formik.errors.serviceDetails && (
                      <div className="error">{formik.errors.serviceDetails}</div>
                    )}
                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      pattern="[0-9]+([.,][0-9]+)?"
                      placeholder="Price"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className="error">{formik.errors.price}</div>
                    )}
                  </div> */}
                  <div className="mb-3">
                  <label htmlFor="years_of_experience" className="form-label">
                    Years of Experience
                  </label>
                  <input
                    type="number"
                    id="years_of_experience"
                    name="years_of_experience"
                    placeholder="Years of Experience"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.years_of_experience}
                  />
                  {formik.touched.years_of_experience && formik.errors.years_of_experience && (
                    <div className="error">{formik.errors.years_of_experience}</div>
                  )}
                </div>
                  <div className="mb-3">
                    <label htmlFor="your_location" className="form-label">
                      Your Location
                    </label>
                    <input
                      type="text"
                      id="your_location"
                      name="your_location"
                      placeholder="Your Location"
                      className="form-control py-4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.your_location}
                    />
                    {formik.touched.your_location && formik.errors.your_location && (
                      <div className="error">{formik.errors.your_location}</div>
                    )}
                  </div>
       
                  <div className="mb-3">
                    <label htmlFor="number" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="number"
                      name="number"
                      placeholder="Phone Number"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.number}
                    />
                    {formik.touched.number && formik.errors.number && (
                      <div className="error">{formik.errors.number}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="direction" className="form-label">
                      Google Maps Direction Link
                    </label>
                    <input
                      type="text"
                      id="direction"
                      name="direction"
                      placeholder="Enter the Google Maps direction link for your store"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.direction}
                    />
                    {formik.touched.direction && formik.errors.direction && (
                      <div className="error">{formik.errors.direction}</div>
                    )}
                  </div>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={10}
                    center={mapCenter}
                    onClick={handleMapClick}
                  >
                    {markerPosition && <Marker position={markerPosition} />}
                  </GoogleMap>
                  <div className='d-flex justify-between mt-3'>
                  <button type="submit" className="btn bg-primary mt-3 text-white">
                  Submit
                </button>
                <p className='cursor-pointer text-primary ' onClick={() => setCallRequestModalOpen(true)}>Make a Call Request ?</p>
                  </div>
               
              </form>
           
            </div>
          </div>
        </div>
      </div>
    </div>
    {callRequestModalOpen && <CallRequestModal onClose={()=>setCallRequestModalOpen(false)}/>}
  </div>
  )
}

export default ProfessionalProfileDetail