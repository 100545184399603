import React, { useState } from 'react';
import "./onboardform.css";
import leftimg from '../../assets/image/1.png';
import axios from "axios";
import { BASE_URL } from '../../env';
import Swal from 'sweetalert2';

const OnboardForm = () => {

  const [formData, setFormData] = useState({
    name: '',
    dob: '',
    phone_number: "",
    email: '',
    location: "",
    voice:"",
  })
  const [loading, setLoading] = useState(false);
  const [dobInputType, setDobInputType] = useState('text');

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  };
  const handleDobInputFocus = () => {
    setDobInputType('date');
  };

  const handleDobInputBlur = () => {
    setDobInputType('text');
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("formvalues",formData)
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/user/onboard`, formData)
      if (response.status === 200) {
        console.log('User onboarded successfully');

        Swal.fire('Regsitered Successfully');
        setFormData({
          name: '',
          dob: '',
          phone_number: "",
          email: '',
          location: "",
          voice:""
        })
      } else {
        console.error('Error in user onboarding:', response.statusText);

      }
    } catch (error) {
      console.log('Error in user onboarding:', error.message)
    } finally {
      setLoading(false);
    }
  }
  return (
    // Main Container
    <div className='container d-flex justify-content-center align-items-center min-vh-100'>

      {/* Login container */}

      <div className='row border rounded-5 p-3 bg-white shadow box-area'>

        {/* left box */}
        <div className='col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column  left-box' style={{ backgroundColor: "#103cbe" }}>
          <div className='featured-image mb-3'>
            <img src={leftimg} alt="form-img" className='img-fluid' style={{ width: "220px" }} />
          </div>
          <p className='text-white fs-2' style={{ fontFamily: "Courier New", fontWeight: 700 }}>Get Verified</p>
          <small className='text-white text-wrap text-center' style={{ width: "17em", fontFamily: "Courier New" }}>Join Webble Community Platform</small>
        </div>
        {/* Right Box */}
        <div className='col-md-6 right-box'>
          <form onSubmit={handleSubmit}>
            <div className='row align-items-center'>
              <div className='header-text mb-4'>
                <h2>Webble Way</h2>
                <p>We are happy to have you in Webble Community !!</p>
              </div>
              <div className='input-group mb-3'>
                <input type='text' className='form-control form-control-lg bg-light fs-6'
                  placeholder='Name' name='name'
                  value={formData.name}
                  onChange={handleInputChange} />
              </div>
              <div className='input-group mb-3'>
                <input type={dobInputType} className='form-control form-control-lg bg-light fs-6' placeholder='Date of Birth'
                  onFocus={handleDobInputFocus}
                  onBlur={handleDobInputBlur}
                  name='dob' value={formData.dob} onChange={handleInputChange} />
              </div>
              <div className='input-group mb-3'>
                <input type='number' className='form-control form-control-lg bg-light fs-6' placeholder='Phone Number'
                  name='phone_number' value={formData.phone_number} onChange={handleInputChange} />
              </div>
              <div className='input-group mb-3'>
                <input type='email' className='form-control form-control-lg bg-light fs-6' placeholder='Email'
                  name='email' value={formData.email} onChange={handleInputChange} />
              </div>
              <div className='input-group mb-3'>
                <input type='text' className='form-control form-control-lg bg-light fs-6' placeholder='Location'
                  name='location' value={formData.location} onChange={handleInputChange} />
              </div>
              <div className='input-group  mb-3'>
                <textarea type='text' className='form-control form-control-lg bg-light fs-6 ' placeholder='Your Voice' 
                 style={{ overflow: "hidden", wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                  name='voice' value={formData.voice} onChange={handleInputChange} />
              </div>
              <div className='input-group mb-3'>
                <button className='btn btn-lg btn-primary w-100 fs-6'>  {loading ? 'Submitting...' : 'Submit'}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default OnboardForm