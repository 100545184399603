import React, { useState, useEffect } from 'react';
import "./Vegetables.css";
import { vegbanner, InstaStories, FaCircleCheck, vegdata, FaStore, MdReviews, IoIosPricetag, FaLocationDot, MdOutlinePhoneAndroid, MdChecklistRtl,FaStar
,FaMapPin,FaRegStar } from '../../../imports';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Modal, Button } from "react-bootstrap";

const Vegetables = () => {
  const [activeStoreIndex, setActiveStoreIndex] = useState(0);
  const [filteredStores, setFilteredStores] = useState([]);
  const [showStoriesModal, setShowStoriesModal] = useState(false);
  const googleMapsLink = `https://www.google.com/maps/place`;

  const selectedLocation = localStorage.getItem('selectedLocation');

  const handleStoreChange = (index) => {
    setActiveStoreIndex(index);
  };


  const handleViewStories = () => {
    setShowStoriesModal(true);
  };

  const handleCloseStoriesModal = () => {
    setShowStoriesModal(false);
  };

  useEffect(() => {
    if (selectedLocation) {
      const filtered = vegdata.stores.filter(store => store.location === selectedLocation);
      setFilteredStores(filtered)
    } else {
      setFilteredStores(vegdata.stores)
    }
  }, [selectedLocation])

  return (
    <>
      <div className='veg-container'>
        <div className='contain-box'>
          <div className='container veg-content-category'>
            <div className='row'>
              <div className='veg-left col-lg-6 col-md-6 col-sm-12'>
                <img src={vegbanner} className='img-fluid' />
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 veg-right'>
                <h3 >Why Choose Us ?</h3>
                <div className='veg-para'>
                  <p>
                    Lorem Ipsum is simply dummy text of the since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.

                  </p>
                  <div className='veg-points'>
                    <div className='d-flex  align-items-center'>
                      <FaCircleCheck className='veg-icon' />
                      <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
                    </div>
                    <div className='d-flex  align-items-center'>
                      <FaCircleCheck className='veg-icon' />
                      <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
                    </div>
                    <div className='d-flex  align-items-center'>
                      <FaCircleCheck className='veg-icon' />
                      <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='row  store-contain-veguser'>
          {filteredStores.map((store, index) => (
            <div key={index} className={`store-info col-lg-3  col-md-6 col-sm-12 ${activeStoreIndex === index ? 'active' : ''}`}>
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {store.images.map((image, i) => (
                  <SwiperSlide key={i}>
                    <img src={image} alt={`Store Image ${i + 1}`} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='mt-3'>
                <p className='store-points text-end text-success pointer' onClick={handleViewStories}>View Stories</p>
              </div>
              {/* <InstaStories stories={store.images}/> */}
              <div onClick={() => handleStoreChange(index)} className='mt-3'>
                <div className='store-details'>
                  <FaStore style={{ color: "#FF5C5C" }} />
                  <h3 className='mx-2 store-heading'>{store.storeName}</h3>
                </div>
                <div className='store-details mt-2'>
                  < MdReviews style={{ color: "#5A4FCF" }} />
                  <div className='d-flex align-items-center'>
                  <p className='store-points mx-2'>Review :</p>
                  <div className='d-flex'>
                  <FaStar style={{ color: "#ffa600" }} />
                  <FaStar style={{ color: "#ffa600" }} />
                  <FaStar style={{ color: "#ffa600" }} />
                  <FaStar style={{ color: "#ffa600" }} />
                  <FaRegStar  style={{ color: "#ffa600" }} />
                    </div>
               
                    </div>
                  
                </div>
                <div className='store-details mt-2'>
                  < IoIosPricetag style={{ color: "#50C878" }} />
                  <p className='store-points mx-2'> Price :<span className='store-detail-point mx-2'>{store.price}</span></p>
                </div>
                <div >
              
                <div className='service-details mt-3 d-flex align-items-center'>
                <  MdChecklistRtl style={{ color: "#4169E1" }} />
                  <p className='store-detail-point' ><span className='store-points mx-2'>Description :</span><br/> </p>
                </div>
                <p  className='store-detail-point mx-2'>{store.serviceDetails}</p>
                </div>

                <div className="mt-2">
                  <div className='d-flex align-items-center'>
                    < FaLocationDot style={{ color: "#4169E1" }} />
                    <p className='store-points  mx-2'>Location : </p>
                  </div>
                  <p style={{ textIndent: "20px" }}>{store.location}</p>
                </div>
                <div className='store-details mt-2'>
                  <  MdOutlinePhoneAndroid style={{ color: "#FF9C34" }} />
                  <p className='store-points mx-2'> Phone No :<span className='store-detail-point mx-2'>{store.number}</span></p>
                </div>
                <div className='store-details mt-2'>
                  < FaMapPin style={{ color: "#bc5090" }} />
                  <p className='store-points mx-2'>  <span className='store-detail-point mx-2'> <a href={googleMapsLink} className='text-primary' target="_blank" rel="noopener noreferrer">
              Open in Google Maps
            </a></span></p>
                </div>
              </div>
            </div>
          ))}
        </div>
         {/* Include the Google Maps iframe */}
      <div className="google-map d-none" >
        <iframe
          title="Google Map"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.672179161021!2d77.97271067481402!3d10.368074966593529!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab4ba0a67f8f%3A0xe8c929fa6a6e6e95!2sVirtual%20Technologies!5e0!3m2!1sen!2sin!4v1705651415463!5m2!1sen!2sin`}
          allowFullScreen
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      </div>

      <Modal show={showStoriesModal} onHide={handleCloseStoriesModal}>
        <Modal.Body>
          <InstaStories stories={filteredStores[activeStoreIndex]?.images || []} />
        </Modal.Body>
        <Modal.Footer>
          <p className="text-danger pointer" onClick={handleCloseStoriesModal}>
            Close
          </p>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Vegetables