import React from 'react';
import "./ResponseModal.css";


const VendorImgReplaceModal = ({ imageUrl, onClose, handleImgUpload}) => {
  const handleUpload = async () => {
    
    handleImgUpload(imageUrl);
  };

  return (
    <div className='vendor-veg-img-overlay'>
      <div className='vendor-veg-img-content'>
        <div className='img-border'>
          <img
            src={imageUrl}
            alt={`Selected Image`}
            className='img-fluid'
          />
        </div>
        <div className='d-flex justify-content-center'>
        <button onClick={onClose} className='store-pto-replace mt-4'>
         Cancel
        </button>
        <button onClick={handleUpload} className='store-img-cls mt-4 mx-2'>
        Upload
        </button>
      
          </div>
        
      </div>
    </div>
  );
};

export default VendorImgReplaceModal ;
