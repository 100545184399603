import React, { useEffect, useState, useRef } from "react";
import { useVendorAuth } from "../VendorAuthContext";
import axios from "axios";
import { BASE_URL } from "../../../env";
import {
  VegetableVendorHeader,
  VendorProfileMessageModal,
  FaEdit,
  vegetable,
  vegdata,
  FaStore,
  MdReviews,
  IoIosPricetag,
  FaLocationDot,
  MdOutlinePhoneAndroid,
  MdChecklistRtl,
  FaStar,
  FaMapPin,
  VegetableVendorViewImage,
  ConfirmationModal,
  StoryPreviewModal,
  VendorStoryDisplayModal,
  AddVegetableProduct,
  EditVegProfileCardModal,
} from "../../../imports";
// import "../../categories/vegetables/Vegetables.css";
import "./ProfileCreation.css";
import { debounce } from "lodash";

const ProfileCreation = () => {
  const { vendorUser } = useVendorAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const [profileData, setprofileData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  //  image viewing

  const [vendorImgModal, setvendorImgModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  // story state
  const [storySelectedFile, setstorySelectedFile] = useState(null);
  const [storyViewModal, setstoryViewModal] = useState(false);

  // profile card edit modal
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const fileInputRef = useRef(null);

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const saveEditedProfile = async (editedData) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/vegetable/${vendorUser.id}/edit`,
        editedData
      );

      if (response.status === 200) {
        console.log("Profile updated successfully");

        setprofileData(editedData);
        closeEditModal();
      } else {
        console.error("Failed to update profile:", response.data);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  // vednor image modal

  useEffect(() => {
    openModal();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/vegetablestore/profile/detail/${vendorUser.id}`
      );

      //  console.log("response from vendoruser ",response.data)
      setprofileData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // fetchData();
  // image modal

  const openImageViewPopup = () => {
    setSelectedUserId(vendorUser.id);
    setvendorImgModal(true);
  };

  const openStoryViewPopup = () => {
    setstoryViewModal(!storyViewModal);
  };

  const handleAddStoryClick = () => {
    console.log("add story is clicked");
    const message = "Are you sure you want to add a story?";
    setConfirmationMessage(message);
    setConfirmModalOpen(true);
  };

  const handleStoryFile = () => {
    fileInputRef.current.click();
  };

  const videoRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;

    if (files.length === 0) {
      alert("Please select at least one file.");
      return;
    }

    let imageCount = 0;
    let videoCount = 0;

    for (const file of files) {
      if (file.type.includes("video")) {
        videoCount++;
        // Handle video file without appending to the body
        const video = document.createElement("video");
        const handleLoadedMetadata = debounce(() => {
          if (video.duration > 30) {
            alert(
              "Video duration exceeds 30 seconds. Please choose a shorter video."
            );
          } else {
            console.log("Selected Video:", file);
            setstorySelectedFile(file);
          }
        }, 200);

        video.addEventListener("loadedmetadata", handleLoadedMetadata);
        video.src = URL.createObjectURL(file);
        videoRef.current = video;
        video.load();
      } else if (file.type.includes("image")) {
        imageCount++;
        // Handle image file
        console.log("Selected Image:", file);
        setstorySelectedFile(file);
      } else {
        alert("Invalid file type. Please select an image or video.");
        return; // Exit early if an invalid file type is encountered
      }
    }

    if (imageCount === 0 && videoCount === 0) {
      alert("Please select at least one image or video.");
    } else if (imageCount > 5) {
      alert("Too many images selected. Please choose up to 5 images.");
    } else if (videoCount > 1) {
      alert("Please select only one video.");
    }
  };

  const handleStoryModalVisibility = () => {
    setstorySelectedFile(null);
  };

  return (
    <div>
      <VegetableVendorHeader />
      <div className="vendor-profile-content">
        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12 vendor-profile-left order-2 order-sm-2 order-md-2 order-lg-1">
            {vendorUser && (
              <div>
                {/* <p>ID: {vendorUser.id}</p>
                <p>Name: {vendorUser.name}</p> */}
                <AddVegetableProduct vendorUser={vendorUser} />
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12 vendor-profile-right order-1 order-sm-1 order-lg-1 order-md-1 my-5">
            <div className="container-fluid">
              <div className="row store-contain">
                <button
                  className="veg-add-story-btn"
                  onClick={handleAddStoryClick}
                >
                  Add Story
                </button>

                {/* card details */}
                <div className="mt-2 profile-veg-card">
                  <button
                    className="veg-vendor-profile-edit-btn"
                    onClick={openEditModal}
                  >
                    <FaEdit />
                  </button>
                  <div className="webble-img">
                    <img
                      src={`${BASE_URL}/vegetable/posterimage/${profileData.posterImage}`}
                      alt="Vendor Poster Image"
                      className="img-fluid"
                    />
                    <div className="overlay">
                      <div className="text">
                        <p className="img-btn" onClick={openImageViewPopup}>
                          View Image
                        </p>
                        <p
                          className="storie-btn mt-2"
                          onClick={openStoryViewPopup}
                        >
                          View Stories
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="store-details mt-2">
                    <FaStore style={{ color: "#FF5C5C" }} />
                    <h3 className="mx-2 store-heading">
                      {profileData.storeName}
                    </h3>
                  </div>
                  <div className="store-details mt-2">
                    <MdReviews style={{ color: "#5A4FCF" }} />
                    <div className="d-flex align-items-center">
                      <p>
                        <span className="store-points mx-2">Review :</span> No
                        Reviews{" "}
                      </p>
                      {/* <div className='d-flex'>
          <FaStar style={{ color: "#ffa600" }} />
          <FaStar style={{ color: "#ffa600" }} />
          <FaStar style={{ color: "#ffa600" }} />
          <FaStar style={{ color: "#ffa600" }} />
          <FaRegStar  style={{ color: "#ffa600" }} />
            </div> */}
                    </div>
                  </div>
                  {/* <div className="store-details mt-2">
                    <IoIosPricetag style={{ color: "#50C878" }} />
                    <p className="store-points mx-2">
                      {" "}
                      Price :
                      <span className="store-detail-point mx-2">
                        {profileData.price}
                      </span>
                    </p>
                  </div> */}
                  <div>
                    <div className="service-details mt-3 d-flex align-items-center">
                      <MdChecklistRtl style={{ color: "#4169E1" }} />
                      <p className="store-detail-point">
                        <span className="store-points mx-2">Description :</span>
                        <br />{" "}
                      </p>
                    </div>
                    <p className="store-detail-point mx-2">
                      {profileData.description}
                    </p>
                  </div>

                  <div className="mt-2">
                    <div className="d-flex align-items-center">
                      <FaLocationDot style={{ color: "#4169E1" }} />
                      <p className="store-points  mx-2">Location : </p>
                    </div>
                    <p style={{ textIndent: "20px" }}>{profileData.location}</p>
                  </div>
                  <div className="store-details mt-2">
                    <MdOutlinePhoneAndroid style={{ color: "#FF9C34" }} />
                    <p className="store-points mx-2">
                      {" "}
                      Phone No :
                      <span className="store-detail-point mx-2">
                        {profileData.phoneNumber}
                      </span>
                    </p>
                  </div>
                  <div className="store-details mt-2">
                    <FaMapPin style={{ color: "#bc5090" }} />
                    <p className="store-points mx-2">
                      {" "}
                      <span className="store-detail-point mx-2">
                        {" "}
                        <a
                          className="text-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <a
                            className="text-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://www.google.com/maps/search/?api=1&query=${profileData.direction}`}
                          >
                            Open in Google Maps
                          </a>
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <VendorProfileMessageModal onClose={closeModal} />}
      {vendorImgModal && (
        <VegetableVendorViewImage
          onClose={() => setvendorImgModal(false)}
          userId={vendorUser.id}
          posterImage={profileData.posterImage}
        />
      )}

      {confirmModalOpen && (
        <ConfirmationModal
          message={confirmationMessage}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleStoryFile}
        />
      )}

      {storySelectedFile && (
        <StoryPreviewModal
          file={storySelectedFile}
          onCancel={handleStoryModalVisibility}
        />
      )}
      {storyViewModal && (
        <VendorStoryDisplayModal onClose={openStoryViewPopup} />
      )}
      {isEditModalOpen && (
        <EditVegProfileCardModal
          profileData={profileData}
          onClose={closeEditModal}
          onSave={saveEditedProfile}
        />
      )}
      <input
        type="file"
        accept="image/*,video/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default ProfileCreation;
