import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import VegetableProductCard from './VegetableProductCard';

const AddVegetableProduct = ({ vendorUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [productName, setProductName] = useState('');
  const [category, setCategory] = useState('');
  const [discount, setDiscount] = useState('');
  const [rate, setRate] = useState('');
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [modalMode, setModalMode] = useState('add');
  const [editProductId, setEditProductId] = useState(null);

  const handleShowModal = () => {
    setShowModal(true);
    fetchCategories();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMode('add');
    setEditProductId(null);
    // Reset input fields
    setProductName('');
    setCategory('');
    setDiscount('');
    setRate('');
  };

  const handleAddProduct = async () => {
    if (!category || !productName || !rate || !discount) {
      alert('All fields are required.');
      return;
    }

    try {
      if (modalMode === 'add') {
        await axios.post(`${BASE_URL}/vegetable/addProduct`, {
          user_id: vendorUser.id,
          category,
          product_name: productName,
          rate,
          discount,
        });

        handleCloseModal();
      } else if (modalMode === 'edit' && editProductId) {
        await axios.put(`${BASE_URL}/vegetable/vendorProducts/${vendorUser.id}/${editProductId}`, {
          category,
          product_name: productName,
          rate,
          discount,
        });
      }

      fetchProducts();

      handleCloseModal();
    } catch (error) {
      console.error('Error adding/editing product:', error);
      alert('Failed to add/edit product. Please try again.');
    }
  };

  const handleEditProduct = (product) => {
    setModalMode('edit');
    setEditProductId(product.id);
    // Set initial values based on the selected product
    setProductName(product.product_name);
    setCategory(product.category);
    setDiscount(product.discount);
    setRate(product.rate);
    handleShowModal();
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`${BASE_URL}/vegetable/vendorProducts/${vendorUser.id}/${productId}`);
      fetchProducts();
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Failed to delete product. Please try again.');
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/vegetable/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/vegetable/vendorProducts/${vendorUser.id}`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  const categorizedProducts = {};
  products.forEach((product) => {
    if (!categorizedProducts[product.category]) {
      categorizedProducts[product.category] = [];
    }
    categorizedProducts[product.category].push(product);
  });

  return (
    <div>
      <button onClick={handleShowModal} className='veg-add-product-btn'>
        Add Product
      </button>
     
      {Object.entries(categorizedProducts).map(([category, categoryProducts]) => (
        <div key={category}>
           <div className='vendorProduct-scroll'>
          <div className='vendorProduct-containerbox'>
          <h2 className="vendorProduct-title mx-auto">{category}</h2>
          <div >
      
          <div className="vendorProduct-scroller  mt-3">
            {categoryProducts.map((product) => (
              <Col key={product.id} md={4}>
                <VegetableProductCard
                  product={product}
                  onEdit={handleEditProduct}
                  onDelete={handleDeleteProduct}
                />
              </Col>
            ))}
          </div>
          
          </div>
          </div>
        </div>
        </div>
      ))}
    
   

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body>
          <Form>
            <Form.Group controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                as='select'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value='' disabled>Select a category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.vegcategory}>
                    {cat.vegcategory}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId='productName'>
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter product name'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='rate'>
              <Form.Label>Rate (per Kg)</Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter rate'
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='discount'>
              <Form.Label>Discount in Percentage </Form.Label>
              <Form.Control
                type='number'
                placeholder='Enter discount'
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='bg-danger' onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className='bg-primary' onClick={handleAddProduct}>
            {modalMode === 'add' ? 'Add Product' : 'Edit Product'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddVegetableProduct;
