import React ,{useRef,useState} from 'react';
import Stories from 'react-insta-stories';

// const burakHeading = {
//     heading: "Burak Deniz",
//     subheading: "burakdeniz@gmail.com",
//     profileImage: "https://i.imgur.com/lq0DC3a.jpg"
//   }
// const initialStories = [
// {
//   url: 'https://images.unsplash.com/photo-1519962551779-514fa155be9a?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dW5zcGFsc2h8ZW58MHx8MHx8fDA%3D',
//   type: 'image',
// //   header: burakHeading,
//   seeMore: true,
//   duration: 1500,
//   seeMore: ({ close }) => {
//           return <div onClick={close}>Hello, click to close this.</div>;
//       },
// },
// {
//   url: 'https://images.unsplash.com/photo-1525310072745-f49212b5ac6d?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Zmxvd2VyfGVufDB8fDB8fHww',
//   type: 'image',
// //   header: burakHeading,
//   seeMore: ({ close }) => {
//           return <div onClick={close}>Hello, click to close this.</div>;
//       },
// },

// {
//   url: 'https://images.unsplash.com/photo-1533038590840-1cde6e668a91?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8Zmxvd2VyfGVufDB8fDB8fHww',
//   type: 'image',
// //   header: burakHeading,
// },
// {
//   url: 'https://i.imgur.com/ARMxyC4.png',
//   type: 'image',
// //   header: burakHeading,
// }
// ]
const InstaStories = ({stories :initialStories}) => {
    const [stories, setStories] = React.useState(initialStories);
     const [isVideoPlaying,setIsVideoPlaying]=useState(false);
     const videoRef =useRef(null);

    const onAllStoriesEndHandler = () =>{
      console.log('stories ended')
    }
  
const toggleVideoPlay =()=>{
  const video =videoRef.current;

  if(video){
    if(isVideoPlaying){
      video.pause();
    }else{
      video.play();
    }
    setIsVideoPlaying(!isVideoPlaying);
  }
}

  const storyContent = {
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
      
  }

  return (
    <div>
      
      <Stories 
          stories={stories}
          defaultInterval={4500}
          width={'100%'}
          height={'80vh'}
          style={{display: 'flex', justifyContent: "center", background:"red",
          cursor: 'pointer'
          }}
          storyStyles={storyContent}
          loop={true}
          keyboardNavigation={true}
          isPaused={true}
          currentIndex={() => {}}
          onStoryStart={() => {}}
          onStoryEnd={() => {}}
          onAllStoriesEnd={onAllStoriesEndHandler}
        
        />
    </div>
  );
};

export default InstaStories;
