import React, { useEffect, useState } from 'react';
import "./Header.css";
import { IoMdArrowDropdown } from "../../imports"
const LocationModal = ({ isOpen, onClose }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleSelect = (value) => {
    setSelectedValue(value);
    setIsActive(false);
  };
  useEffect(() => {
    const storedValue = localStorage.getItem('selectedLocation');
    if (storedValue) {
      setSelectedValue(storedValue);
    }
  }, []);
  const handleSave = () => {
    
    localStorage.setItem('selectedLocation', selectedValue);

    onClose();
    window.location.reload()
  };
  return (
    <div className={`modal ${isOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isOpen ? 'block' : 'none' }} >
      <div className="modal-dialog mt-15" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">Select City</h5>
            <button type="button" className="close" onClick={onClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className='dropdown'>
              <div className='dropdown-btn' onClick={() => setIsActive(!isActive)}> {selectedValue || 'Choose One'}
                <IoMdArrowDropdown />
              </div>
              {isActive && (
                <div className='dropdown-content'>
                  <div className='dropdown-item' onClick={() => handleSelect('Dindigul')}>
                    Dindigul
                  </div>
                  <div className='dropdown-item' onClick={() => handleSelect('Palani')}>
                    Palani
                  </div>
                  <div className='dropdown-item' onClick={() => handleSelect('Coimbatore')}>
                    Coimbatore
                  </div>
                  <div className='dropdown-item' onClick={() => handleSelect('Pollachi')}>
                    Pollachi
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <p> from local storage:{selectedValue}</p> */}
          <div className='d-flex justify-content-between m-4 cursor-pointer' >
            <div className='mx-2 bg-danger p-2 text-white rounded' onClick={onClose}>Cancel</div>
            <div className='mx-2 bg-primary p-2 text-white rounded' onClick={handleSave}>Save</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationModal;
