import React, { useEffect, useState } from 'react'
import "./Categories.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import {
  GiFruitBowl, FaCarSide, FaHandHoldingMedical,
  FaPeopleLine, GrCafeteria, LocationModal
} from "../../imports"
const Categories = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    console.log("clicled open modal")
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    const selectedLocation = localStorage.getItem('selectedLocation');
    console.log(selectedLocation, "in categories page")
    if (!selectedLocation) {
      openModal();
    }
  }, []);

  const handleNavigate = (path) => {
    const selectedLocation = localStorage.getItem('selectedLocation');

    if (selectedLocation) {
      navigate(path);
    } else {
      openModal();
    }
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (

    <div className='cat-carousel cursor-pointer'>

      <Slider {...settings}>
        <div className='cat-box' onClick={() => handleNavigate('/vegetable')}>
          <div className='cat-icon'>
            <GiFruitBowl />
          </div>
          <div>
            <h6 className='cat-heading'>Fresh Picks, Daily Delights</h6>
            <p className='cat-para'>Your local produce to Table Experience Starts Here!
            </p>
          </div>
        </div>
        <div className='cat-box' onClick={() => handleNavigate('/transport')}>
          <div className='cat-icon '>
            < FaCarSide />
          </div>
          <div>
            <h6 className='cat-heading'>Local Transport
            </h6>
            <p className='cat-para'>Seamless and Safe Journeys<br /><br /> Ride with Ease and Efficiency
            </p>
          </div>
        </div >
        <div className='cat-box' onClick={() => handleNavigate('/pharmacy')}>
          <div className='cat-icon '>
            < FaHandHoldingMedical />
          </div>
          <div>
            <h6 className='cat-heading'>Pharmacy

            </h6>
            <p className='cat-para'>Your health, Our Priority<br /><br /> A Local Pharmacy network committed to your well-being
            </p>
          </div>
        </div>
        <div className='cat-box ' onClick={() => handleNavigate('/hotels')}>
          <div className='cat-icon '>
            <GrCafeteria />
          </div>
          <div>
            <h6 className='cat-heading'>Restaurants & Cafe


            </h6>
            <p className='cat-para'>Experience the food flavors with daily updated special menu crafted for you
            </p>
          </div>
        </div>
        <div className='cat-box' onClick={() => handleNavigate('/professionals')}>
          <div className='cat-icon '>
            <FaPeopleLine />
          </div>
          <div>
            <h6 className='cat-heading'>Hire Professionals
            </h6>
            <p className='cat-para'>Expertise at your fingertips Discover, Connect and Hire our verified professionals for every need
            </p>
          </div>
        </div>

      </Slider>
      <LocationModal isOpen={isModalOpen} onClose={closeModal} />

    </div>
  )
}

export default Categories