import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  FaEdit,
  MdOutlineDeleteSweep,
  MdVerifiedUser,
  TbListDetails,
  MdOutlineLocationCity,
  FaStore,
  MdEmail,
  MdReviews,
  TbDeviceLandlinePhone,
  MdDescription,
  IoIosPricetag,
  TbPasswordUser,
  IoMdArrowRoundBack,
  FaUserCircle,
  MdOutlineSmartphone,
  FaLocationDot,
  BiSolidCategoryAlt,
  MdAssistantDirection,
  MessageModal,AdminProfessionalStoryModal
} from "../../../../imports";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../AdminDashboard.css";

const ProfessionalVerifiedDetailing = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { professional } = location.state || {};
  const [imagePaths, setImagePaths] = useState([]);
  const [deletingImage, setDeletingImage] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);
  const [imgDelModal, setimgDelModal] = useState(false);
  const [verificationModalShow, setVerificationModalShow] = useState(false);
  const [delUserModalShow, setdelUserModalShow] = useState(false);
  const [professionalCategories, setProfessionalCategories] = useState([]);
  const [storyViewModal,setstoryViewModal]=useState(false);

  const [editedValues, setEditedValues] = useState({
    user_id: professional.user_id,
    name: professional.name,
    phoneNumber: professional.phoneNumber,
    password: professional.password,
    storename: professional.storename,
    location: professional.location,
    description: professional.description,
    email: professional.email,
    reviews: professional.reviews,
    price: professional.price,
    serviceDetails: professional.serviceDetails,
    number: professional.number,
    verification: professional.verification,
    category: professional.professionalcategory,
    direction: professional.direction,
    city: professional.city,
    years_of_experience: professional.years_of_experience,
  });
  const fetchProfessionalCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/professional/category`
      );

      setProfessionalCategories(response.data);
    } catch (error) {
      console.error("Error fetching professional categories:", error.message);
    }
  };
  const handleClose = () => {
    setEditModalShow(false);
  };

  const handleDeleteModal = () => {
    setimgDelModal(true);
  };

  const fetchImagePaths = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/professional/serviceimage/${professional.user_id}`
      );
      // console.log(response, "response for image path")
      setImagePaths(response.data.imagePaths || []);
    } catch (error) {
      console.error("Error fetching image paths:", error);
    }
  };

  const handleDeleteImage = async (imageName) => {
    setDeletingImage(imageName);
    handleDeleteModal();
  };

  const handleCancelDelete = () => {
    setDeletingImage("");
    setimgDelModal(false);
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/professional/serviceimage/${professional.user_id}/${deletingImage}`
      );
      // console.log('Response after deleting image:', response.data);
      if (response.status === 200) {
        handleCancelDelete();
        fetchImagePaths();
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleEditModalShow = () => setEditModalShow(true);

  const handleUserDelModalShow = () => setdelUserModalShow(true);
  const handleUserModalCancel = () => setdelUserModalShow(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/professional/edit`,
        editedValues
      );
      handleClose();
    } catch (error) {
      console.error("Error updating professional data:", error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/unverifiedvendor/delete/${professional.user_id}`
      );
      setdelUserModalShow(false);
      navigation("/admin/professional/verifiedlist");
    } catch (error) {
      console.error("Error in deleting user data:", error);
    }
  };

  useEffect(() => {
    fetchImagePaths();
    fetchProfessionalCategories();
  }, []);

  const openStoryViewPopup= () => {
    setstoryViewModal(!storyViewModal)
  }

  return (
    <div>
      <div className="container">
        <div className="">
          <div
            className="d-flex justify-content-between mt-3"
            style={{ cursor: "pointer" }}
          >
            <IoMdArrowRoundBack
              className="back-option"
              onClick={() => navigation("/admin/verified/professional/list")}
            />
            <div className="head-options">
            <button onClick={openStoryViewPopup} className='admin-choose-poster-btn'>View Stories</button>


              <FaEdit className="edit-options" onClick={handleEditModalShow} />
            </div>
          </div>

          {/* designing basic info */}
          <div className="container">
            <div className=" ">
              {professional ? (
                <div className="unverified-detailing row ">
                  <div className="col-lg-6 col-sm-12">
                    <div className="unverified-row">
                      <p className="detail-heading ">
                        <FaUserCircle className="mx-2" /> Name&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">{editedValues.name}</span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        {" "}
                        <FaStore className="mx-2" />
                        Store Name &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">
                        {editedValues.storename}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        {" "}
                        <FaStore className="mx-2" />
                        Years of Experience &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">
                        {editedValues.years_of_experience}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <FaLocationDot className="mx-2" />
                        Location &nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.location}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdReviews className="mx-2" />
                        Reviews&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.reviews}
                      </span>
                    </div>
                    {/* <div className="unverified-row">
                      <p className="detail-heading">
                        <IoIosPricetag className="mx-2" />
                        Price &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">{editedValues.price}</span>
                    </div> */}

                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdOutlineLocationCity className="mx-2" />
                        City &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">{editedValues.city}</span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <TbListDetails className="mx-2" />
                        Service &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">
                        {editedValues.serviceDetails}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdOutlineSmartphone className="mx-2" /> Phone
                        Number&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.phoneNumber}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <BiSolidCategoryAlt className="mx-2" />
                        Category&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.category}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <TbPasswordUser className="mx-2" />
                        Password &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">
                        {editedValues.password}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdEmail className="mx-2" />
                        Email Id&nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">{editedValues.email}</span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <TbDeviceLandlinePhone className="mx-2" />
                        Shop Number&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.number}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdDescription className="mx-2" />
                        Desription&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.description}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdAssistantDirection className="mx-2" />
                        Direction&nbsp;:&nbsp;{" "}
                      </p>
                      <a
                        href={`https://www.google.com/maps?q=${editedValues.direction}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="detail-point text-primary"
                      >
                        {" "}
                        open in google map
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Professional details available.</p>
              )}
            </div>
          
          </div>
        </div>
        <div className="detail-point-img">
          {imagePaths.map((imagePath, index) => (
            <div key={index} className="image-container-admin">
              <div
                className="delete-button"
                onClick={() => handleDeleteImage(imagePath)}
              >
                <MdOutlineDeleteSweep />
              </div>

              <img
                src={`${BASE_URL}/professional/images/${imagePath}`}
                alt={`Image ${index}`}
                className="image-preview img-fluid"
              />
            </div>
          ))}
        </div>
      </div>
      <Modal show={editModalShow} onHide={handleClose} centered>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={editedValues.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter phone number"
                name="phoneNumber"
                value={editedValues.phoneNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formpassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Password"
                name="password"
                value={editedValues.password}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formstorename">
              <Form.Label>Store Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Store Name"
                name="storename"
                value={editedValues.storename}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="professionalCategory">
              <Form.Label>Professional Category</Form.Label>
              <Form.Control
                as="select"
                value={editedValues.category}
                onChange={(e) =>
                  setEditedValues({ ...editedValues, category: e.target.value })
                }
              >
                <option value="" disabled>
                  Select a professional category
                </option>
                {professionalCategories.map((cat) => (
                  <option key={cat.id} value={cat.professionalCategory}>
                    {cat.professionalCategory}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formyears_of_experience">
              <Form.Label>Years Of Experience</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Experience"
                name="years_of_experience"
                value={editedValues.years_of_experience}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formlocation">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Location"
                name="location"
                value={editedValues.location}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formdescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                name="description"
                value={editedValues.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formemail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Id"
                name="email"
                value={editedValues.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formreviews">
              <Form.Label>Reviews</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Reviews"
                name="reviews"
                value={editedValues.reviews}
                onChange={handleInputChange}
              />
            </Form.Group>
            {/* <Form.Group controlId="formprice">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Price"
                name="price"
                value={editedValues.price}
                onChange={handleInputChange}
              />
            </Form.Group> */}
            <Form.Group controlId="formprice">
              <Form.Label>Shop Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Shop Number"
                name="number"
                value={editedValues.number}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Select
                name="city"
                value={editedValues.city}
                onChange={handleInputChange}
              >
                <option value="">Select a City</option>
                <option value="Dindigul">Dindigul</option>
                <option value="Palani">Palani</option>
                <option value="Coimbatore">Coimbatore</option>
                <option value="Pollachi">Pollachi</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={handleClose} className="cancel-btn-edit">
            Cancel
          </button>
          <button onClick={handleEdit} className="save-btn-edit">
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imgDelModal}
        onHide={handleCancelDelete}
        centered
        className="admin-del-modal"
      >
        <div className="admin-del-point">
          <p>Are you sure you want to delete this image? </p>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <p onClick={handleCancelDelete} className="cancel-btn-edit">
            Cancel
          </p>
          <p
            variant="danger"
            onClick={handleConfirmDelete}
            className="save-btn-edit"
          >
            Delete
          </p>
        </div>
      </Modal>
      {storyViewModal && 
      <AdminProfessionalStoryModal onClose={openStoryViewPopup} userId={editedValues.user_id}/>}
      {/* user del modal */}
      <Modal show={delUserModalShow} onHide={handleUserModalCancel} centered>
       
      </Modal>
    </div>
  );
};

export default ProfessionalVerifiedDetailing;
