import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  AdminDashboard,VegetableSubCategoryListing,
  UnAuthoriziedVendor,TransportVerifiedDetailingPage,
  VegetableUnverifiedList,TransportVerifiedList,
  VegetableUnverifiedDetailing,
  MasterCategoryPage,TransportUnverifiedDetailingPage ,
  VegetableVerifiedTable,TransportUnverifiedList,
  VegetableVerifiedList,TransportMasterHome ,
  VegetableVerifiedDetailPage,
  ProfessionalMasterHome, ProfessionalVerifiedDetailing,
  AddCategoryVegetables,ProfessionalUnverifiedDetailing ,
  AdminLoginForm,ProfessionalUnverifiedList,ProfessionalVerifiedList,
  VendorCallRequest
} from "../../imports";
import { useAdminAuth } from "./AdminAuthProvider";

const AdminRoutes = () => {
  const { adminUser } = useAdminAuth();

  return (
    <Routes>
      <Route path="/login" element={<AdminLoginForm />} />
      {adminUser && (
        <>
         <Route path="/dashboard" element={<AdminDashboard />} />
          <Route
            path="/unauthorized/vendor"
            element={<UnAuthoriziedVendor />}
          />
          <Route
            path="/vegetable/unauthorized"
            element={<VegetableUnverifiedList />}
          />
          <Route
            path="/vegetable/detailing"
            element={<VegetableUnverifiedDetailing />}
          />
          <Route
            path="/vegetable/verifiedlist"
            element={<VegetableVerifiedList />}
          />
          <Route
            path="/vegetable/verifiedtable"
            element={<VegetableVerifiedTable />}
          />
          <Route
            path="/verified/detailpage"
            element={<VegetableVerifiedDetailPage />}
          />
          <Route path="/master/category" element={<MasterCategoryPage />} />
          <Route
            path="/master/vegetable/addcategory"
            element={<AddCategoryVegetables />}
          />
          <Route
            path="/master/professional/create"
            element={<ProfessionalMasterHome />}
          />
              <Route
            path="/master/vegetable/subcategory"
            element={<VegetableSubCategoryListing/>}
          />
          {/* professional routes */}
          <Route
            path="/unverified/professional/list"
            element={<ProfessionalUnverifiedList />}
          />
           <Route
            path="/unverified/professional/detailing"
            element={<ProfessionalUnverifiedDetailing  />}
          />
             <Route
            path="/verified/professional/list"
            element={<ProfessionalVerifiedList />}
          />
             <Route
            path="/verified/professional/details"
            element={< ProfessionalVerifiedDetailing />}
          />
              <Route
            path="/callrequest"
            element={< VendorCallRequest />}
          />
          {/* transport Routes */}
             <Route
            path="/master/transport"
            element={< TransportMasterHome  />}
          />
              <Route
            path="/unverified/transport/list"
            element={<TransportUnverifiedList  />}
          />
             <Route
            path="/unverified/transport/detailing"
            element={<TransportUnverifiedDetailingPage   />}
          />
           <Route
            path="/verified/transport/list"
            element={<TransportVerifiedList  />}
          />
            <Route
            path="/verified/transport/detailing"
            element={<TransportVerifiedDetailingPage  />}
          />
        </>
      )}
     

{/* <Route path="/sidebar" element={<AdminSidebarMain />} /> */}
    </Routes>
  );
};

export default AdminRoutes;
