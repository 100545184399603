import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  FaEdit,FaCarSide,FaCalendarCheck,
  MdOutlineDeleteSweep,
  MdVerifiedUser,IoMdTimer,
  MdOutlineLocationCity,
  FaStore,
  MdEmail,
  MdReviews,
  TbListDetails,
  TbDeviceLandlinePhone,
  MdDescription,
  IoIosPricetag,
  TbPasswordUser,
  IoMdArrowRoundBack,
  FaUserCircle,
  MdOutlineSmartphone,
  FaLocationDot,
  BiSolidCategoryAlt,
  MdAssistantDirection,
  MessageModal,
} from "../../../../imports";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import { Modal, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../AdminDashboard.css";

const TransportUnverifiedDetailingPage = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { profile } = location.state || {};
  const [editModalShow, setEditModalShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [deletingImage, setDeletingImage] = useState("");
  const [imgDelModal, setimgDelModal] = useState(false);
  const [selectedPosterImage, setSelectedPosterImage] = useState("");
  const [showmessageModal, setShowmessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [posterImageModalShow, setPosterImageModalShow] = useState(false);
  const [verificationModalShow, setVerificationModalShow] = useState(false);

  const [editedValues, setEditedValues] = useState({
    user_id: profile.user_id,
    name: profile.name,
    phoneNumber: profile.phoneNumber,
    password: profile.password,
    email: profile.email,
    address: profile.address,
    description: profile.description,
    city: profile.city,
    vehicleType: profile.vehicle_type,
    vehicleModel: profile.vehicle_model,
    vehicleNo: profile.vehicle_registration_number,
    experience: profile.years_of_driving_experience,
    days_and_hours_available: profile.days_and_hours_available,
    category: profile.category,
    direction: profile.location,
    reviews: profile.reviews,
    verification:profile.verification
  });

//   Edit modal
const handleClose = () => {
    setEditModalShow(false);
  };
const handleEditModalShow = () => setEditModalShow(true);
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/transport/categories`);
      setCategories(response.data);
     
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchImagePaths = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/transport/serviceimage/${profile.user_id}`
      );
      // console.log(response, "response for image path")
      setImagePaths(response.data.imagePaths || []);
    } catch (error) {
      console.error("Error fetching image paths:", error);
    }
  };
  useEffect(() => {
    fetchCategories()
    fetchImagePaths();
  }, []);

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/transport/edit`,
        editedValues
      );

      // console.log('Vegetable data updated:', response.data.message);
      handleClose();
    } catch (error) {
      console.error("Error updating vegetable data:", error);
    }
  };

  // deleting image
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/transportservice/image/${profile.user_id}/${deletingImage}`
      );
      // console.log('Response after deleting image:', response.data);
      if (response.status === 200) {
        handleCancelDelete();
        fetchImagePaths();
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };
  const handleDeleteImage = async (imageName) => {
    setDeletingImage(imageName);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setimgDelModal(true);
  };
  const handleCancelDelete = () => {
    setDeletingImage("");
    setimgDelModal(false);
  };
  const handleClosemessageModal = () => {
    setShowmessageModal(false);
  };
  const fileInputRef = useRef(null);

  const handleSetPosterImage = async (imagePath) => {
    setSelectedPosterImage(imagePath);
    try {
      const requestData = {
        user_id: profile.user_id,
        poster: imagePath,
      };

      const response = await axios.put(
        `${BASE_URL}/transport/poster/serviceimg`,
        requestData
      );

      console.log("Poster image uploaded successfully:", response.data);
      handleShowmessageModal("Poster image uploaded successfully.");
    } catch (error) {
      console.error("Error uploading poster image:", error);
    }
  };

  const handleShowmessageModal = (message) => {
    setShowmessageModal(true);
    setMessageModalContent(message);
  };

  //choose poster image

  const handleChoosePosterClick = () => {
    fileInputRef.current.click();
  };

 

    const handlePosterImageChange = (e) => {
      const file = e.target.files[0];
      setSelectedPosterImage(file);
      setSelectedImageURL(URL.createObjectURL(file));
      handleShowPosterImageModal();
    };

      // showing selected poster image in preview
  const handleShowPosterImageModal = () => {
    setPosterImageModalShow(true);
  };

  const handleClosePosterImageModal = () => {
    setPosterImageModalShow(false);
  };

  const handlePosterImageUpload = async () => {
    try {
      if (!selectedPosterImage) {
        handleShowmessageModal("Please select a poster image before verifying the user");
        return;
      }

      // console.log("selected poster image",selectedPosterImage,vegetable.user_id)
      const formData = new FormData();
      formData.append('user_id', profile.user_id);
      formData.append('poster', selectedPosterImage);

      const response = await axios.put(`${BASE_URL}/transport/poster/upload`, formData);

      console.log('Poster image uploaded successfully:', response.data);

      handleClosePosterImageModal();
      handleShowmessageModal('Poster image uploaded successfully.');

    } catch (error) {
      console.error('Error uploading poster image:', error);
    }
  };

  // verification state
    // vendor verifiation modal
    const handleVerifyModalShow = () => setVerificationModalShow(true);

    const handleCancelVerification = () => {
      setVerificationModalShow(false);
  
    };

    const handleApproveVerification = async () => {
      try {
        if (!selectedPosterImage) {
          handleShowmessageModal("Please select a poster image before verifying the user");
          return;
        }
  
        // Perform the verification process
        const response = await axios.post(`${BASE_URL}/admin/transport/verify/${profile.user_id}`);
        navigation("/admin/unverified/transport/list");
      } catch (error) {
        console.error('Error verifying vendor:', error);
      } finally {
        setVerificationModalShow(false);
      }
    };
  const posterImageModalContent = (
    <Modal
      show={posterImageModalShow}
      onHide={handleClosePosterImageModal}
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center">
        <img
          src={selectedImageURL}
          alt="Selected Poster Image"
          className="img-fluid"
        />
        <div className="d-flex justify-content-between mt-3">
          <Button
            onClick={handleClosePosterImageModal}
            className="bg-danger mx-2"
          >
            Cancel
          </Button>
          <Button onClick={handlePosterImageUpload} className="mx-3 bg-primary">
            Upload
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
  return (
    <div>
      <div>
      <div
        className="d-flex justify-content-between mt-3"
        style={{ cursor: "pointer" }}
      >
        <IoMdArrowRoundBack
          className="back-option"
          onClick={() => navigation("/admin/unverified/transport/list")}
        />

        <div className="head-options">
          <button
            onClick={handleChoosePosterClick}
            className="admin-choose-poster-btn"
          >
            Choose Poster
          </button>
          <FaEdit className="edit-options" onClick={handleEditModalShow} />
          {/* <MdOutlineDeleteSweep
            className="delete-options"
            onClick={handleUserDelModalShow}
          /> */}
    <MdVerifiedUser
          className="verify-options"
       onClick={handleVerifyModalShow}
        />
        </div>
       </div>
      <input
         type="file"
         accept="image/*"
         ref={fileInputRef}
         onChange={handlePosterImageChange}
         style={{ display: "none" }}
      />
    </div>  
      <div className="container">
        <div className=" ">
          {profile ? (
            <div className="unverified-detailing row ">
              <div className="col-lg-6 col-sm-12">
                <div className="unverified-row">
                  <p className="detail-heading ">
                    <FaUserCircle className="mx-2" /> Name&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">{editedValues.name}</span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdEmail className="mx-2" />
                    Email Id&nbsp;:&nbsp;
                  </p>
                  <span className="detail-point">{editedValues.email}</span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    {" "}
                    <FaCarSide className="mx-2" />
                    Vehicle Type&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.vehicleType}
                  </span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <FaCarSide className="mx-2" />
                    Vehicle No &nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">{editedValues.vehicleNo}</span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <FaLocationDot className="mx-2" />
                    Location &nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">{editedValues.address}</span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdReviews className="mx-2" />
                    Reviews&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">{editedValues.reviews}</span>
                </div>
                {/* <div className="unverified-row">
                <p className="detail-heading">
                  <IoIosPricetag className="mx-2" />
                  Price &nbsp;:&nbsp;
                </p>
                <span className="detail-point">{editedValues.price}</span>
              </div> */}

                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdOutlineLocationCity className="mx-2" />
                    City &nbsp;:&nbsp;
                  </p>
                  <span className="detail-point">{editedValues.city}</span>
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdOutlineSmartphone className="mx-2" /> Phone
                    Number&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.phoneNumber}
                  </span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <TbPasswordUser className="mx-2" />
                    Password &nbsp;:&nbsp;
                  </p>
                  <span className="detail-point">{editedValues.password}</span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <FaCarSide className="mx-2" />
                    VehicleModel&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.vehicleModel}
                  </span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <FaCalendarCheck className="mx-2" />
                    Years of Experience&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.experience}
                  </span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <IoMdTimer className="mx-2" />
                    Day and Hours Available&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.days_and_hours_available}
                  </span>
                </div>

                <div className="unverified-row">
                  <p className="detail-heading">
                    <BiSolidCategoryAlt className="mx-2" />
                    Category&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">{editedValues.category}</span>
                </div>

                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdDescription className="mx-2" />
                    Desription&nbsp;:&nbsp;{" "}
                  </p>
                  <span className="detail-point">
                    {editedValues.description}
                  </span>
                </div>
                <div className="unverified-row">
                  <p className="detail-heading">
                    <MdAssistantDirection className="mx-2" />
                    Direction&nbsp;:&nbsp;{" "}
                  </p>
                  <a
                    href={`https://www.google.com/maps?q=${editedValues.direction}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="detail-point text-primary"
                  >
                    {" "}
                    open in google map
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <p>No Professional details available.</p>
          )}
        </div>
        <div className="detail-point-img">
        {imagePaths.map((imagePath, index) => (
          <div key={index} className="image-container-admin">
            <div className="d-flex justify-content-between">
              <div
                className="delete-button"
                onClick={() => handleDeleteImage(imagePath)}
              >
                <MdOutlineDeleteSweep />
              </div>
              <button
                onClick={() => handleSetPosterImage(imagePath)}
                className="set-poster-button"
              >
                Set as Poster
              </button>
            </div>

            <img
              src={`${BASE_URL}/vegetablestoreimages/${imagePath}`}
              alt={`Image ${index}`}
              className="image-preview img-fluid"
            />
          </div>
        ))}
      </div>
      </div>
      {/* edit modal */}
      <Modal show={editModalShow} onHide={handleClose} centered>
  <Modal.Body>
    <Form>
      <Form.Group controlId="formName">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter name"
          name="name"
          value={editedValues.name}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formPhoneNumber">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter phone number"
          name="phoneNumber"
          value={editedValues.phoneNumber }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formpassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Password"
          name="password"
          value={editedValues.password }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formemail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Email Id"
          name="email"
          value={editedValues.email }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formAddress">
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Address"
          name="address"
          value={editedValues.address}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formDescription">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Description"
          name="description"
          value={editedValues.description }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formCity">
        <Form.Label>City</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter City"
          name="city"
          value={editedValues.city }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formVehicleType">
  <Form.Label>
    
    Vehicle Type:
  </Form.Label>
  {categories.length > 0 ? (
    <Form.Control
      as="select"
      value={editedValues.vehicleType}
      onChange={(e) =>
        setEditedValues({ ...editedValues, vehicleType: e.target.value })
      }
    >
      <option value="" disabled>
        Select a Vehicle Type
      </option>
      {categories.map((cat) => (
        <option key={cat.id} value={cat.vehicleCategory}>
          {cat.vehicleCategory}
        </option>
      ))}
    </Form.Control>
  ) : (
    <span className="detail-point">Loading categories...</span>
  )}
</Form.Group>
      <Form.Group controlId="formVehicleModel">
        <Form.Label>Vehicle Model</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Vehicle Model"
          name="vehicleModel"
          value={editedValues.vehicleModel}
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formVehicleNo">
        <Form.Label>Vehicle Number</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Vehicle Number"
          name="vehicleNo"
          value={editedValues.vehicleNo }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formExperience">
        <Form.Label>Years of Driving Experience</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter Experience"
          name="experience"
          value={editedValues.experience }
          onChange={handleInputChange}
        />
      </Form.Group>
      <Form.Group controlId="formDaysAndHours">
        <Form.Label>Days and Hours Available</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Availability"
          name="days_and_hours_available"
          value={editedValues.days_and_hours_available}
          onChange={handleInputChange}
        />
      </Form.Group>
  

      <Form.Group controlId="formReviews">
        <Form.Label>Reviews</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter Reviews"
          name="reviews"
          value={editedValues.reviews }
          onChange={handleInputChange}
        />
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <button onClick={handleClose} className="cancel-btn-edit">
      Cancel
    </button>
    <button onClick={handleEdit} className="save-btn-edit">
      Save Changes
    </button>
  </Modal.Footer>
</Modal>


      {/* delete user modal */}
      {/* user del modal */}
      {/* <Modal show={delUserModalShow} onHide={handleUserModalCancel} centered>
      <div className="admin-del-point">
        <p>Are You Sure You Want to Delete the user ?</p>
      </div>
      <div className="d-flex justify-content-between mt-5">
        <p onClick={handleUserModalCancel} className="cancel-btn-edit">
          Cancel
        </p>
        <p
          variant="danger"
          onClick={handleDeleteUser}
          className="save-btn-edit"
        >
          Delete User
        </p>
      </div>
    </Modal> */}
      {/* Verify Modal */}
      <Modal
      show={verificationModalShow}
      onHide={handleCancelVerification}
      centered
    >
      <div className="admin-del-point">
        <p>Authorize User ?</p>
      </div>
      <div className="d-flex justify-content-between mt-5">
        <p onClick={handleCancelVerification} className="cancel-btn-edit">
          Cancel
        </p>
        <p
          variant="danger"
          onClick={handleApproveVerification}
          className="save-btn-edit"
        >
          Approve
        </p>
      </div>
    </Modal>
 
   
       <MessageModal
      show={showmessageModal}
      message={messageModalContent}
      onClose={handleClosemessageModal}
    />
    <Modal
      show={imgDelModal}
      onHide={handleCancelDelete}
      centered
      className="admin-del-modal"
    >
      <div className="admin-del-point">
        <p>Are you sure you want to delete this image? </p>
      </div>
      <div className="d-flex justify-content-between mt-5">
        <p onClick={handleCancelDelete} className="cancel-btn-edit">
          Cancel
        </p>
        <p
          variant="danger"
          onClick={handleConfirmDelete}
          className="save-btn-edit"
        >
          Delete
        </p>
      </div>
    </Modal> 
      {posterImageModalContent}
    </div>
  );
};

export default TransportUnverifiedDetailingPage;
