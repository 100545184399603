import React, { useState, useEffect } from "react";
import { AdminSidebarMain, IoMdArrowRoundBack } from "../../../../imports";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import "../../AdminDashboard.css";
import "../../demo/AdminSidebar.css";

import { useNavigate } from "react-router-dom";

const VegetableUnverifiedList = () => {
  const navigate = useNavigate();

  const [vegetables, setVegetables] = useState([]);
  const [isDashboardHeaderVisible, setDashboardHeaderVisible] = useState(true);

  const toggleNavigation = () => {
    setDashboardHeaderVisible(!isDashboardHeaderVisible);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/vegetable/unverified/list`
        );
       
        setVegetables(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleView = (id) => {
    const selectedVegetable = vegetables.find(
      (vegetable) => vegetable.id === id
    );
    navigate("/admin/vegetable/detailing", {
      state: { vegetable: selectedVegetable },
    });
  };
  return (
    <div className="admin-dashboard-page cursor-pointer">
      <AdminSidebarMain />

      <h2 className="text-center admin-title-heading">
        Unauthorized Vegetable Vendor List
      </h2>

      <div className="dashboardmain">
        {/* table */}

        <div className="responsive-table mt-2 custom-overflow-x">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Phone Number</th>
                  <th>Location</th>
                  {/* <th>Verification</th> */}
                  <th>Store Name</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {vegetables.map((vegetable) => (
                  <tr key={vegetable.id} className="pt-3">
                    <td>{vegetable.name}</td>
                    <td>{vegetable.phoneNumber}</td>
                    <td>{vegetable.location}</td>
                    <td>{vegetable.storename}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleView(vegetable.id)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VegetableUnverifiedList;
