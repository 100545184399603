import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack,EditSubCategoryModal} from "../../../imports";
import { BASE_URL } from "../../../env";
import axios from "axios";
import ReactSwitch from "react-switch";
import { MdOutlineEdit } from "react-icons/md";

const VegetableSubCategoryListing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subcategories, setSubcategories] = useState([]);
  const [editSubcategory, setEditSubcategory] = useState(null);
  const { categoryId } = location.state;

  const handleGoBack = () => {
    navigate(-1);
  };
  const handleEdit = (subcategory) => {
    setEditSubcategory(subcategory);
  };
  const handleCancelEdit = () => {
    setEditSubcategory(null);
  };



  const fetchSubcategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/vegetable-subcategories/${categoryId}`
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      // Handle error, e.g., show error message to user
    }
  };
  useEffect(() => {

    fetchSubcategories();
  }, [categoryId]);


  const handleChange = (id) => async (val) => {
    try {
      await axios.put(`${BASE_URL}/admin/vegetable-subcategories/${id}`, {
        active: val ? 1 : 0,
      });
      const updatedSubcategories = subcategories.map((subcategory) => {
        if (subcategory.id === id) {
          return { ...subcategory, active: val ? 1 : 0 };
        }
        return subcategory;
      });
      console.log("sucess ");
      
      setSubcategories(updatedSubcategories);
    } catch (error) {
      console.error("Error updating subcategory status:", error);
     
    }
  };

  

  return (
    <div>
      <p
        className="d-flex align-items-center cursor-pointer"
        onClick={handleGoBack}
      >
        <IoMdArrowRoundBack />
        <ins>Back</ins>
      </p>

      <div className="mart-categorycard mt-5">
        {subcategories.map((subcategory) => (
          <div key={subcategory.id} className="mart-card">
            <div className="d-flex m-2">
              <MdOutlineEdit className="vendorProduct-edit" onClick={() => handleEdit(subcategory)}/>

              <div className="mx-2">
                <ReactSwitch
                  checked={subcategory.active === 1}
                  onChange={handleChange(subcategory.id)}
                  height={20}
                  width={40}
                />
              </div>
            </div>
            <img
              src={`${BASE_URL}/vegetable/category/images/${subcategory.subcategoryImage}`}
              className="mart-category-img img-fluid mt-2"
              alt={subcategory.subcategoryName}
            />
            <div className="mt-2">
              <h3 className="font-bold text-capitalize">
                {subcategory.subcategoryName}
              </h3>
            </div>
          </div>
        ))}
      </div>
      {editSubcategory && (
        <EditSubCategoryModal
          subcategory={editSubcategory}
          
          onCancel={handleCancelEdit}
          fetchSubcategories={fetchSubcategories}
        />
      )}
    </div>
  );
};

export default VegetableSubCategoryListing;
