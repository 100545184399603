import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import {
  FaEdit, MdOutlineDeleteSweep, MdVerifiedUser, MdOutlineLocationCity, FaStore, MdEmail, MdReviews, TbDeviceLandlinePhone, MdDescription, IoIosPricetag,
  TbPasswordUser, IoMdArrowRoundBack, FaUserCircle, MdOutlineSmartphone, FaLocationDot, BiSolidCategoryAlt, MdAssistantDirection, MessageModal,
} from "../../../../imports"
import axios from 'axios';
import { BASE_URL } from "../../../../env";
import { Modal, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import "../../AdminDashboard.css"

const VegetableUnverifiedDetailing = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { vegetable } = location.state || {};
  const [imagePaths, setImagePaths] = useState([]);
  const [deletingImage, setDeletingImage] = useState('');
  const [editModalShow, setEditModalShow] = useState(false);
  const [imgDelModal, setimgDelModal] = useState(false);
  const [verificationModalShow, setVerificationModalShow] = useState(false);
  const [delUserModalShow, setdelUserModalShow] = useState(false);
  const [selectedPosterImage, setSelectedPosterImage] = useState('');
  const [showmessageModal, setShowmessageModal] = useState(false);
  const [posterImageModalShow, setPosterImageModalShow] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState('');
  const [messageModalContent, setMessageModalContent] = useState("");

  const fileInputRef = useRef(null);



  const [editedValues, setEditedValues] = useState({
    user_id: vegetable.user_id,
    name: vegetable.name,
    phoneNumber: vegetable.phoneNumber,
    password: vegetable.password,
    storename: vegetable.storename,
    location: vegetable.location,
    description: vegetable.description,
    email: vegetable.email,
    reviews: vegetable.reviews,
    price: vegetable.price,
    serviceDetails: vegetable.serviceDetails,
    storeName: vegetable.storeName,
    number: vegetable.number,
    verification: vegetable.verification,
    category: vegetable.category,
    direction: vegetable.direction,
    city: vegetable.city
  });
  const handleClose = () => {

    setEditModalShow(false);
  };

  const handleDeleteModal = () => {
    setimgDelModal(true);
  }
  const fetchImagePaths = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/vegetable/storeimage/${vegetable.user_id}`);
      // console.log(response, "response for image path")
      setImagePaths(response.data.imagePaths || []);
    } catch (error) {
      console.error('Error fetching image paths:', error);
    }
  };

  // vendor verifiation modal
  const handleVerifyModalShow = () => setVerificationModalShow(true);

  const handleCancelVerification = () => {
    setVerificationModalShow(false);

  };



  const handleApproveVerification = async () => {
    try {
      if (!selectedPosterImage) {
        handleShowmessageModal("Please select a poster image before verifying the user");
        return;
      }

      // Perform the verification process
      const response = await axios.post(`${BASE_URL}/admin/vendor/verify/${vegetable.user_id}`);
      navigation("/admin/vegetable/unauthorized");
    } catch (error) {
      console.error('Error verifying vendor:', error);
    } finally {
      setVerificationModalShow(false);
    }
  };
  useEffect(() => {

    fetchImagePaths()

  }, []);

  const handleDeleteImage = async (imageName) => {
    setDeletingImage(imageName);
    handleDeleteModal()
  };
  const handleCancelDelete = () => {
    setDeletingImage('');
    setimgDelModal(false);
  };


  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/vegetable/storeimage/${vegetable.user_id}/${deletingImage}`);
      // console.log('Response after deleting image:', response.data);
      if (response.status === 200) {
        handleCancelDelete();
        fetchImagePaths();
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  // modals

  const handleEditModalShow = () => setEditModalShow(true);

  const handleUserDelModalShow = () => setdelUserModalShow(true);
  const handleUserModalCancel = () => setdelUserModalShow(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {


      const response = await axios.put(`${BASE_URL}/vegeatable/edit`, editedValues);

      // console.log('Vegetable data updated:', response.data.message);
      handleClose();

    } catch (error) {
      console.error('Error updating vegetable data:', error);
    }
  };

  const handleDeleteUser = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/unverifiedvendor/delete/${vegetable.user_id}`);
      console.log("handle delete", response)
    } catch (error) {
      console.error('Error in deleting user data:', error);
    }
  }

  // ...

  const handleSetPosterImage = async (imagePath) => {
   
      setSelectedPosterImage(imagePath);
    try {
     

      const requestData = {
        user_id: vegetable.user_id,
        poster: imagePath,
      };

    
      const response = await axios.put(`${BASE_URL}/vegetable/poster/storeimg`, requestData);

      console.log('Poster image uploaded successfully:', response.data);
      handleShowmessageModal('Poster image uploaded successfully.');

    } catch (error) {
      console.error('Error uploading poster image:', error);
    }
  };




  const handleShowmessageModal = (message) => {
    setShowmessageModal(true);
    setMessageModalContent(message);
  };

  const handleClosemessageModal = () => {
    setShowmessageModal(false);
  };

  // choose poster image

  const handlePosterImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedPosterImage(file);
    setSelectedImageURL(URL.createObjectURL(file));
    handleShowPosterImageModal();
  };
  const handleChoosePosterClick = () => {
    fileInputRef.current.click();
  };

  // showing selected poster image in preview
  const handleShowPosterImageModal = () => {
    setPosterImageModalShow(true);
  };

  const handleClosePosterImageModal = () => {
    setPosterImageModalShow(false);
  };
  const handlePosterImageUpload = async () => {
    try {
      if (!selectedPosterImage) {
        handleShowmessageModal("Please select a poster image before verifying the user");
        return;
      }

      // console.log("selected poster image",selectedPosterImage,vegetable.user_id)
      const formData = new FormData();
      formData.append('user_id', vegetable.user_id);
      formData.append('poster', selectedPosterImage);

      const response = await axios.put(`${BASE_URL}/vegetable/poster/upload`, formData);

      console.log('Poster image uploaded successfully:', response.data);

      handleClosePosterImageModal();
      handleShowmessageModal('Poster image uploaded successfully.');

    } catch (error) {
      console.error('Error uploading poster image:', error);
    }
  };


  const posterImageModalContent = (
    <Modal show={posterImageModalShow} onHide={handleClosePosterImageModal} centered>
      <Modal.Body className='d-flex flex-column align-items-center'>
        <img
          src={selectedImageURL}
          alt="Selected Poster Image"
          className="img-fluid"
        />
        <div className="d-flex justify-content-between mt-3">
          <Button onClick={handleClosePosterImageModal} className='bg-danger mx-2'>Cancel</Button>
          <Button onClick={handlePosterImageUpload} className='mx-3 bg-primary'>Upload</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
  return (
    <div>
      {/* <AdminNavigation /> */}
      <div className=''>
        <div className='d-flex justify-content-between mt-3' style={{ cursor: "pointer" }}>

          <IoMdArrowRoundBack className='back-option' onClick={() => navigation("/admin/vegetable/unauthorized")} />

          <div className='head-options'>
            <button onClick={handleChoosePosterClick} className='admin-choose-poster-btn'>Choose Poster</button>
            <FaEdit className='edit-options' onClick={handleEditModalShow} />
            {/* <MdOutlineDeleteSweep className='delete-options' onClick={handleUserDelModalShow} /> */}
            <MdVerifiedUser className='verify-options' onClick={handleVerifyModalShow} />
          </div>
        </div>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handlePosterImageChange}
          style={{ display: 'none' }}
        />

        {/* designing basic info */}
        <div className='container'>
          <div className=' '>
            {vegetable ? (
              <div className='unverified-detailing row '>
                <div className='col-lg-6 col-sm-12'>
                  <div className='unverified-row'>
                    <p className='detail-heading '><FaUserCircle className='mx-2' /> Name&nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.name}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'> <FaStore className='mx-2' />Store Name &nbsp;:&nbsp;</p>
                    <span className='detail-point'>{editedValues.storename}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'><FaLocationDot className='mx-2' />Location &nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.location}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'>< MdReviews className='mx-2' />Reviews&nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.reviews}</span>
                  </div>
                  {/* <div className='unverified-row'>
                    <p className='detail-heading'>< IoIosPricetag className='mx-2' />Price &nbsp;:&nbsp;</p>
                    <span className='detail-point'>{editedValues.price}</span>
                  </div> */}
                  {/* <div className='unverified-row'>
                    <p className='detail-heading'><BiSolidCategoryAlt className='mx-2' />Category&nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.category}</span>
                  </div> */}
                  <div className='unverified-row'>
                    <p className='detail-heading'><MdOutlineLocationCity className='mx-2' />City &nbsp;:&nbsp;</p>
                    <span className='detail-point'>{editedValues.city}</span>
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                  <div className='unverified-row'>
                    <p className='detail-heading'><MdOutlineSmartphone className='mx-2' /> Phone Number&nbsp;:&nbsp;  </p>
                    <span className='detail-point'>{editedValues.phoneNumber}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'><TbPasswordUser className='mx-2' />Password &nbsp;:&nbsp;</p>
                    <span className='detail-point'>{editedValues.password}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'><MdEmail className='mx-2' />Email Id&nbsp;:&nbsp;</p>
                    <span className='detail-point'>{editedValues.email}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'><TbDeviceLandlinePhone className='mx-2' />Shop Number&nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.number}</span>
                  </div>
                  <div className='unverified-row'>
                    <p className='detail-heading'>< MdDescription className='mx-2' />Desription&nbsp;:&nbsp; </p>
                    <span className='detail-point'>{editedValues.description}</span>
                  </div>
                  <div className='unverified-row'>

                    <p className='detail-heading'><  MdAssistantDirection className='mx-2' />Direction&nbsp;:&nbsp; </p>
                    <a
                    href={`https://www.google.com/maps?q=${editedValues.direction}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="detail-point text-primary"
                  >
                    {" "}
                    open in google map
                  </a>
                  </div>
                </div>

              </div>

            ) : (
              <p>No vegetable details available.</p>
            )}

          </div>
          <div className='detail-point-img'>
            {imagePaths.map((imagePath, index) => (
              <div key={index} className='image-container-admin'>
                <div className='d-flex justify-content-between'>
                  <div className='delete-button' onClick={() => handleDeleteImage(imagePath)}>
                    <MdOutlineDeleteSweep />
                  </div>
                  <button onClick={() => handleSetPosterImage(imagePath)} className='set-poster-button'>
                    Set as Poster
                  </button>
                </div>



                <img
                  src={`${BASE_URL}/vegetablestoreimages/${imagePath}`}
                  alt={`Image ${index}`}
                  className='image-preview img-fluid'
                />

              </div>
            ))}
          </div>
        </div>


        <Modal show={editModalShow} onHide={handleClose} centered>

          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={editedValues.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formPhoneNumber">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  value={editedValues.phoneNumber}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formpassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Password"
                  name="password"
                  value={editedValues.password}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formstorename">
                <Form.Label>Store Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Store Name"
                  name="storename"
                  value={editedValues.storename}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formlocation">
                <Form.Label>Location </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Location"
                  name="location"
                  value={editedValues.location}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formdescription">
                <Form.Label>Description </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  name="description"
                  value={editedValues.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formemail">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email Id"
                  name="email"
                  value={editedValues.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formreviews">
                <Form.Label>Reviews</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Reviews"
                  name="reviews"
                  value={editedValues.reviews}
                  onChange={handleInputChange}
                />
              </Form.Group>
              {/* <Form.Group controlId="formprice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Price"
                  name="price"
                  value={editedValues.price}
                  onChange={handleInputChange}
                />
              </Form.Group> */}
              <Form.Group controlId="formprice">
                <Form.Label>Shop Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Shop Number"
                  name="number"
                  value={editedValues.number}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Select
                  name="city"
                  value={editedValues.city}
                  onChange={handleInputChange}
                >
                  <option value="">Select a City</option>
                  <option value="Dindigul">Dindigul</option>
                  <option value="Palani">Palani</option>
                  <option value="Coimbatore">Coimbatore</option>
                  <option value="Pollachi">Pollachi</option>
                </Form.Select>
              </Form.Group>
            </Form>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={handleClose} className='cancel-btn-edit'>
              Cancel
            </button>
            <button onClick={handleEdit} className='save-btn-edit'>
              Save Changes
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={imgDelModal} onHide={handleCancelDelete} centered className='admin-del-modal'>
          <div className='admin-del-point'>
            <p>Are you sure you want to delete this image? </p>
          </div>
          <div className='d-flex justify-content-between mt-5'>
            <p onClick={handleCancelDelete} className='cancel-btn-edit'>
              Cancel
            </p>
            <p variant='danger' onClick={handleConfirmDelete} className='save-btn-edit'>
              Delete
            </p>
          </div>

        </Modal>
        {/* Verify Modal */}
        <Modal show={verificationModalShow} onHide={handleCancelVerification} centered>
          <div className='admin-del-point'>
            <p>Authorize User ?</p>
          </div>
          <div className='d-flex justify-content-between mt-5'>
            <p onClick={handleCancelVerification} className='cancel-btn-edit'>
              Cancel
            </p>
            <p variant='danger' onClick={handleApproveVerification} className='save-btn-edit'>
              Approve
            </p>
          </div>

        </Modal>
        {/* user del modal */}
        <Modal show={delUserModalShow} onHide={handleUserModalCancel} centered>
          <div className='admin-del-point'>
            <p>Are You Sure You Want to Delete the user ?</p>
          </div>
          <div className='d-flex justify-content-between mt-5'>
            <p onClick={handleUserModalCancel} className='cancel-btn-edit'>
              Cancel
            </p>
            <p variant='danger' onClick={handleDeleteUser} className='save-btn-edit'>
              Delete User
            </p>
          </div>

        </Modal>
        <MessageModal show={showmessageModal}
          message={messageModalContent}
          onClose={handleClosemessageModal} />
      </div>
      {/* main div */}
      {/* previewing poster image */}
      <Modal centered>
        <Modal.Body >

          <div>
            <Button>Cancel</Button>
            <Button >
              Upload
            </Button>
          </div>

        </Modal.Body>

      </Modal>
      {posterImageModalContent}
    </div>
  )
}

export default VegetableUnverifiedDetailing