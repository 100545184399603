import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../env";
import { AdminSidebarMain } from "../../imports";

const VendorCallRequest = () => {
  const [callRequests, setCallRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [notesInput, setNotesInput] = useState("");

  useEffect(() => {
   
    fetchCallRequests();
  }, []);

  const fetchCallRequests = async () => {
    try {
      // Replace the URL with your actual API endpoint
      const response = await axios.get(`${BASE_URL}/getCallRequests`);
      setCallRequests(response.data);
    } catch (error) {
      console.error("Error fetching call requests:", error);
    }
  };

  const filterTable = (term) => {
    setSearchTerm(term);
  };

  const handleAddNotes = (id) => {
    setSelectedRequestId(id);
  };

  const handleEdit = (id) => {
    // Implement edit logic here
    console.log(`Edit ID: ${id}`);
  };
  const handleDelete = async (id) => {
    try {
      // Send a DELETE request to delete the specific row on the server
      const response = await axios.delete(
        `${BASE_URL}/callrequest/deleteRow/${id}`
      );

      if (response.status === 200) {
        console.log("Row deleted successfully!");
        // Optionally, you can perform additional actions after deleting the row
        // For example, you may want to fetch updated call requests after deletion
        fetchCallRequests();
      } else {
        console.error("Failed to delete row:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting row:", error.message);
    }
  };

  const handleSaveNotes = async () => {
    try {
      // Send a PUT request to update notes on the server
      const response = await axios.put(
        `${BASE_URL}/updateNotes/${selectedRequestId}`,
        {
          notes: notesInput,
        }
      );

      if (response.status === 200) {
        // Update the local state with the new notes
        setCallRequests((prevRequests) =>
          prevRequests.map((request) =>
            request.id === selectedRequestId
              ? { ...request, notes: notesInput }
              : request
          )
        );

        // Reset the selectedRequestId and notesInput
        setSelectedRequestId(null);
        setNotesInput("");

        console.log("Notes updated successfully!");
      } else {
        console.error("Failed to update notes:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating notes:", error.message);
    }
  };

  return (
    <div className="admin-dashboard-page">
      <AdminSidebarMain />
      <h2 className="text-center admin-title-heading mt-3">
        Vendor Call Request
      </h2>

      <div className="dashboardmain">
        {/* table */}

        <div className="table-responsive">
          {/* <input
            type="text"
            value={searchTerm}
            onChange={(e) => filterTable(e.target.value)}
            className="form-control mb-3 w-25 mt-3"
            placeholder="Search"
          /> */}
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Phone Number</th>
                <th>Notes</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {callRequests.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{request.phone_number}</td>
                  <td>
                    {selectedRequestId === request.id ? (
                      <>
                        <input
                          type="text"
                          value={notesInput}
                          onChange={(e) => setNotesInput(e.target.value)}
                          className="form-control"
                        />
                        <button
                          className="admin-choose-poster-btn mt-2 float-right "
                          onClick={handleSaveNotes}
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      request.notes
                    )}
                  </td>
                  <td>{new Date(request.created_at).toLocaleDateString()}</td>
                  <td className="d-flex ">
                    <span
                      className="admin-choose-poster-btn"
                      onClick={() => handleAddNotes(request.id)}
                      disabled={selectedRequestId === request.id}
                    >
                      Add Notes
                    </span>
                    <span
                      className="admin-choose-poster-btn bg-danger mx-2"
                      onClick={() => handleDelete(request.id)}
                      disabled={selectedRequestId === request.id}
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VendorCallRequest;
