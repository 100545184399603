import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../env";

const VendorAuthContext = createContext();

export const VendorAuthProvider = ({ children }) => {
  const navigate =useNavigate();

  const [vendorUser, setVendorUser] = useState(()=>{
    const storedVendorUser =localStorage.getItem("vendorUser");
    return storedVendorUser ? JSON.parse(storedVendorUser):null;

  });
  const [token, setToken] = useState(null);


  const vendorLogin = (userData, token) => {
    setVendorUser(userData);
    setToken(token);

  };

  const vendorLogout = () => {
    setVendorUser(null);
    setToken(null);
   
  };
  // const fetchData = async (id) => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/vegetablestore/profile/detail/${id}`);
  //     const data = response.data;

  //     setVendorUser(data);
  //   } catch (error) {
  //     console.error("Error fetching vendor details:", error.message);
  //   }
  // };
  // useEffect(() => {
  //   if (vendorUser) {
    
  //     fetchData(vendorUser.id);
  //   }
  // }, [vendorUser]);

  useEffect(()=>{
    localStorage.setItem("vendorUser",JSON.stringify(vendorUser));
    const timeoutId =setTimeout(()=>{
      localStorage.removeItem("vendorUser");
      vendorLogout();

      navigate("/");

    }, 1 * 60 * 60 * 1000);
    return()=>clearTimeout(timeoutId);
    
  },[vendorUser])
  return (
    <VendorAuthContext.Provider value={{ vendorUser, token, vendorLogin, vendorLogout }}>
      {children}
    </VendorAuthContext.Provider>
  );
};

export const useVendorAuth = () => {
  const context = useContext(VendorAuthContext);

  if (!context) {
    throw new Error("useVendorAuth must be used within a VendorAuthProvider");
  }

  return context;
};
