import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import { useNavigate } from "react-router-dom";
import { IoMdArrowRoundBack } from "../../../../imports";

const TransportMasterHome = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [existingCategories, setExistingCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [editCategory, setEditCategory] = useState("");

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/transport/categories`);
      setCategories(response.data);
      setExistingCategories(
        response.data.map((category) => category.vehicleCategory)
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setEditCategory("");
    setSelectedCategoryId(null);
  };

  const handleInputChange = (e) => setNewCategory(e.target.value);

  const handleAddCategory = async () => {
    try {
      await axios.post(`${BASE_URL}/transport/add/category`, {
        vehicleCategory: newCategory,
      });
      fetchCategories();
      setNewCategory("");
      handleCloseModal();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleEditCategory = async () => {
    try {
      await axios.put(
        `${BASE_URL}/transport/edit/category/${selectedCategoryId}`,
        { vehicleCategory: editCategory }
      );
      fetchCategories();
      setEditCategory("");
      handleCloseModal();
    } catch (error) {
      console.error("Error editing category:", error);
    }
  };

  const handleEditClick = (categoryId, categoryName) => {
    setSelectedCategoryId(categoryId);
    setEditCategory(categoryName);
    setShowModal(true);
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${BASE_URL}/transport/delete/category/${categoryId}`);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div>
      <p
        className="d-flex align-items-center cursor-pointer"
        onClick={() => navigate("/admin/master/category")}
      >
        <IoMdArrowRoundBack className="" />
        <ins>Back</ins>
      </p>

      <h2 className="text-center admin-title-heading mt-2">
        Add Categories to Transport
      </h2>

      <Button  onClick={handleShowModal} className="m-4 bg-primary">
        Add New Category
      </Button>

      {categories.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Category</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>{category.vehicleCategory}</td>
                <td>
                  <Button
                    className="bg-warning"
                    onClick={() =>
                      handleEditClick(category.id, category.vehicleCategory)
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    className="ml-2 bg-primary" 
                    onClick={() => handleDeleteCategory(category.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>No categories available.</p>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Body className="mt-2">
          <Form.Control
            type="text"
            list="categorySuggestions"
            placeholder="Enter category name"
            value={selectedCategoryId !== null ? editCategory : newCategory}
            onChange={(e) =>
              selectedCategoryId !== null
                ? setEditCategory(e.target.value)
                : setNewCategory(e.target.value)
            }
          />
          <datalist id="categorySuggestions">
            {existingCategories.map((category, index) => (
              <option key={index} value={`${category} (Already Exists)`} />
            ))}
          </datalist>
          <div className="d-flex justify-content-between mt-3">
            <Button onClick={handleCloseModal} className="bg-danger">
              Cancel
            </Button>
            <Button
            className="bg-primary"
              onClick={
                selectedCategoryId !== null
                  ? handleEditCategory
                  : handleAddCategory
              }
              disabled={existingCategories.some(
                (category) =>
                  category.trim().toLowerCase() ===
                  (selectedCategoryId !== null
                    ? editCategory.trim().toLowerCase()
                    : newCategory.trim().toLowerCase())
              )}
            >
              {selectedCategoryId !== null ? "Edit Category" : "Add Category"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransportMasterHome;
