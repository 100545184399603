import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import {
  VendorRegistration,
  VegetableHomepage,
  VendorLogin,
  ProfileCreation,
  StoreDetails,
  ProfessionalProfileCreation,
  StoreImageUploading,
  ProfessionalImageUploading,
  TransportImageUploading,
  VegetableVendorStory,
  TransportVendorHome,
  TransportVendorRegister,
  TransportProfileCreation,
  ProfessionalLandingPage,
  ProfessionalProfileDetail,
  TransportVendorProfile,
} from "../../imports";
import { useVendorAuth } from "./VendorAuthContext";

const PrivateRoute = ({ element, path }) => {
  const { vendorUser } = useVendorAuth();

  if (!vendorUser) {
    return <Navigate to="/vendor/login" />;
  }

  return element;
};

const VendorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<VendorRegistration />} />
      <Route path="/login" element={<VendorLogin />} />

      <Route
        path="/vegetable/home"
        element={<PrivateRoute element={<VegetableHomepage />} />}
      />
      <Route
        path="/vegetable/profile"
        element={<PrivateRoute element={<ProfileCreation />} />}
      />
      <Route
        path="/store/detail"
        element={<PrivateRoute element={<StoreDetails />} />}
      />
      <Route
        path="/image"
        element={<PrivateRoute element={<StoreImageUploading />} />}
      />
      <Route
        path="/professional/image"
        element={<PrivateRoute element={<ProfessionalImageUploading />} />}
      />
      <Route
        path="/daily/story"
        element={<PrivateRoute element={<VegetableVendorStory />} />}
      />
      {/* professional routes */}
      <Route
        path="/professional/detail"
        element={<PrivateRoute element={<ProfessionalProfileDetail />} />}
      />
      <Route
        path="/professional/homepage"
        element={<PrivateRoute element={<ProfessionalLandingPage />} />}
      />
      <Route
        path="/professional/profile"
        element={<PrivateRoute element={<ProfessionalProfileCreation />} />}
      />

      {/* Tranport */}
      <Route
        path="/transport/profile/detail"
        element={<PrivateRoute element={<TransportVendorProfile />} />}
      />
      <Route
        path="/transport/profile/creation"
        element={<PrivateRoute element={<TransportProfileCreation />} />}
      />
      <Route
        path="/transport/image/uploading"
        element={<PrivateRoute element={<TransportImageUploading />} />}
      />
      <Route
        path="/transport/home"
        element={<PrivateRoute element={<TransportVendorHome />} />}
      />

      <Route
        path="/transport/registration"
        element={<TransportVendorRegister />}
      />
      {/* demo */}
      {/* <Route path="/store" element={< StoreDetails />} /> */}
    </Routes>
  );
};

export default VendorRoutes;
