import React from 'react';
import AdminNavigation from './AdminNavigation';
import "./UnAuthorizied.css"
import { Link } from 'react-router-dom';
import {AdminSidebarMain, GiFruitBowl, FaCarSide, FaHandHoldingMedical,
  FaPeopleLine, GrCafeteria,GiCoffeeCup } from "../../imports";

const UnAuthorizedVendor = () => {
  const category = [
    { title: 'Vegetables',path :"/admin/vegetable/unauthorized", icon: <GiFruitBowl className='ag-course-lefticon'/> },
    { title: 'Professionals',path :"/admin/unverified/professional/list", icon: <FaPeopleLine className='ag-course-lefticon'/> },
    { title: 'Transport',path :"/admin/unverified/transport/list",  icon:< FaCarSide className='ag-course-lefticon'/> },
    { title: 'Hotels', icon:  <GrCafeteria className='ag-course-lefticon' /> },
    { title: 'Restaurants & Cafe', icon:<GiCoffeeCup  className='ag-course-lefticon'/> },
   
  
  ];

  return (
    <div className="admin-dashboard-page">
      <AdminSidebarMain />
      <div className='dashboardmain' >
  
        <div className="ag-format-container">
        <h2 className='text-center admin-title-heading'>unauthorized vendor category</h2>

          <div className="ag-courses_box">
            {category.map((category, index) => (
              <div className="ag-courses_item" key={index}>
                <Link to={category.path} className="ag-courses-item_link">
                  <div className="ag-courses-item_bg"></div>
                  <div className="ag-courses-item_bg-iconPage"  >
                  {category.icon}
                  </div>
                  <div className="ag-courses-item_title">
                    {category.title}
                    <div className="ag-course-icon">
                     
                    </div>
                  </div>
                 
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAuthorizedVendor;
