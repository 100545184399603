import React, { useState } from "react";
import {
  AdminNavigation,
  MdMenu,
  AdminSidebarMain,
  GiFruitBowl,
  FaCarSide,
  FaHandHoldingMedical,
  FaPeopleLine,
  GrCafeteria,
  GiCoffeeCup,
} from "../../../../imports";
import "../../AdminDashboard.css";
import "../../UnAuthorizied.css";
import "../../demo/AdminSidebar.css";
import { Link } from "react-router-dom";
const VegetableVerifiedList = () => {
  const category = [
    {
      title: "Vegetables",
      description:
        "Efficiently categorize vegetables by type, ensuring concise and informative descriptions for a user friendly browsing experience.",
      path: "/admin/vegetable/verifiedtable",
      icon: <GiFruitBowl className="ag-course-lefticon" />,
    },
    {
      title: "Professionals",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      path: "/admin/verified/professional/list",
      icon: <FaPeopleLine className="ag-course-lefticon" />,
    },
    {
      title: "Transport",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      path: "/admin/verified/transport/list",
      icon: <FaCarSide className="ag-course-lefticon" />,
    },

    {
      title: "Hotels",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: <GrCafeteria className="ag-course-lefticon" />,
    },
    {
      title: "Restaurants & Cafe",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      icon: <GiCoffeeCup className="ag-course-lefticon" />,
    },
  ];

  const [isNavigationOpen, setIsNavigationOpen] = useState(true);

  const toggleNavigation = () => {
    setIsNavigationOpen(!isNavigationOpen);
  };

  return (
    <div className="admin-dashboard-page">
      <AdminSidebarMain />
      <div className="dashboardmain">
        <div className="ag-format-container">
          <h2 className="text-center admin-title-heading">
            Verified vendors category
          </h2>
          <div className="ag-courses_box">
            {category.map((category, index) => (
              <div className="ag-courses_item" key={index}>
                <Link to={category.path} className="ag-courses-item_link">
                  <div className="ag-courses-item_bg"></div>
                  <div className="ag-courses-item_bg-iconPage">
                    {category.icon}
                  </div>
                  <div className="ag-courses-item_title ">
                    {category.title}
                    <div className="ag-course-icon">
                      {/* {category.description} */}
                    </div>
                  </div>
                  {/* <div className="ag-courses-item_date-box">
                    <span className="ag-courses-item_date">View All</span>
                  </div> */}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VegetableVerifiedList;
