import React from 'react'
import "./Background.css";
import {video1,image1,image2,image3} from "../../../../../imports";

const TransportVendorBackground = ({playStatus,heroCount}) => {
  if(playStatus){
    return(
      <video className='background-transport fade-in' autoPlay loop muted>
        <source src={video1} type='video/mp4'/>
      </video>
    )
  }
  else if(heroCount===0){
    return <img src={image1} className='background-transport img-fluid fade-in' alt=""/>
  }
  else if(heroCount===1){
    return <img src={image2} className='background-transport img-fluid fade-in' alt=""/>
      
  }
  else if(heroCount===2){
    return <img src={image3} className='background-transport img-fluid fade-in' alt=""/>
  }
}

export default TransportVendorBackground