import React from 'react'
import {VscVerifiedFilled} from "../../imports";
import "./SuccessModal.css";
import { useNavigate } from 'react-router-dom';
const SuccessModal = ({ onClose ,message,redirectTo}) => {
  const navigate = useNavigate();

  const handleDoneClick = () => {
    onClose();

    if (redirectTo) {
      navigate(redirectTo); 
    }
  };

  return (
<div className=' modal-centering'>

  <div className="modal-dialog modal-confirm">
  <div className="modal-content">
    <div className="modal-header">
      <div className="icon-box">
       <VscVerifiedFilled className='modal-icon'/>
      </div>
      {/* <h4 className="modal-title w-100">Congra</h4> */}
    </div>
    <div className="modal-body">
      <p className="text-center">{message}</p>
    </div>
    <div className="modal-footer">
      <button className="btn btn-success btn-block w-100"  onClick={handleDoneClick}>Done</button>
    </div>
  </div>
</div>
    
</div>



  )
}

export default SuccessModal