import { createContext, useContext, useState,useEffect } from "react";

const AdminAuthContext = createContext();

export const AdminAuthProvider = ({ children }) => {
  const [adminUser, setAdminUser] = useState(()=>{
    const storedAdminUser =localStorage.getItem("adminUser");
    return storedAdminUser ? JSON.parse(storedAdminUser):null;
    });

useEffect(()=>{
  localStorage.setItem("adminUser",JSON.stringify(adminUser))
},[adminUser])

  const adminLogin = (userData) => {
    setAdminUser(userData);
  };

  const adminLogout = () => {
    setAdminUser(null);
  };
// console.log(adminUser)
  return (
    <AdminAuthContext.Provider value={{ adminUser, adminLogin, adminLogout }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);

  if (!context) {
    throw new Error("useAdminAuth must be used within an AdminAuthProvider");
  }

  return context;
};
