import React,{useState,useEffect} from 'react'
import {AdminSidebarMain} from '../../../../imports';
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import { useNavigate } from 'react-router-dom';
import "../../AdminDashboard.css";
import '../../demo/AdminSidebar.css';

const VegetableVerifiedTable = () => {
  const navigate =useNavigate();
  const [vegetables,setVegetables] =useState([]);
  

useEffect(()=>{
   
  const fetchData=async()=>{
    try{
     const response = await axios.get(`${BASE_URL}/vegetable/verified/list`);
     setVegetables(response.data);

    }catch(error){
      console.log(error)
    }
  }

  fetchData();
},[])

const handleView = (id) => {
  const selectedVegetable = vegetables.find((vegetable) => vegetable.id === id);
  navigate('/admin/verified/detailpage', { state: { vegetable: selectedVegetable } });
};
  return (
      <div className='admin-dashboard-page'>
 <AdminSidebarMain/>
 <h2 className='text-center admin-title-heading'> authorized Vegetable Vendor List</h2>

      <div   className='dashboardmain'>
   
        {/* table */}

        <div className='responsive-table custom-overflow-x' >
                  <table className="table table-striped">
                      <thead>
                          <tr>
                              <th>Name</th>
                              <th>Phone Number</th>
                              <th>Location</th>
                              {/* <th>Verification</th> */}
                              <th>Store Name</th>
                              <th>View</th>
                          </tr>
                      </thead>
                      <tbody>
                          {vegetables.map((vegetable) => (
                              <tr key={vegetable.id} className='pt-3' >
                                  <td >{vegetable.name}</td>
                                  <td>{vegetable.phoneNumber}</td>
                                  <td>{vegetable.location}</td>
                                  <td>{vegetable.storename}</td>
                                  <td>
                                      <button className="btn btn-primary" onClick={() => handleView(vegetable.id)}>View</button>
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </table>
              </div>
    
      </div>
    </div>
  )
}

export default VegetableVerifiedTable