import React,{useState,useEffect} from 'react';
import "../vegetables/Vegetables.css";
import { vegbanner,FaCircleCheck,transportbanner  } from '../../../imports';

const  Transport = () => {
 
  return (
    <>
    <div className='veg-container'>
    <div className='contain-box'>
    <div className='container  veg-content-category'>
    <div className='row'>
      <div className='veg-left col-lg-6 col-md-6 col-sm-12'>
       <img src={transportbanner } className='img-fluid'/>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 veg-right'>
         <h3 >Why Choose Us ?</h3>
         <div className='veg-para'>
          <p>
          Lorem Ipsum is simply dummy text of the since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.

          </p>
          <div className='veg-points'>
            <div className='d-flex  align-items-center'>
              <FaCircleCheck className='veg-icon'/>
              <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
            </div>
            <div className='d-flex  align-items-center'>
              <FaCircleCheck className='veg-icon'/>
              <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
            </div>
            <div className='d-flex  align-items-center'>
              <FaCircleCheck className='veg-icon'/>
              <p> Lorem Ipsum is simply dummy text of the since the 1500s </p>
            </div>
          </div>
         </div>
      </div>
    </div>
    </div>
    </div>
    </div>

    </>
  )
}

export default Transport