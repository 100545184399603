import React, { useState } from "react";

const OtpVerifyModal = ({onClose,onVerify}) => {
  const [otp, setOtp] = useState("");

  const handleVerifyOtp = () => {

    onVerify(otp);
  };
  return (
    <div className="modal fade show" role="dialog" style={{ display: "block", zIndex: 10000 }}>
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">OTP has been sended to Registered Email Id</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <label htmlFor="otp">Enter OTP</label>
              <input
                type="text"
                className="form-control"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn bg-danger text-white"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn bg-primary text-white"
              onClick={handleVerifyOtp}
            >
              Verify OTP and Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpVerifyModal