import React, { useState, useEffect } from 'react';
import { FaHome, FaUsersGear, RiShieldUserFill, FaUserLock, BsPersonBadge, BsPersonFillUp, BsPersonFillExclamation,AdminSidebarMain } from '../../imports';
import axios from 'axios';
import { BASE_URL } from '../../env';
import "./demo/AdminSidebar.css";

const Dashboard = () => {
  const [userCounts, setUserCounts] = useState({
    totalUsers: 0,
    totalUnverifiedUsers: 0,
    totalVerifiedUsers: 0,
  });

  const [vendorCounts, setVendorCounts] = useState({
    totalVendors: 0,
    totalUnauthorizedVendors: 0,
    totalAuthorizedVendors: 0,
  });
  const [monthlyChartData, setMonthlyChartData] = useState([]);


  useEffect(() => {
    const fetchUserCounts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/user/count`);
        setUserCounts(response.data);
      } catch (error) {
        console.error('Error fetching user counts:', error);
      }
    };

    const fetchVendorCounts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/vendor/count`);
        setVendorCounts(response.data);
      } catch (error) {
        console.error('Error fetching vendor counts:', error);
      }
    };
    const fetchMonthlyChartData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/dashboard/monthchart`);
        setMonthlyChartData(response.data);
      } catch (error) {
        console.error('Error fetching monthly chart data:', error);
      }
    };



    fetchUserCounts();
    fetchVendorCounts();

  }, []);







  return (
    <div>
      {/* =============== Navigation ================ */}
      <div className="admin-dashboard-page">
        <AdminSidebarMain/>
        {/* ========================= Main ==================== */}
        <div className='dashboardmain' >
          
          {/* ======================= Cards ================== */}
          <div className="cardBox ">
            <div className="card">
              <div>
                <div className="numbers">{userCounts.totalUsers}</div>
                <div className="cardName">Total Users</div>
              </div>
              <div className="iconBx">
                <FaUsersGear />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">{userCounts.totalVerifiedUsers}</div>
                <div className="cardName">Authorized User</div>
              </div>
              <div className="iconBx">
                <RiShieldUserFill />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">{userCounts.totalUnverifiedUsers}</div>
                <div className="cardName">Unauthorized User</div>
              </div>
              <div className="iconBx">
                <FaUserLock />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">{vendorCounts.totalVendors}</div>
                <div className="cardName">Total Vendors</div>
              </div>
              <div className="iconBx">
                <BsPersonBadge />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">{vendorCounts.totalAuthorizedVendors}</div>
                <div className="cardName">Authorized Vendor</div>
              </div>
              <div className="iconBx">
                < BsPersonFillUp />
              </div>
            </div>
            <div className="card">
              <div>
                <div className="numbers">{vendorCounts.totalUnauthorizedVendors}</div>
                <div className="cardName">Unauthorized Vendor</div>
              </div>
              <div className="iconBx">
                <BsPersonFillExclamation />
              </div>
            </div>
          </div>
          {/* =========================Charts=============== */}

        </div>
      </div>
    </div>
  );
};

export default Dashboard;
