import React, { useState }  from 'react';
import "./StoreDetail.css";
import { storeDetailsValidationSchema, vegform, SuccessModal, FailureModal, } from "../../../../imports";
import { useVendorAuth } from '../../VendorAuthContext';
import { useFormik } from "formik";
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';


const StoreDetails = () => {
  const { vendorUser } = useVendorAuth();
  const libraries = ['places'];
  const mapContainerStyle = {
    width: '100%',
    height: '300px',
    marginTop: "10px",
  };

  const defaultCenter = {
    lat: 10.3500,
    lng: 77.9500,
  };
  
  const formik = useFormik({
    initialValues: {
      storename: vendorUser.storename || '',
      reviews: 0,
      serviceDetails: '',
      price: 0,
      location: vendorUser.location || '',
      number: vendorUser.phoneNumber || '',
      direction: '',

    },
    validationSchema: storeDetailsValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [mapCenter, setMapCenter] = useState(defaultCenter); 
  const [markerPosition, setMarkerPosition] = useState(null);
  const handleSubmit = async (values) => {
    try {
      const formData = {
        ...values,
        user_id: vendorUser.id,
        category: vendorUser.category,
      };
      const response = await axios.post(`${BASE_URL}/vendor/profile/create`, formData);

      if (response.status === 200) {
        console.log('Profile created successfully');
        setSuccessMessage(response.data.message)
        setSuccessModalOpen(true);
      } else {
        console.error('Error creating profile:', response.statusText);
        setErrorMessage("Error in Creating Profile. Please try again.");
        setFailureModalOpen(true)
      }
    } catch (error) {
      console.error('Error creating profile:', error.message);
    }
  };
  const closeSuccessModal = () => {
    setSuccessModalOpen(false);

  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);

  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB9HRWEcSk53RuyZC_TJ2EsqC93hUnzqns',
    libraries,
  });

  const handleMapClick = (event) => {
    const { lat, lng } = event.latLng.toJSON();
    formik.setValues({
      ...formik.values,
      direction: `${lat}, ${lng}`, 
    });
    setMarkerPosition({ lat, lng });
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div className='store-detail-page'>
      <div className='store-form-area'>
        <div className='container'>
          {successModalOpen && <SuccessModal onClose={closeSuccessModal} message={successMessage} redirectTo="/vendor/image" />}
          {failureModalOpen && <FailureModal onClose={closeFailureModal} message={errorMessage} />}
          <div className='row store-detailform g-0'>
            <div className='col-sm-12 col-lg-6'>
              <div className='store-left'>

                <img src={vegform} className='veg-form-img img-fluid fade-in' />
              </div>
           
            </div>
            {/* right content */}
            <div className='col-sm-12 col-lg-6'>
              <div className='store-right'>
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="storename" className="form-label">
                      Store Name
                    </label>
                    <input
                      type="text"
                      id="storename"
                      name="storename"
                      placeholder="store name"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.storename}

                    />
                    {formik.touched.storename && formik.errors.storename && (
                      <div className="error">{formik.errors.storename}</div>
                    )}


                  </div>
                  <div className="mb-3">
                    <label htmlFor="reviews" className="form-label">
                      Reviews
                    </label>
                    <input
                      type="number"
                      id="reviews"
                      name="reviews"
                      placeholder="Enter your store's rating (1-5)"
                      className="form-control"
                      min="1"
                      max="5"
                      step="0.1"
                      pattern="[0-9]+([.,][0-9]+)?"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reviews}
                      disabled 
                    />
                    {formik.touched.reviews && formik.errors.reviews && (
                      <div className="error">{formik.errors.reviews}</div>
                    )}

                  </div>
                  <div className="mb-3">
                    <label htmlFor="serviceDetails" className="form-label">
                      Service Detail
                    </label>
                    <input
                      type="text"
                      id="serviceDetails"
                      name="serviceDetails"
                      placeholder="Service Details"
                      className="form-control py-4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.serviceDetails}
                    />
                    {formik.touched.serviceDetails && formik.errors.serviceDetails && (
                      <div className="error">{formik.errors.serviceDetails}</div>
                    )}

                  </div>
                  {/* <div className="mb-3">
                    <label htmlFor="price" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      pattern="[0-9]+([.,][0-9]+)?"
                      placeholder="Price"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <div className="error">{formik.errors.price}</div>
                    )}

                  </div> */}
                  <div className="mb-3">
                    <label htmlFor="location" className="form-label">
                      Shop Location
                    </label>
                    <input
                      type="text"
                      id="location"
                      name="location"
                      placeholder="Shop Location"
                      className="form-control py-4"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="error">{formik.errors.location}</div>
                    )}

                  </div>
                  <div className="mb-3">
                    <label htmlFor="number" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="number"
                      name="number"
                      placeholder="Phone Number"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.number}
                    />
                    {formik.touched.number && formik.errors.number && (
                      <div className="error">{formik.errors.number}</div>
                    )}

                  </div>

                  <div className="mb-3">
                    <label htmlFor="direction" className="form-label">
                      Google Maps Direction Link
                    </label>
                    <input
                      type="text"
                      id="direction"
                      name="direction"
                      placeholder="Enter the Google Maps direction link for your store"
                      className="form-control"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.direction}
                     
                    />
                    {formik.touched.direction && formik.errors.direction && (
                      <div className="error">{formik.errors.direction}</div>
                    )}
                 
                  </div>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={10}
                    center={mapCenter}
                    onClick={handleMapClick}
                  >
                    {markerPosition && <Marker position={markerPosition} />}
                  </GoogleMap>

                  <button type="submit" className="btn bg-primary mt-3 text-white">
                    Submit
                  </button>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreDetails