import React, { useState, useMemo } from "react";
import transport from "../../../assets/image/home.png";
import "./TransportForm.css";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useFormik } from "formik";
import {
  transportFormValidation,
  SuccessModal,
  FailureModal,
  auto,
  bus,
  car,
} from "../../../imports";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { Link } from "react-router-dom";

function TransportVendorRegister() {
  const [cities, setCities] = useState([
    "Dindigul",
    "Palani",
    "Coimbatore",
    "Pollachi",
  ]);
  const [selectedCity, setSelectedCity] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const libraries = ["places"];
  const mapContainerStyle = {
    // width: '',
    height: "300px",
    margin: "auto",
  };

  const defaultCenter = {
    lat: 10.35,
    lng: 77.95,
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      contactNumber: "",
      emailAddress: "",
      address: "",
      mapLocation: "",
      city: "",
      description: "",
    },
    validationSchema: transportFormValidation,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await axios.post(
        `${BASE_URL}/register/transport`,
        values
      );

      if (response.status === 200) {
        // console.log('Transport registration successful. Login credentials sent to your registered email. Use the provided username and password for Login.');

        setSuccessMessage(response.data);
        setSuccessModalOpen(true);
      }
    } catch (error) {
      console.error("Error creating profile:", error.response.data);
      setErrorMessage(error.response.data);
      setFailureModalOpen(true);
    }
  };
  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);
  };

  const { isLoaded, loadError } = useLoadScript(
    {
      googleMapsApiKey: "AIzaSyB9HRWEcSk53RuyZC_TJ2EsqC93hUnzqns",
      libraries,
    },
    [libraries]
  );

  const handleMapClick = (event) => {
    const { lat, lng } = event.latLng.toJSON();

    setMarkerPosition({ lat, lng });

    formik.setFieldValue("mapLocation", ` ${lat}, ${lng}`);
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <div className="row g-0 transport-contain ">
        <div className="col-12 col-lg-6 trans-bg-svg order-1 ">
          <div className="row trans-bg-svg-crds ">
            <div className="col col-lg-6 ">
              <div className="trans-card m-3 bounce-top ">
                <img src={bus} alt="Transport" />
              </div>
            </div>
            <div className="col col-lg-6 ">
              <div className="trans-card m-3 bounce-top">
                <img className="trans-card-img" src={auto} alt="Transport" />
              </div>
              <div className="trans-card m-3 bounce-top">
                <img src={car} alt="Transport" />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 transport-form order-2 py-5 "
          style={{ overflowY: "auto" }}
        >
          {successModalOpen && (
            <SuccessModal
              onClose={closeSuccessModal}
              message={successMessage}
              redirectTo="/vendor/login"
            />
          )}
          {failureModalOpen && (
            <FailureModal onClose={closeFailureModal} message={errorMessage} />
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="transport-form-fields">
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p className=" col-lg-11 col-sm-8 col-md-11  trans-bg-svg-crds-p ">
                  Register your service with
                  <span className="trans-bg-svg-crds-p-span">
                    &nbsp;Whistles
                  </span>
                  <br></br>and discover a whole new level of success!{" "}
                </p>
              </div>
              <input
                className="transport-input my-3"
                type="text"
                placeholder="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.name && formik.errors.name && (
                  <div className="frm-error">{formik.errors.name}</div>
                )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="number"
                name="contactNumber"
                placeholder="Contact Number"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.contactNumber &&
                  formik.errors.contactNumber && (
                    <div className="frm-error">
                      {formik.errors.contactNumber}
                    </div>
                  )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.emailAddress && formik.errors.emailAddress && (
                  <div className="frm-error">{formik.errors.emailAddress}</div>
                )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="text"
                name="address"
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.address && formik.errors.address && (
                  <div className="frm-error">{formik.errors.address}</div>
                )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="text"
                name="mapLocation"
                placeholder="Google Map Location"
                value={formik.values.mapLocation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.mapLocation && formik.errors.mapLocation && (
                  <div className="frm-error">{formik.errors.mapLocation}</div>
                )}
              </div>
              <div className="my-5 map-div bg-dark ">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  zoom={10}
                  center={defaultCenter}
                  onClick={handleMapClick}
                >
                  {markerPosition && <Marker position={markerPosition} />}
                </GoogleMap>
              </div>
              <br />
              <select
                id="city"
                className="transport-input my-3"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" disabled>
                  Select a city
                </option>
                {cities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
              <div className="error-div">
                {formik.touched.city && formik.errors.city && (
                  <div className="frm-error">{formik.errors.city}</div>
                )}
              </div>
              <textarea
                className="transport-input my-3"
                style={{ height: "60px" }}
                name="description"
                placeholder="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              ></textarea>
              <div className="error-div">
                {formik.touched.description && formik.errors.description && (
                  <div className="frm-error">{formik.errors.description}</div>
                )}
              </div>
              
              Already a Registered User ?{" "}
              <Link to="/vendor/login" className="transport-login-link">
                Login here
              </Link>
              <br></br>
              <button
                type="submit"
                className="my-3 text-white p-2 w-50 rounded"
                style={{ backgroundColor: "#2653c6" }}
              >
                Register
              </button>
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TransportVendorRegister;
