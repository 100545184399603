import React, { useState } from 'react';
import "./AdminLogin.css";
import { FaCircleUser, adminLoginValidation, RiLockPasswordFill, FaEye,FaUnlock , FaEyeSlash, SuccessModal, FailureModal } from "../../imports";
import axios from 'axios';
import { useFormik } from 'formik';
import { BASE_URL } from '../../env';
import { useAdminAuth } from "./AdminAuthProvider";

const AdminLoginForm = () => {
  const { adminLogin } = useAdminAuth();
  const [showPassword, setShowPassword] = useState(false);

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const closeSuccessModal = () => {
    setSuccessModalOpen(false);

  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);

  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: adminLoginValidation,
    onSubmit: async (values) => {
      // console.log(values)
      try {
        const response = await axios.post(`${BASE_URL}/admin/login`, values);


        if (response.status === 200) {
          // console.log(response.data);
          adminLogin(response.data.user);
          setSuccessMessage("Login Successful")
          setSuccessModalOpen(true);
          formik.resetForm();

        } else {
          // console.log(response.data.message);

        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("Invalid credentials");
        setFailureModalOpen(true)

      }
    },
  });
  const getRedirectRoute = (message) => {
    if (message === 'Login Successful') {
      return '/admin/dashboard';

    } else {

      return '/admin/login';
    }
  };

  return (
    <div>
      <div className="container-fluid AdminLogin-contain ">
        <div className="row">
          {successModalOpen && <SuccessModal onClose={closeSuccessModal} message={successMessage} redirectTo={getRedirectRoute(successMessage)} />}
          {failureModalOpen && <FailureModal onClose={closeFailureModal} message={errorMessage} />}
          <div className="col-11 col-lg-4 col-md-6  AdminLogin-form">
            <form onSubmit={formik.handleSubmit}>
              <h1 className='AdminLogin-h1'>Hello Admin !</h1>
              <div className="AdminLogin-input-box">
                <input type="text"
                  placeholder="Username"
                  required
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email} />
                <FaCircleUser className='Admin-login-icon' />
                {formik.touched.email && formik.errors.email && (
                  <span className='mt-2 error'>{formik.errors.email}</span>
                )}
              </div>
              

              <div className="AdminLogin-input-box">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password} />
                  {/* <div className=''>
                  <RiLockPasswordFill className='Admin-login-icon' />
                 < FaUnlock ></FaUnlock>
                  </div> */}
            <div className='icon' onClick={togglePasswordVisibility}>
                    {showPassword ? <FaUnlock className='Admin-login-icon' /> : <RiLockPasswordFill className='Admin-login-icon' />}
                
                  </div>
            
                {formik.touched.password && formik.errors.password && (
                  <span className='mt-2 error'>{formik.errors.password}</span>
                )}
              </div>

              <button type="submit" className="AdminLoginbutton" style={{backgroundColor:"white"}}>Login</button>
              <div className="AdminLogin-register-link">
                <p>Dont have an account? <a href="#">Register</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
}

export default AdminLoginForm