import React, { useState } from 'react';
import "./VegetableHomePage.css";

import { Link } from 'react-router-dom';
import { veg1, veg2, veg3, VegetableVendorHeader } from "../../../imports";

const VegetableHomepage = () => {
  const [currentImage, setCurrentImage] = useState(veg2);
  const imageWidths = [800, 1000, 800];
  
  const imgSlider = (newImage, color) => {
    setCurrentImage(newImage);
    changeCircleColor(color);
  };

  const changeCircleColor = (color) => {
    const circle = document.querySelector(".circle");
    if (circle) {
      circle.style.background = color;
    }
  };

  const toggleMenu = () => {
    const menuToggle = document.querySelector(".veg-toggle");
    var navigation = document.querySelector(".veg-navigation");
    if (menuToggle) {
      menuToggle.classList.toggle('active');
    }
    if (navigation) {
      navigation.classList.toggle('active')
    }
  };

  return (
    <div className="veg-section">
      <div className='circle'></div>
      
      <VegetableVendorHeader toggleMenu={toggleMenu}/>
      <div className="veg-content" >
     
        <div className="textBox " style={{}} >
          <h2>
          <span style={{color:'#333333'}}>Welcome to</span>   <br /> <span style={{color:'#2653c6'}}>Whistles</span>
          </h2>
          <p>
            Whistles is where vendors and users converge for mutual growth.
            Seamlessly connect with local vegetable vendors, enhance your
            business, and reach a broader audience. Elevate the vegetable
            shopping experience for users, and empower vendors to expand their
            presence.
          </p>
          <Link to="/vendor/vegetable/profile">Discover More</Link>
        </div>
        <div className='imgBox'>
          <img
            src={currentImage}
            className='veg-product  img-fluid'
            style={{ maxWidth: '100%', width: `${imageWidths[0]}px` }}
            alt="Vegetable Preview"
          />
        </div>
      </div>
      <ul className='thumb'>
        <li><img src={veg2} className='img-fluid' onClick={() => imgSlider(veg2, "#2653c6")} alt="Thumbnail" /></li>
        <li><img src={veg1} onClick={() => imgSlider(veg1, "#2653c6")} alt="Thumbnail" /></li>
        <li><img src={veg3} onClick={() => imgSlider(veg3, "#2653c6")} alt="Thumbnail" /></li>
      </ul>
    </div>
  );
}

export default VegetableHomepage;
