import React, { useEffect, useState } from "react";
import "../../Modals/ResponseModal.css";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { VendorImgReplaceModal, IoMdCloseCircle } from "../../../imports";

const TransportVendorViewImage = ({ userId, onClose, posterImage }) => {
  const [imagePaths, setImagePaths] = useState([]);
  const [isDisplayModalOpen, setIsDisplayModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedimgFile, setselectedimgFile] = useState(null);
  const [selectedImagePath, setSelectedImagePath] = useState(null);
  useEffect(() => {
    fetchImagePaths();
  }, []);

  const fetchImagePaths = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/transport/storeimage/${userId}`
      );
      // console.log(response, "response for image path")
      setImagePaths(response.data.imagePaths || []);
    } catch (error) {
      console.error("Error fetching image paths:", error);
    }
  };

  const handleReplace = (imagePath) => {
    // Open file explorer
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
    setSelectedImagePath(imagePath);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setselectedimgFile(file);
    }
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
    }
  };

  const handleImgUpload = async () => {
    //  console.log(selectedimgFile,"file image")
      try {
        const formData = new FormData();
        formData.append('user_id', userId);
        formData.append('image_path', selectedimgFile);
        formData.append('selected_image_path', selectedImagePath);
        const response = await axios.post(`${BASE_URL}/transport/imgReplace`, formData);
        console.log(response.data);
        setSelectedImage(null);
        fetchImagePaths();
      } catch (error) {
        console.error('Error replacing image path:', error);
      }
  };

  return (
    <>
      <div className="vendor-veg-img-overlay">
        <div className="vendor-veg-img-content">
          <div onClick={onClose} className="close-main">
            <IoMdCloseCircle className="close-circle-icon" />
          </div>

          <div className="image-scroll-container">
            {imagePaths.map((imagePath, index) => (
              <div key={index} className="img-border">
                {imagePath !== posterImage && (
                  <p
                    className="mt-2 store-pto-replace"
                    onClick={() => handleReplace(imagePath)}
                  >
                    Replace
                  </p>
                )}
                <img
                  src={`${BASE_URL}/vegetablestoreimages/${imagePath}`}
                  alt={`Image ${index}`}
                  className="img-fluid"
                />
              </div>
            ))}

            <input
              type="file"
              id="imageInput"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            {selectedImage && (
              <VendorImgReplaceModal
                imageUrl={selectedImage}
                onClose={() => setSelectedImage(null)}
                handleImgUpload={handleImgUpload}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransportVendorViewImage;
