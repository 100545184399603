import React, { useState } from 'react';
import "./Login.css";
import { FaCircleUser, RiLockPasswordFill,FaEye, FaEyeSlash,SuccessModal,FailureModal } from "../../imports";
import axios from "axios";
import { BASE_URL } from '../../env';
import { ToastContainer, toast } from 'react-toastify';
import {Modal,Button} from "react-bootstrap";


const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ""

  })
  const [loading, setLoading] = useState(false);
  const[showModal,setShowModal]=useState(false);
  const[modalMessage,setModalMessage]=useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Modals

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const[successMessage,setSuccessMessage]=useState("");
  const [errorMessage, setErrorMessage] = useState("");

  
  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
    
  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);
   
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  };

  

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${BASE_URL}/userlogin`, formData);
      if (response.status === 200) {
        console.log('User logged in successfully');
        setSuccessMessage(response.data.message)
        setSuccessModalOpen(true);
       
        // setModalMessage('User logged in successfully')
        // setShowModal(true);
      } else if (response.status === 403) {
        // console.log('Your profile is under verification', response.data.message);
        // setModalMessage( response.data.message);
        // setShowModal(true);
        setErrorMessage(response.data.message);
        setFailureModalOpen(true)
      } else {
        setErrorMessage(response.data.message);
        setFailureModalOpen(true)
        console.error('Error in login:', response.data.message);
      }
    } catch (error) {
      // setModalMessage( error.response.data.message);
      // setShowModal(true);
      setErrorMessage(error.response.data.message);
        setFailureModalOpen(true)
      console.log('Error in user login:', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };


  const closeModal = () => {
    setShowModal(false);
  };

  return (
    
    <>
      <div className='login-page'>
      {successModalOpen && <SuccessModal onClose={closeSuccessModal} message={successMessage}  redirectTo="/"/>}
      {failureModalOpen && <FailureModal onClose={closeFailureModal} message={errorMessage} />}
      <div className='login-container'>
        <div className='design'>
          <div className='pill-1 rotate-45'></div>
          <div className='pill-2 rotate-45'></div>
          <div className='pill-3 rotate-45'></div>
          <div className='pill-4 rotate-45'></div>
        </div>
        <form onSubmit={handleLogin}>
          <div className='login'>
            <h3 className='title'>User Login</h3>
            <div className='text-input'>
              <FaCircleUser className='login-icon' />
              <input
                type="email"
                placeholder="Email "
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className='text-input'>
              <RiLockPasswordFill className='login-icon' />
              {/* <input
                type="password"
                placeholder="Password"
                name='password'
                value={formData.password}
                onChange={handleInputChange}
              /> */}
                 <div className='password-input-container'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    name='password'
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="toggle-password-button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
            </div>
            <button className='login-btn' onClick={handleLogin}>{loading ? 'loading...' : 'LOGIN'}</button>
            <div>
              <div className="create">
                <a href="/userregitration" style={{ color: "#2653c6" }}>Create Your Account</a>
              </div>
            </div>
            <ToastContainer />
          </div>
        </form>
      </div>
    </div>
    <div className='login-result-modal'>
    <Modal show={showModal} onHide={closeModal} className='d-flex items-center justify-center '>
        
        <Modal.Body className='text-capitalize '>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <p className='text-danger' onClick={closeModal}>
            Close
          </p>
        </Modal.Footer>
      </Modal>
      </div>
    </>
  
  );
}

export default Login;
