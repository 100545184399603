import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../env";
import ProfessionalServiceCard from "./ProfessionalServiceCard";
import { MdOutlineDelete, MdOutlineEdit } from "react-icons/md";
import { FcCurrencyExchange, FcBookmark } from "react-icons/fc";
import { TbListDetails } from "../../../imports";

const AddProfessionalService = ({ vendorUser }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [professionalCategories, setProfessionalCategories] = useState([]);
  const [editServiceId, setEditServiceId] = useState(null);
  const [service, setService] = useState("");
  const [serviceDetail, setServiceDetail] = useState("");
  const [price, setPrice] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [professionalCategory, setProfessionalCategory] = useState("");
  const [categorizedProducts, setCategorizedProducts] = useState({});

  const [showProductModal, setProductShowModal] = useState(false);
  const [productName, setProductName] = useState("");
  const [productPrice, setProductPrice] = useState(null);
  const [productDiscount, setProductDiscount] = useState(null);
  const [professionalProducts, setProfessionalProducts] = useState([]);
  const [productModalMode,setProductModalMode]=useState("add");
  const[productEditId,setProductEditId]=useState(null);

  useEffect(() => {
    fetchProfessionalCategories();
    fetchProfessionalServices();
    fetchProfessionalProducts();
  }, []);

  const fetchProfessionalCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/professional/category`
      );
      setProfessionalCategories(response.data);
    } catch (error) {
      console.error("Error fetching professional categories:", error.message);
    }
  };

  const fetchProfessionalServices = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/professional/vendorServices/${vendorUser.id}`
      );
      const services = response.data;

      const categorizedServices = {};
      services.forEach((service) => {
        if (!categorizedServices[service.professionalcategory]) {
          categorizedServices[service.professionalcategory] = [];
        }
        categorizedServices[service.professionalcategory].push(service);
      });

      setCategorizedProducts(categorizedServices);
    } catch (error) {
      console.error("Error fetching professional services:", error.message);
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
    fetchProfessionalCategories();
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMode("add");
    setEditServiceId(null);

    setProfessionalCategory("");
    setService("");
    setServiceDetail("");
    setDiscount("");
    setPrice("");
  };

  const handleAddService = async () => {
    if (
      !professionalCategory ||
      !service ||
      !serviceDetail ||
      !price ||
      !discount
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      const requestBody = {
        user_id: vendorUser.id,
        professionalcategory: professionalCategory,
        service_name: service,
        service_detail: serviceDetail,
        price: price,
        discount: discount,
      };

      if (modalMode === "add") {
        await axios.post(`${BASE_URL}/professional/addservice`, requestBody);
      } else if (modalMode === "edit" && editServiceId) {
        await axios.put(
          `${BASE_URL}/professional/vendorServices/${vendorUser.id}/${editServiceId}`,
          requestBody
        );
      }

      fetchProfessionalServices();

      handleCloseModal();
    } catch (error) {
      console.error("Error adding/editing professional service:", error);
      alert("Failed to add/edit professional service. Please try again.");
    }
  };

  const handleEditService = (service) => {
    setModalMode("edit");
    setEditServiceId(service.id);

    setProfessionalCategory(service.professionalcategory);
    setService(service.service_name);
    setServiceDetail(service.service_detail);
    setDiscount(service.discount);
    setPrice(service.price);
    handleShowModal();
  };

  const handleDeleteService = async (serviceId) => {
    try {
      await axios.delete(
        `${BASE_URL}/professional/vendorServices/${vendorUser.id}/${serviceId}`
      );

      fetchProfessionalServices();
    } catch (error) {
      console.error("Error deleting professional service:", error);
      alert("Failed to delete professional service. Please try again.");
    }
  };

  const handleProductShowModal = () => {
    setProductShowModal(true);
  };

  const handleProductCloseModal = () => {
    setProductShowModal(false);
    setProductName("");
    setProductPrice(null);
    setProductDiscount(null);
  };

  // product edit
  const handleEditProduct = (product) => {
    // console.log("Edit Product Clicked",product);
    setProductModalMode("edit");
    setProductEditId(product.id);
  
    setProductName(product.product_name);
    setProductPrice(product.rate);
    setProductDiscount(product.discount);
  
    handleProductShowModal();
  };
  
  const handleAddProduct = async () => {
    try {
      const requestBody = {
        user_id: vendorUser.id,
        product_name: productName,
        rate: productPrice,
        discount: productDiscount,
      };
  
      if (productModalMode ==="add") {
        await axios.post(`${BASE_URL}/professionalVendor/addProduct`, requestBody);
        console.log("Add Product Request");
      } else if (productModalMode ==="edit" && productEditId) {
        await axios.put(
          `${BASE_URL}/vendor/professional/product/edit/${vendorUser.id}/${productEditId}`,
          requestBody
        );
        console.log("Edit Product Request");
      }
  
      fetchProfessionalProducts();
      handleProductCloseModal();
    } catch (error) {
      console.error("Error adding/editing professional product:", error);
      alert("Failed to add/edit professional product. Please try again.");
    }
  };
  
  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(
        `${BASE_URL}/professional/vendorProducts/${vendorUser.id}/${productId}`
      );

      fetchProfessionalProducts();
    } catch (error) {
      console.error("Error deleting professional service:", error);
      alert("Failed to delete professional service. Please try again.");
    }
  };


  const fetchProfessionalProducts = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/professional/vendorProducts/${vendorUser.id}`
      );
      const products = response.data;

      setProfessionalProducts(products);
    } catch (error) {
      console.error("Error fetching professional products:", error.message);
    }
  };

 
  return (
    <div>
      <div className="d-flex justify-space-between ">
        <button onClick={handleShowModal} className="veg-add-product-btn">
          Add Service
        </button>
        <button
          onClick={handleProductShowModal}
          className="veg-add-product-btn "
        >
          Add Product
        </button>
      </div>
      <div>
        {professionalProducts.length > 0 && (
          <div className="vendorProduct-scroll">
            <div className="vendorProduct-containerbox">
              <h2 className="vendorProduct-title mx-auto">Products</h2>
              <div className="vendorProduct-scroller  mt-3">
                <div className="d-flex">
                  {professionalProducts.map((product) => (
                    <div
                      key={product.id}
                      className="vendorProduct-box"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      <div className="vendorProduct-icon">
                        <MdOutlineEdit
                          className="vendorProduct-edit "
                          onClick={()=>handleEditProduct(product)}
                        />
                        <MdOutlineDelete className="vendorProduct-delete" onClick={()=>handleDeleteProduct(product.id)}/>
                      </div>
                      <h6 className="vendorProduct-heading d-flex gap-1">
                        <span className="service-heading-vendor d-flex gap-1">
                          <TbListDetails
                            className="vendorProduct-iconbar"
                            color="#FDBF60"
                          />
                          {product.product_name}
                        </span>
                      </h6>
                      <div className="d-flex gap-1 p-1">
                        <span>
                          <FcCurrencyExchange className="vendorProduct-iconbar" />
                        </span>
                        <span>
                          <span className="service-heading-vendor">
                            Price :
                          </span>{" "}
                          {product.discount > 0
                            ? `${product.rate} - ${product.discount}% = ${
                                product.rate -
                                (product.rate * product.discount) / 100
                              }`
                            : product.rate}
                        </span>
                      </div>
                      {product.discount > 0 && (
                        <div className="d-flex gap-1 p-1">
                          <span>
                            <FcBookmark className="vendorProduct-iconbar" />
                          </span>
                          <span>
                            <span className="service-heading-vendor">
                              Discount :
                            </span>{" "}
                            {product.discount} %
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {Object.entries(categorizedProducts).map(
        ([category, categoryServices]) => (
          <div key={category}>
            <div className="vendorProduct-scroll">
              <div className="vendorProduct-containerbox">
                <h2 className="vendorProduct-title mx-auto">{category}</h2>
                <div>
                  <div className="vendorProduct-scroller  mt-3">
                    {categoryServices.map((service) => (
                      <Col key={service.id} md={4}>
                        <ProfessionalServiceCard
                          service={service}
                          onEdit={handleEditService}
                          onDelete={handleDeleteService}
                        />
                      </Col>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      <Modal show={showModal} onHide={handleProductCloseModal}>
        <Modal.Body>
          <Form>
            <Form.Group controlId="professionalCategory">
              <Form.Label>Professional Category</Form.Label>
              <Form.Control
                as="select"
                value={professionalCategory}
                onChange={(e) => setProfessionalCategory(e.target.value)}
              >
                <option value="" disabled>
                  Select a professional category
                </option>
                {professionalCategories.map((cat) => (
                  <option key={cat.id} value={cat.professionalCategory}>
                    {cat.professionalCategory}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="service">
              <Form.Label>Service</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service"
                value={service}
                onChange={(e) => setService(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="serviceDetail">
              <Form.Label>Service Detail</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter service detail"
                value={serviceDetail}
                onChange={(e) => setServiceDetail(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="discount">
              <Form.Label>Discount in Percentage </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-danger" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button className="bg-primary" onClick={handleAddService}>
            {modalMode === "add"
              ? "Add Professional Service"
              : "Edit Professional Service"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showProductModal} onHide={handleProductCloseModal}>
        <Modal.Body>
          <Form>
            <Form.Group controlId="productName">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter product name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="price">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter price"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="discount">
              <Form.Label>Discount in Percentage</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter discount"
                value={productDiscount}
                onChange={(e) => setProductDiscount(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-danger" onClick={handleProductCloseModal}>
            Cancel
          </Button>
          <Button className="bg-primary" onClick={handleAddProduct}>
           {productModalMode ==="add"? "Add  Product" :"Edit  Product"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddProfessionalService;
