import React, { useEffect, useState } from 'react';
import '../../vegetablesession/postlogin/StoreImageUploading.css';
import { imageupload, SuccessModal, FailureModal } from '../../../../imports';
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import { useVendorAuth } from '../../VendorAuthContext';

const ProfessionalImageUploading = () => {
    const { vendorUser } = useVendorAuth();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [failureModalOpen, setFailureModalOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        openModal();
    }, []);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleImageChange = (e) => {
        const files = e.target.files;
        const selectedFiles = Array.from(files).slice(0, 5);
        setSelectedImages(selectedFiles);
    };

    const handleImageSubmit = async () => {
        try {
            const formData = new FormData();
            selectedImages.forEach((file) => {
                formData.append('images', file);
            });

            formData.append('user_id', vendorUser.id);
            const response = await axios.post(`${BASE_URL}/upload-professional-image`, formData);

            if (response.status === 200) {
                console.log('Images uploaded successfully');
                setSuccessMessage('Profile Created Successfully Your Profile Will get Verified within 2 business days');
                setSuccessModalOpen(true);
            } else {
                setErrorMessage('Error in Uploading images. Please try again.');
                setFailureModalOpen(true);
                console.error('Failed to upload images');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
    };

    const closeFailureModal = () => {
        setFailureModalOpen(false);
    };

    return (
        <div className='storeImage-page'>
            <div className={`modal-wrapper ${isModalOpen ? 'open' : 'close'}`}>
                <div className='modal-content'>
                    {successModalOpen && (
                        <SuccessModal onClose={closeSuccessModal} message={successMessage} redirectTo='/' />
                    )}
                    {failureModalOpen && (
                        <FailureModal onClose={closeFailureModal} message={errorMessage} />
                    )}
                    <img src={imageupload} className='img-fluid veg-upload-img mx-auto' />
                    <h2 className='text-center text-danger mb-4'>
                        Select 5 images of your Professional Services
                    </h2>
                    <div>
                        <label htmlFor='imageUpload' className='btn btn-primary'>
                            Upload Images
                        </label>
                        <input
                            type='file'
                            id='imageUpload'
                            name='imageUpload'
                            accept='image/*'
                            multiple
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                        />
                        <div className='img-displaying-space'>
                            {selectedImages.length > 0 && (
                                <>
                                    <div className='horizontal-scroll-container'>
                                        <ul className='d-flex'>
                                            {selectedImages.map((file, index) => (
                                                <li key={index} className='selected-image-container'>
                                                    <img
                                                        src={URL.createObjectURL(file)}
                                                        alt={file.name}
                                                        className='selected-image'
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className='img-upload-btn'>
                                        <button onClick={closeModal} className='img-cancel'>
                                            Cancel
                                        </button>
                                        <button onClick={handleImageSubmit} className='img-upload'>
                                            Submit
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfessionalImageUploading;
