import React from 'react'
import "./TransportHero.css";
import {arrow_btn,play_icon,pause_icon} from "../../../../../imports";

const TransportVendorHero = ({heroData,setHeroCount,heroCount,setPlayStatus,playStatus}) => {
  return (
    <div className='transport-vendor-hero '>
<div className='transport-vendor-hero-text col-sm-12 text-lg-start text-center'>
  <p>{heroData.text1}</p>
  <p>{heroData.text2}</p>
</div>

<div className='col-sm-12 col-md-12'>
<div className='transport-hero-explore'>
<p>Explore the features</p>
<img src={arrow_btn} alt="arrow-btn"/>
</div>

</div>
 
<div className='transport-vendorhero-dot-play col-12 '>
  <ul className='transport-hero-dots '>
    <li onClick={()=>setHeroCount(0)} className={heroCount===0? "hero-dot orange":"hero-dot"}>.</li>
    <li   onClick={()=>setHeroCount(1)}  className={heroCount===1? "hero-dot orange":"hero-dot"}>.</li>
    <li   onClick={()=>setHeroCount(2)}  className={heroCount===2? "hero-dot orange":"hero-dot"}>.</li>
    </ul>
    <div className='transport-hero-play '>
      <img onClick={()=>setPlayStatus(!playStatus)} src={playStatus ?pause_icon:play_icon } alt="pause icon"/>
      <p>See the Video</p>
    </div>
</div>
    </div>
  )
}

export default TransportVendorHero