import React from 'react'
import "./SuccessModal.css";
import { FaExclamation} from "../../imports";

const FailureModal = ({ onClose ,message}) => {
  return (
    <div className='modal-centering'>
    <div className="modal-dialog modal-confirm">
  <div className="modal-content">
    <div className="modal-header">
      <div className="icon-box">
       <FaExclamation className='modal-icon'/>
      </div>
      {/* <h4 className="modal-title w-100">Awesome!</h4> */}
    </div>
    <div className="modal-body">
      <p className="text-center">{message}</p>
    </div>
    <div className="modal-footer">
      <button className="btn btn-success btn-block w-100"  onClick={onClose}>Done</button>
    </div>
  </div>
</div>
</div>
  )
}

export default FailureModal