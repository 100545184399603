import React from 'react';
import { rightarrow, leftarrow, ProfessionalHeader, professionalbg1, professionalbg2, professionalbg3 } from '../../../imports';
import "./ProfessionalVendor.css";

const ProfessionalLandingPage = () => {
  const carouselItemStyle1 = {
    backgroundImage: `url(${professionalbg1})`,
  };

  const carouselItemStyle2 = {
    backgroundImage: `url(${professionalbg2})`,
  };

  const carouselItemStyle3 = {
    backgroundImage: `url(${professionalbg3})`,
  };

  return (
    <div>
      <ProfessionalHeader />
      <div className='home'>
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className='control-slide'>
            <ol className="carousel-indicators">
              <li type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className='active' aria-current="true" aria-label="Slide 1" style={carouselItemStyle1}></li>
              <li type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" style={carouselItemStyle2}></li>
              <li type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" style={carouselItemStyle3}></li>
            </ol>

            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
              <img src={leftarrow} alt="Previous" />
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
              <img src={rightarrow} alt="Next" />
            </button>
          </div>

          <div className="carousel-inner">
            <div className="carousel-item active" style={carouselItemStyle1}>
              <div class="container">
                <h2>Electrician Services</h2>
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  <div className='col-12 col-lg-8 text-center' >
                    <p>Join Whistles and expand your electrician services to a large user base. Connect with customers looking for reliable electrical solutions.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" style={carouselItemStyle2}>
              <div class="container">
                <h2>Mechanic Services</h2>
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  <div className='col-12 col-lg-8 text-center' >
                  <p>Whistles connects mechanics with a vast user network. Expand your reach and offer your expertise to users in need of mechanical services.</p>
               </div>
                </div>
                 </div>
            </div>
            <div className="carousel-item" style={carouselItemStyle3}>
              <div className='container'>
                <h2>Home Appliance Repair</h2>
                <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  <div className='col-12 col-lg-8 text-center' >
                  <p>Partner with Whistles to provide home appliance repair services. Reach a larger audience and grow your business in the appliance repair industry.</p>
              </div>
                </div>
                 </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ProfessionalLandingPage;
