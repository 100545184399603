import React,{useState,useEffect} from 'react'
import "./TransportVendor.css";
import {TransportVendorBackground, TranportVendorNavbar,TransportVendorHero} from "../../../imports";

const TransportVendorHome = () => {

  let heroData =[
    {text1:"Dive into",text2:"What you love"},
    {text1:"Indulge",text2:"your passions"},
    {text1:"Give in to",text2:"your passions"}
  ]
const[heroCount,setHeroCount]=useState(2);
const[playStatus,setPlayStatus]=useState(false);



useEffect(() => {
  const intervalId = setInterval(() => {
    setHeroCount((count) => (count === 2 ? 0 : count + 1));
  }, 5000);

  return () => clearInterval(intervalId); 
}, []);
  return (
    <div>
      
      <TransportVendorBackground playStatus={playStatus} heroCount={heroCount}/>
      <TranportVendorNavbar/>
     <TransportVendorHero setPlayStatus={setPlayStatus} heroData={heroData[heroCount]} heroCount={heroCount}
     setHeroCount={setHeroCount} playStatus={playStatus}/>
    </div>
  )
}

export default TransportVendorHome