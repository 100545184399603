import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


const EditTransportProfileCardModal = ({ profileData, onClose, onSave }) => {
  const [editedProfileData, setEditedProfileData] = useState({ ...profileData });

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setEditedProfileData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    console.log("edited profile data from modal", editedProfileData);
    onSave(editedProfileData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Your Profile Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your name"
              value={editedProfileData.name}
              onChange={(e) => handleInputChange(e, 'name')}
            />
          </Form.Group>

          <Form.Group controlId="vehicleType">
            <Form.Label>Vehicle Type</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your vehicle type"
              value={editedProfileData.vehicle_type}
              onChange={(e) => handleInputChange(e, 'vehicle_type')}
            />
          </Form.Group>

          <Form.Group controlId="vehicleModel">
            <Form.Label>Vehicle Model</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your vehicle model"
              value={editedProfileData.vehicle_model}
              onChange={(e) => handleInputChange(e, 'vehicle_model')}
            />
          </Form.Group>

          <Form.Group controlId="yearsOfExperience">
            <Form.Label>Years Of Experience</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your years of experience"
              value={editedProfileData.years_of_driving_experience}
              onChange={(e) => handleInputChange(e, 'years_of_driving_experience')}
            />
          </Form.Group>

          <Form.Group controlId="availableHours">
            <Form.Label>Available Hours</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your available hours"
              value={editedProfileData.days_and_hours_available}
              onChange={(e) => handleInputChange(e, 'days_and_hours_available')}
            />
          </Form.Group>
          <Form.Group controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your location"
              value={editedProfileData.address}
              onChange={(e) => handleInputChange(e, 'address')}
            />
          </Form.Group>

          {/* <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your phone number"
              value={editedProfileData.phoneNumber}
              onChange={(e) => handleInputChange(e, 'phoneNumber')}
            />
          </Form.Group> */}


          {/* Add more fields in a similar manner */}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-danger' onClick={onClose}>
          Cancel
        </Button>
        <Button className='bg-primary' onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTransportProfileCardModal;
