import React, { useState, useEffect } from "react";
import "./VendorRegsitration.css";
import { useFormik } from "formik";
import { useNavigate, } from "react-router-dom";
import {
  vendorRegistrationSchema,
  FaCaretLeft,
  SuccessModal,
  FailureModal,
  vendorregister,
  IoMdArrowRoundBack,
} from "../../../imports";
import { BASE_URL } from "../../../env";
import axios from "axios";

const VendorRegistration = () => {
  const navigate = useNavigate();
  const [cities, setCities] = useState([
    "Dindigul",
    "Palani",
    "Coimbatore",
    "Pollachi",
  ]);
  const validationSchema = vendorRegistrationSchema;
  useEffect(() => {
 
    window.scrollTo(0, 0)
  }, [])
  


  const formik = useFormik({
    initialValues: {
      name: "",
      phoneNumber: "",
      email: "",
      location: "",
      city: "",
      category: "",
      storename: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (formData) => {
    // console.log("formdata in vendor login", formData);

    axios
      .post(`${BASE_URL}/vendor/onboard`, formData)
      .then((response) => {
        // console.log("Success:", response.data);
        setSuccessMessage(response.data);
        setSuccessModalOpen(true);
        formik.resetForm();
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        setErrorMessage(error.response.data);
        setFailureModalOpen(true);
      });
  };

  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);
  };

  return (
    <div className="vendor-page" >
      {successModalOpen && (
        <SuccessModal
          onClose={closeSuccessModal}
          message={successMessage}
          redirectTo="/vendor/login"
        />
      )}
      {failureModalOpen && (
        <FailureModal onClose={closeFailureModal} message={errorMessage} />
      )}
      <p
        className="d-flex align-items-center mx-2 cursor-pointer"
        onClick={() => navigate("/")}
      >
        {" "}
        <IoMdArrowRoundBack className="mx-1" />
        <ins className="text-primary">Back To Home Page</ins>
      </p>

      <div className="form-area my-4 " >
        <div className="container" >
          <div className="row single-form g-0" >
            <div className="col-sm-12 col-lg-6 frm-left">
              {/* <div className="left">
                <img src={vendorregister} />
                <h2 className="text-focus-in">
                  <span>Elevate your business </span> <br /> with Webble Way{" "}
                </h2>
              </div> */}

              <div className="frm-left-cntdiv" >
                <div className="frm-left-imgdiv">
                <img  src={vendorregister} />
                </div>
        
              <h2 className="text-focus-in">
                  <span>Elevate your business </span> 
                  <span className="text-focus-in-www"><br /> with Whistles.ai{" "}</span>
                </h2>
              </div>
            </div>
            <div className="col-sm-12 col-lg-6">
              <div className="right">
                <FaCaretLeft className="vendor-icon" />
                <form onSubmit={formik.handleSubmit}>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Your Name"
                      className="form-control"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <span className="error">{formik.errors.name}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="storename" className="form-label">
                      Store Name
                    </label>
                    <input
                      type="text"
                      id="storename"
                      name="storename"
                      placeholder="Your Store Name"
                      className="form-control"
                      value={formik.values.storename}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.storename && formik.errors.storename && (
                      <span className="error">{formik.errors.storename}</span>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="phoneNumber" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="number"
                      id="phone"
                      name="phoneNumber"
                      value={formik.values.phoneNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Your Phone Number"
                      className="form-control"
                    />
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber && (
                        <div className="error">{formik.errors.phoneNumber}</div>
                      )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email Address"
                      className="form-control"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <span className="error">{formik.errors.email}</span>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="location" className="form-label">
                      Address
                    </label>
                    <textarea
                      type="text"
                      id="location"
                      name="location"
                      value={formik.values.location}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Your Address"
                      className="form-control"
                    />
                    {formik.touched.location && formik.errors.location && (
                      <div className="error">{formik.errors.location}</div>
                    )}
                  </div>
                  <div className="mb-3 ">
                    <label htmlFor="city" className="form-label">
                      City
                    </label>
                    <select
                      id="city"
                      name="city"
                      value={formik.values.city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-select"
                      placeholder="Select a city"
                    >
                      <option value="" disabled>
                        Select a city
                      </option>
                      {cities.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    {formik.touched.city && formik.errors.city && (
                      <div className="error">{formik.errors.city}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Category
                    </label>
                    <select
                      id="category"
                      name="category"
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-select"
                      placeholder="select a category"
                    >
                      <option value="" disabled>
                        Select a category
                      </option>
                      <option value="vegetables">Vegetables</option>
                      <option value="pharmacy">Pharmacy</option>
                      <option value="transport">Transport</option>
                      <option value="hotels">Hotels</option>
                      <option value="professionals">Professionals</option>
                    </select>
                    {formik.touched.category && formik.errors.category && (
                      <div className="error">{formik.errors.category}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      type="text"
                      id="description"
                      name="description"
                      placeholder="Your  Description"
                      className="form-control"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div className="error">{formik.errors.description}</div>
                      )}
                  </div>

                  <p
                    className="text-primary text-end arrow-pointer"
                    onClick={() => navigate("/vendor/login")}
                  >
                    Already a registered user ? Login here{" "}
                  </p>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRegistration;
