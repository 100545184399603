import React from "react";
import { Card, Button } from "react-bootstrap";
import "../vegetablesession/ProfileCreation.css";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { FcCurrencyExchange } from "react-icons/fc";
import { FcBookmark } from "react-icons/fc";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FcViewDetails } from "react-icons/fc";
import { FcServices } from "react-icons/fc";
import { TbListDetails, MdChecklistRtl } from "../../../imports";
const ProfessionalServiceCard = ({ service, onEdit, onDelete }) => {
  const hasDiscount = service.discount > 0;
  const discountedAmount = hasDiscount
    ? (service.price * service.discount) / 100
    : 0;
  const discountedPrice = hasDiscount ? service.price - discountedAmount : null;
  return (
    <div>
      <div className="vendorProduct-box">
        <div className="vendorProduct-icon">
          <MdOutlineEdit
            className="vendorProduct-edit "
            onClick={() => onEdit(service)}
          />
          <MdOutlineDelete
            className="vendorProduct-delete"
            onClick={() => onDelete(service.id)}
          />
        </div>
        <h6 className="vendorProduct-heading d-flex gap-1">
          <span className="service-heading-vendor d-flex gap-1">
            <TbListDetails className="vendorProduct-iconbar" color="#FDBF60" />
            {service.service_name}
          </span>
        </h6>

        <div className=" d-flex gap-1 p-1">
          <span>
            <MdChecklistRtl className="vendorProduct-iconbar" color="#4169E1" />
          </span>
          <span>
            <span className="service-heading-vendor">Service Detail : </span>
            {service.service_detail}{" "}
          </span>
        </div>
        <div className=" d-flex gap-1 p-1">
          <span>
            <FcCurrencyExchange className="vendorProduct-iconbar" />
          </span>
          <span>
            {" "}
            <span className="service-heading-vendor">Price :</span>{" "}
            {hasDiscount
              ? `${service.price} - ${service.discount}% = ${discountedPrice}`
              : service.price}
          </span>
        </div>

        {service.discount > 0 && (
          <div className=" d-flex gap-1 p-1">
            <span>
              <FcBookmark className="vendorProduct-iconbar" />
            </span>
            <span>
              <span className="service-heading-vendor">Discount :</span>{" "}
              {service.discount} %
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default ProfessionalServiceCard;
