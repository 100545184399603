import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditVegProfileCardModal = ({ profileData, onClose, onSave }) => {
  const [editedProfileData, setEditedProfileData] = useState({ ...profileData });

  
  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    setEditedProfileData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  

  const handleSave = () => {
    console.log("edited profile data from modal",editedProfileData)
    onSave(editedProfileData);
    onClose();
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Your Profile Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="storeName">
            <Form.Label>Store Name</Form.Label>
            <Form.Control
              type="text"
              value={editedProfileData.storeName}
              onChange={(e) => handleInputChange(e, 'storeName')}
            />
          </Form.Group>

          <Form.Group controlId="reviews">
            <Form.Label>Review</Form.Label>
            <Form.Control
              type="number"
              value="No Reviews"
              readOnly
            />
          </Form.Group>

          {/* <Form.Group controlId="price">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              value={editedProfileData.price}
              onChange={(e) => handleInputChange(e, 'price')}
            />
          </Form.Group> */}

          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={editedProfileData.description}
              onChange={(e) => handleInputChange(e, 'description')}
            />
          </Form.Group>

          <Form.Group controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              value={editedProfileData.location}
              onChange={(e) => handleInputChange(e, 'location')}
            />
          </Form.Group>

          <Form.Group controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="number"
              value={editedProfileData.phoneNumber}
              onChange={(e) => handleInputChange(e, 'phoneNumber')}
            />
          </Form.Group>

          {/* Add more fields as needed */}

           {/* <Form.Group controlId="googleMapsLink">
            <Form.Label>Google Maps Link</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Google Maps Link"
              value="Open in Google Maps"
              readOnly
            />
          </Form.Group>  */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-danger' onClick={onClose}>
          Cancel
        </Button>
        <Button className='bg-primary' onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditVegProfileCardModal;
