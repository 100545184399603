import React,{useState} from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { VendorCategorySelectionModal,MdEmail, MdCall,MdLocationOn, FaWhatsapp,FaInstagram ,GrFacebookOption} from "../../imports";

function Footer() {
   const navigate =useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const currentYear = new Date().getFullYear();

  
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCategorySelect=(category)=>{
    setSelectedCategory(category)
   
    if(category==="Transport"){
      navigate("/vendor/transport/registration")
    }else{
      navigate("/vendor")
    }

  }
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <div className=" professional-footer-bg">
        <div className="container-fluid  ">
          <div className="container py-5 cursor-pointer">
            <div className=" my-3  footer-top">
              <div>
                <h4
                  style={{ color: "white", fontSize: "30px", padding: "7px" }}
                >
                  Whistles
                </h4>
              </div>
              <div
                className="pro-top-center-link-div"
                style={{ padding: "7px" }}
              >
                <h2>
                  Register as a vendor{" "}
                  <Link  className="pro-top-center-link" onClick={() => { openModal(); scrollToTop(); }}>
                    Create account{" "}
                  </Link>
                </h2>
                <span className="mx-2">(or)</span>
                <h2>
                  Already have an account{" "}
                  <Link to="/vendor/login" className="pro-top-center-link">
                    Login here
                  </Link>{" "}
                </h2>
              </div>
              <div className="footsocialicon">
                <Link className="socialiconspan">
                  <FaInstagram className="pro-social-icons-style" />
                </Link>
                <Link className="socialiconspan">
                  <FaWhatsapp className="pro-social-icons-style" />
                </Link>
                <Link className="socialiconspan">
                  <GrFacebookOption className="pro-social-icons-style" />
                </Link>
              </div>
            </div>
            <hr></hr>
            <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2  g-lg-3  my-5">
              <div className="col professional-col my-3">
                <div>
                  <h5
                    className=" professionaltit-h5  "
                    style={{ marginBottom: "10px" }}
                  >
                    Quicky Links
                  </h5>
                  <li className="professional-li">
                    <Link
                      to="/first-link"
                      className="professional-link professional-a"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/second-link"
                      className="professional-link professional-a"
                    >
                      About
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/third-link"
                      className="professional-link professional-a"
                    >
                      Product&Services
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/fourth-link"
                      className="professional-link professional-a"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/fourth-link"
                      className="professional-link professional-a"
                    >
                      Login
                    </Link>
                  </li>
                </div>
              </div>

              <div className="col professional-col my-3">
                <div>
                  <h5
                    className=" professionaltit-h5  "
                    style={{ marginBottom: "10px" }}
                  >
                    Service
                  </h5>
                  <li className="professional-li">
                    <Link
                      to="/first-link"
                      className="professional-link professional-a"
                    >
                      Fresh picks ,Daily Delights
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/second-link"
                      className="professional-link professional-a"
                    >
                      Local Transport
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/third-link"
                      className="professional-link professional-a"
                    >
                      Pharmacy
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/fourth-link"
                      className="professional-link professional-a"
                    >
                      Restaurants & Cafe
                    </Link>
                  </li>
                  <li className="professional-li">
                    <Link
                      to="/fourth-link"
                      className="professional-link professional-a"
                    >
                      Hire Professionals
                    </Link>
                  </li>
                </div>
              </div>

              <div className="col my-3 professional-col ">
                <div>
                  <h5
                    className=" professionaltit-h5  "
                    style={{ marginBottom: "15px" }}
                  >
                    Contact Us
                  </h5>
                  <div className="my-2" style={{ display: "flex" }}>
                    <div>
                      <MdCall
                        style={{
                          color: "white",
                          fontSize: "25px",
                          marginRight: "5px",
                          borderRadius: "50%",
                          padding: "9%",
                          border: "1px solid white",
                        }}
                      />
                    </div>
                   <Link to="tel:+91 90250 52716" style={{ color: "white",fontSize:"16px" }}> <p >+91  90250 52716</p></Link>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div>
                      <MdEmail
                        style={{
                          color: "white",
                          fontSize: "25px",
                          marginRight: "5px",
                          borderRadius: "50%",
                          padding: "9%",
                          border: "1px solid white",
                        }}
                      />
                    </div>
                    <Link style={{ color: "white", fontSize: "100%" }}>
                      webble12@gmail.com
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col my-3 professional-col ">
                <div>
                  <h5
                    className=" professionaltit-h5  "
                    style={{ marginBottom: "15px" }}
                  >
                    Address
                  </h5>
                  <div style={{ display: "flex" }}>
                    <div>
                      <MdLocationOn
                        style={{
                          color: "white",
                          fontSize: "25px",
                          marginRight: "5px",
                          borderRadius: "50%",
                          padding: "3%",
                          border: "1px solid white",
                        }}
                      />
                    </div>
                    <p style={{ color: "white" }}>
                      KC Encalve 2nd floor,<br/>Naicker New 1st St Govindapuram,
                      Dindigul.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="sb_footer-copyright my-2">
              <p>&copy; {currentYear}  Whistles. All rights reserved.</p>
            </div>
            <div className="sb_footer-copyright">
              <p>
                Powered by{" "}
                <Link
                  to="https://virtualtechnology.in/ "
                  className="pro-top-center-link"
                >
                  {" "}
                  virtualtechology
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <VendorCategorySelectionModal isOpen={isModalOpen} onClose={closeModal} onSelectCategory={handleCategorySelect}/>

    </div>
  );
}
export default Footer;
