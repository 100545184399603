import React, { useState } from 'react'
import "./Header.css";
import { Link } from 'react-router-dom';
import {ResponseModal, BiMenuAltRight, AiOutlineCloseSquare, LocationModal, FaLocationDot } from "../../imports"
const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResponseModalOpen, setResponseModalOpen] = useState(false);
  const handleButtonClick = () => {
    // Logic to handle button click
    setResponseModalOpen(false);
  };


  const openModal = () => {
    setIsModalOpen(true);
    console.log("clicled open modal")
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  let Links = [
    { name: "Home", link: "/" },
    { name: "About", link: "/" },
    { name: "Product & Service", link: "/" },
    { name: "Blog", link: "/" },
    { name: "Contact", link: "/" },
    { name: "Login", link: "/login" }
  ]
  const [open, setOpen] = useState(false)
  return (
    <div className=' w-full category-header
    fixed top-0 left-0'>
      <div className='md:flex items-center justify-between main-bg py-4 md:px-10 px-7'>
        <div className='font-bold text-white text-2xl cursor-poniter flex items-center'>
          Whistles
        </div>
        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer text-white md:hidden '>
          {open ? <AiOutlineCloseSquare /> : <BiMenuAltRight />}

        </div>
        <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static main-bg text-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9
        transition-all duration-500 ease-in ${open ? "top-20 opacity-100" : "top-[-490px] md:opacity-100 opacity-0"}`}>
          {
            Links.map((link) => (
              <li key={link.name} className='md:ml-8 md:my-0 my-7'>
                <Link to={link.link} className='text-white text-sm hover:text-gray-200 duration-500'>{link.name}</Link>
              </li>

            ))
          }
          <li className='location-icon md:ml-8 md:my-0 my-7' onClick={()=> openModal()}>
            <a href="#" >

              <FaLocationDot style={{ color: "#2653c6" }} />
            </a>
          </li>
        </ul>
        <LocationModal isOpen={isModalOpen} onClose={closeModal} />
        <ResponseModal
        isOpen={isResponseModalOpen}
        onClose={() => setResponseModalOpen(false)}
        message="Your response message goes here."
        buttonText="Close"
        imageUrl="your-dynamic-image-url.jpg"
      />
      </div>
    </div>
  )
}

export default Header