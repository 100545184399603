import React, { useEffect, useState } from 'react';
import "./ResponseModal.css";
import { useVendorAuth } from '../vendors/VendorAuthContext';
import axios from 'axios';
import { BASE_URL } from '../../env';
import Stories from 'react-insta-stories';
import { IoMdCloseCircle, MdOutlineDeleteSweep } from "../../imports";

const VendorStoryDisplayModal = ({ onClose }) => {
  const { vendorUser } = useVendorAuth();
  const [storyPaths, setStoryPaths] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);


  const fetchStoryPaths = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/vegetable/vendor/stories/${vendorUser.id}`);
      // console.log(response.data);
      setStoryPaths(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching image paths:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStoryPaths();
  }, []);


 

  const generateStoryItems = () => {
    return storyPaths.map((story, index) => {
      const storyUrl = `${BASE_URL}/vegetablestories/${story.todaystory}`;
      const isVideo = story.todaystory.endsWith('.mp4');

      return {
        url: storyUrl,
        type: isVideo ? 'video' : 'image',
        id: story.id,
      };
    });
  };


  const handleStoryClick = async () => {
    if (selectedStoryIndex !== null && selectedStoryIndex < storyPaths.length) {
      const storyIdToDelete = storyPaths[selectedStoryIndex].id;
  
      try {
      
        await axios.delete(`${BASE_URL}/vendor/vegetablestories/${storyIdToDelete}`);
        
        onClose();
      } catch (error) {
        console.error('Error deleting story:', error);
      }
    }
  };
  

  return (
    <div className="vendor-veg-img-overlay">
      <div className="vegvendor-story-content">
        <button onClick={onClose} className="story-close-icon">
          <IoMdCloseCircle />
        </button>
        {loading ? (
          <p>Loading...</p>
        ) : storyPaths.length ? (
          <>
          <Stories
            stories={generateStoryItems()}
            width={'100%'}
            height={'400px'}
            style={{ display: 'flex', justifyContent: 'center', background: 'red', cursor: 'pointer' }}
            loop
            keyboardNavigation
            defaultInterval={3000}
          />
          <div className='mt-2'>
          <select defaultValue="" onChange={(e) => setSelectedStoryIndex(parseInt(e.target.value, 10))}>
  <option value="" disabled>Select a story to delete</option>
  {storyPaths.map((story, index) => (
    <option key={index} value={index}>{`Story ${index + 1}`}</option>
  ))}
</select>
            <button onClick={handleStoryClick} className='story-del-btn'>< MdOutlineDeleteSweep /></button>
          </div>
        </>
        ) : (
          <p className='my-auto'>No story available.</p>
        )}
      </div>
    </div>
  );
};

export default VendorStoryDisplayModal;
