
import "./Preloader.css";



function Preloader(){
    return(
        <div className="demo">
            <div class="loader">
    <div class="inner-loader">
        <div class="circle1"></div>
    </div>
    <div class="inner-loader">
        <div class="circle2"></div>
    </div>
    <div class="inner-loader">
        <div class="circle3"></div>
    </div>
    <div class="inner-loader">
        <div class="circle4"></div>
    </div>
    <div class="inner-loader">
        <div class="circle5"></div>
    </div>
</div>
            </div>
    )
}

export default Preloader;