import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ResponseModal.css';

const MessageModal = ({ show, message, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="messageModalContent">
        <p>{message}</p>
        <Button className="bg-primary mt-5" onClick={onClose}>
        Done
        </Button>
      </Modal.Body>
     
    </Modal>
  );
};

export default MessageModal;
