import React from 'react'
import {VegetableVendorHeader} from "../../../../imports";
import { useVendorAuth } from '../../VendorAuthContext';
import "../ProfileCreation.css";

const VegetableVendorStory = () => {
  const { vendorUser } = useVendorAuth();

  const toggleMenu = () => {
    const menuToggle = document.querySelector(".veg-toggle");
    var navigation = document.querySelector(".veg-navigation");
    if (menuToggle) {
      menuToggle.classList.toggle('active');
    }
    if (navigation) {
      navigation.classList.toggle('active')
    }
  };

  return (
<div className='veg-story-page'>
<VegetableVendorHeader toggleMenu={toggleMenu} />
<div className='veg-vendor-story'>

</div>
</div>
  )
}

export default VegetableVendorStory