import React, { useState, useEffect } from 'react';
import { AdminSidebarMain } from '../../../../imports';
import axios from 'axios';
import { BASE_URL } from '../../../../env';
import { useNavigate } from 'react-router-dom';
import '../../AdminDashboard.css';
import '../../demo/AdminSidebar.css';
const TransportVerifiedList = () => {

  const navigate =useNavigate();
  const [transport,setTransport] =useState([]);
  

useEffect(()=>{
   
  const fetchData=async()=>{
    try{
     const response = await axios.get(`${BASE_URL}/transport/verified/list`);
     setTransport(response.data);

    }catch(error){
      console.log(error)
    }
  }

  fetchData();
},[])
const handleView = (id) => {
  const selectedtransport = transport.find((profile) => profile.id === id);
  navigate('/admin/verified/transport/detailing', { state: { profile: selectedtransport} });
};

  return (
    <div className="admin-dashboard-page">
    <AdminSidebarMain />
  

    <h2 className="text-center admin-title-heading">Unauthorized Transport List</h2>


    <div className="dashboardmain">

      {/* table */}
      <div className="responsive-table mt-2 custom-overflow-x">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Location</th>
                {/* <th>Verification</th> */}
                <th>Store Name</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {transport.map((profile) => (
                <tr key={profile.id} className="pt-3">
                  <td>{profile.name}</td>
                  <td>{profile.phoneNumber}</td>
                  <td>{profile.city}</td>
                  <td>{profile.vehicle_type}</td>
                  <td>
                    <button className="btn btn-primary" onClick={() => handleView(profile.id)}>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default TransportVerifiedList