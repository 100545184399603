export const vegdata ={
    "stores": [
      {
        "storeName": "Fresh Harvest Market",
        "review": "High-quality produce .",
        "price": "Rs.502",
        "serviceHeading": "Fresh Fruits and Vegetables",
        "serviceDetails": "We offer a wide variety of fresh fruits and vegetables sourced directly from local farmers.",
        "location": "Dindigul",
        "number":"9786543210",
        "images": [
         "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
            "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
            "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",

          ]
          
      },
      {
        "storeName": "Organic Delights",
        "review": "Certified organic produce.",
        "price": "Rs.502",
        "serviceHeading": "Organic Fruits and Vegetables",
        "serviceDetails": "Our store is dedicated to providing the finest organic fruits and vegetables for a healthy lifestyle.",
        "location": "Palani",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
  
    
      {
        "storeName": "Organic Delights",
        "review": "Certified organic produce.",
        "price": "Rs.502",
        "serviceHeading": "Organic Fruits and Vegetables",
        "serviceDetails": "Our store is dedicated to providing the finest organic fruits and vegetables for a healthy lifestyle.",
        "location": "Coimbatore",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Farm Fresh Produce",
        "review": "Great prices and a wide ",
        "price": "Rs.502",
        "serviceHeading": "Local Farm Produce",
        "serviceDetails": "We source our fruits and vegetables directly from nearby farms to ensure freshness and support local agriculture.",
        "location": "Pollachi",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Fresh Harvest Market",
        "review": "High-quality produce .",
        "price": "Rs.503",
        "serviceHeading": "Fresh Fruits and Vegetables",
        "serviceDetails": "We offer a wide variety of fresh fruits and vegetables sourced directly from local farmers.",
        "location": "Coimbatore",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Organic Delights",
        "review": "Certified organic produce.!",
        "price": "Rs.502",
        "serviceHeading": "Organic Fruits and Vegetables",
        "serviceDetails": "Our store is dedicated to providing the finest organic fruits and vegetables for a healthy lifestyle.",
        "location": "Dindigul",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Farm Fresh Produce",
        "review": "Great prices and a wide .",
        "price": "Rs.502",
        "serviceHeading": "Local Farm Produce",
        "serviceDetails": "We source our fruits and vegetables directly from nearby farms to ensure freshness and support local agriculture.",
        "location": "Palani",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Fresh Harvest Market",
        "review": "High-quality produce and excellent service.",
        "price": "Rs.502",
        "serviceHeading": "Fresh Fruits and Vegetables",
        "serviceDetails": "We offer a wide variety of fresh fruits and vegetables sourced directly from local farmers.",
        "location": "Dindigul",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Organic Delights",
        "review": "Certified organic produce.",
        "price": "Rs.502",
        "serviceHeading": "Organic Fruits and Vegetables",
        "serviceDetails": "Our store is dedicated to providing the finest organic fruits and vegetables for a healthy lifestyle.",
        "location": "Coimbatore",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
      {
        "storeName": "Farm Fresh Produce",
        "review": "Great prices and a wide selection of local farm produce.",
        "price": "Rs.502",
        "serviceHeading": "Local Farm Produce",
        "serviceDetails": "We source our fruits and vegetables directly from nearby farms to ensure freshness and support local agriculture.",
        "location": "Pollachi",
        "number":"9786543210",

        "images": [
            "https://images.unsplash.com/photo-1550258987-190a2d41a8ba?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
               "https://images.unsplash.com/photo-1574856344991-aaa31b6f4ce3?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1557800636-894a64c1696f?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8ZnJ1aXR8ZW58MHx8MHx8fDA%3D",
               "https://images.unsplash.com/photo-1609780447631-05b93e5a88ea?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZnJ1aXQlMjBzaG9wfGVufDB8fDB8fHww",
   
             ]
      },
    ]
  }
  