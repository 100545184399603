
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './VegtableVendorHeader.css';
import { useVendorAuth } from "../../VendorAuthContext";
import { VendorUserAccountModal } from "../../../../imports";

const VegetableVendorHeader = () => {
  const { vendorLogout,vendorUser} = useVendorAuth();
  
  const navigate = useNavigate();

  const getTopNav = () => {
    return [
      { id: 1, label: "Home", to: "/vendor/vegetable/home" },
      { id: 2, label: "Customers", to: "/vendor/vegetable/home" },
      { id: 3, label: "Profile", to: "/vendor/vegetable/profile" },
      { id: 4, label: "Account", onClick: () => setIsAccountModalOpen(true) },
      { id: 5, label: "Logout", to: "" },
    ];
  };

  const [navItems, setNavItems] = useState([]);
  const [collapse, setCollapse] = useState("vegvendor-navbar__menu");
  const [toggleIcon, setToggleIcon] = useState("vegvendor-toggler__icon");
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  useEffect(() => {
    setNavItems(getTopNav());
  }, []);

  const onToggle = () => {
    setCollapse((prevCollapse) =>
      prevCollapse === "vegvendor-navbar__menu"
        ? "vegvendor-navbar__menu vegvendor-navbar__collapse"
        : "vegvendor-navbar__menu"
    );

    setToggleIcon((prevToggleIcon) =>
      prevToggleIcon === "vegvendor-toggler__icon"
        ? "vegvendor-toggler__icon vegvendor-toggle"
        : "vegvendor-toggler__icon"
    );
  };

  const handleNavigation = (to) => {
    setIsAccountModalOpen(false); 
    navigate(to);
  };

  const handleLogout = () => {
    vendorLogout();
    navigate("/vendor/login");
  };

  return (
    <div className="vegvendor-navbar__wrapper cursor-pointer">
      <div className="vegvendor-container">
        <div className="vegvendor-navbar">
          <Link to="/" className="vegvendor-navbar__brand">
          Whistles
          </Link>
          <ul className={collapse}>
            {navItems.map((item) => (
              <li key={item.id} className="vegvendor-navbar__item">
                {item.label === "Logout" ? (
                  <button className="vegvendor-navbar__link" onClick={handleLogout}>
                    {item.label}
                  </button>
                ) : (
                  <span
                    className="vegvendor-navbar__link"
                    onClick={() => (item.onClick ? item.onClick() : handleNavigation(item.to))}
                  >
                    {item.label}
                  </span>
                )}
              </li>
            ))}
          </ul>
          <div className={toggleIcon} onClick={onToggle}>
            <div className="line__1"></div>
            <div className="line__2"></div>
            <div className="line__3"></div>
          </div>
        </div>
      </div>
      {isAccountModalOpen && (
        <VendorUserAccountModal
          vendorInfo={vendorUser}
          onClose={() => setIsAccountModalOpen(false)}
        />
      )}
    </div>
  );
};

export default VegetableVendorHeader;
