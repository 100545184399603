import React, { useState } from "react";
import "./Navbar.css";
import homeimg from "../assets/image/home.png";
import { MdDoubleArrow } from "react-icons/md";

import { FaLocationDot, LocationModal, Header } from "../imports";
const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    console.log("clicled open modal");
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="home-banner">
      <Header />
      <LocationModal isOpen={isModalOpen} onClose={closeModal} />
      <div className="container-fluid w-100 home-page">
        <div className="row">
          <div className="text-container mt-3  col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <h2 className="heading">Live the Whistles !!</h2>
            <p className="navbar-para">
              Welcome to Whistles community, your ultimate connection hub for
              embracing the heartbeat of your local community. Say Goodbye to
              indirect online transactions and Hello to a digitalized, local
              experience like never before.<br></br>
              Discover the charm of your local community through Whistles. We
              connect you with retail shops, skilled professional workers, and
              reliable transport options around you. Our mission is to
              strengthen the bonds within your community by building healthy
              businesses.{" "}
            </p>

            <div className="my-3 banner-last-cnt">
              <div className="mx-3">Community-centric</div>
              <MdDoubleArrow className="arrow-icon-style" />
              <div className="mx-3">Reliable</div>
              <MdDoubleArrow className="arrow-icon-style" />
              <div className="mx-3">Convenience</div>
            </div>
            <div></div>
          </div>

          <div className="image-container  col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="imgbox mt-5">
              <img src={homeimg} alt="home img" className="img-fluid" />
            </div>
            <span className="animblob"></span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
