import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { useNavigate } from "react-router-dom";
import {
  IoMdArrowRoundBack,
  VegetableMainCategoryListing,
} from "../../../imports";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCategoryVegetables = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [existingCategories, setExistingCategories] = useState([]);
  const [showNestedModal, setShowNestedModal] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState("");
  const [subcategoryImage, setSubcategoryImage] = useState("");
  const [previewSubcategoryImage, setPreviewSubcategoryImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setSelectedCategoryId(selectedOption ? selectedOption.id : null);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCategoryId(null);
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/vegetable/categories`);
      console.log(response.data, "categories fetched");
      setCategories(response.data);
      setExistingCategories(
        response.data.map((category) => ({
          id: category.id,
          label: category.vegcategory,
        }))
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleAddCategory = () => {
    if (!categoryName || !categoryImage) {
      toast.error("Please select both category name and image.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const formData = new FormData();
    formData.append("vegcategory", categoryName);
    formData.append("categoryimg", categoryImage);

    axios
      .post(`${BASE_URL}/vegetable/add/category`, formData)
      .then((response) => {
        toast.success("Category added successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchCategories();
        setShowNestedModal(false);
        setCategoryImage(null);
        setPreviewImage(null);
        setNewCategory("");
      })
      .catch((error) => {
        toast.error("Error in adding category", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleShowNestedModal = () => setShowNestedModal(true);
  const handleCloseNestedModal = () => setShowNestedModal(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCategoryImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };
  const handleSubcategoryImageChange = (e) => {
    const file = e.target.files[0];
    setSubcategoryImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSubcategoryImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewSubcategoryImage(null);
    }
  };
  const handleAddSubcategory = () => {
    if (!subcategoryName || !subcategoryImage || !selectedCategoryId) {
      toast.error("Please fill in all fields.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const formData = new FormData();
    formData.append("subcategoryName", subcategoryName);
    formData.append("subcategoryImage", subcategoryImage);
    formData.append("categoryId", selectedCategoryId);
    axios
      .post(`${BASE_URL}/vegetable/add/subcategory`, formData)
      .then((response) => {
        console.log("Subcategory added successfully");
        toast.success("Subcategory added successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchCategories();
        setShowModal(false);
        setShowNestedModal(false);
        setSubcategoryImage(null);
        setPreviewSubcategoryImage(null);
        setSubcategoryName("");
      })
      .catch((error) => {
        toast.error("Error in adding Subcategory", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div>
      <p
        className="d-flex align-items-center  cursor-pointer "
        onClick={() => navigate("/admin/master/category")}
      >
        {" "}
        <IoMdArrowRoundBack className="" />
        <ins>Back</ins>
      </p>

      <h2 className="text-center admin-title-heading mt-2">
        Add Categories to Vegetables
      </h2>
      <Button onClick={handleShowModal} className="m-4 bg-primary">
        + Add
      </Button>
      <VegetableMainCategoryListing
        categories={categories}
        fetchCategories={fetchCategories}
      />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Sub Category</p>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex jusify-content-center align-items-center">
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={existingCategories}
              isClearable
              getOptionLabel={(option) => option.label}
              placeholder="---Select Category---"
              className="w-100"
            />
            <Button className="m-4 bg-primary" onClick={handleShowNestedModal}>
              +
            </Button>
          </div>

          <Form.Group>
            <p className="add-martcategory-label">Subcategory Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Subcategory Name"
              value={subcategoryName}
              onChange={(e) => setSubcategoryName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <p className="add-martcategory-label">Subcategory Image</p>
            <Form.Control type="file" onChange={handleSubcategoryImageChange} />
            {previewSubcategoryImage && (
              <img
                src={previewSubcategoryImage}
                alt="Preview"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-danger" onClick={handleCloseModal}>
            Close
          </Button>
          <Button className="bg-primary" onClick={handleAddSubcategory}>
            Add Subcategory
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showNestedModal} onHide={handleCloseNestedModal}>
        <Modal.Header closeButton>
          <p className="add-martcategory-title">Add Main Category</p>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <p className="add-martcategory-label">Category Name</p>
            <Form.Control
              type="text"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <p className="add-martcategory-label">Category Image</p>
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
          {previewImage && (
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: "100%", marginTop: "10px" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-danger" onClick={handleCloseNestedModal}>
            Close
          </Button>

          <Button className="bg-primary" onClick={handleAddCategory}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AddCategoryVegetables;
