import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { BASE_URL } from '../../../env';
import {TransportProductCard} from "../../../imports"
const AddTransportService = ({vendorUser}) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [serviceDetails, setServiceDetails] = useState('');
    const [price, setPrice] = useState('');
    const[service,setService]=useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('add');
    const [editProductId, setEditProductId] = useState(null);
    const [categorizedServices, setCategorizedServices] = useState({}); 

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/transport/categories`);
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const fetchProducts = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/transport/vendorServices/${vendorUser.id}`);
          const categorizedServices = {};
      
          response.data.forEach((service) => {
            if (!categorizedServices[service.category]) {
              categorizedServices[service.category] = [];
            }
            categorizedServices[service.category].push(service);
          });
      
          setService(categorizedServices);
          setCategorizedServices(categorizedServices); 
        } catch (error) {
          console.error('Error fetching services:', error);
        }
      };
      
      useEffect(() => {
        fetchCategories();
        fetchProducts();
      }, []);
      
    
    useEffect(() => {
        fetchCategories();
        fetchProducts();
    }, []);

    const handleShowModal = () => {
        setShowModal(true);
        fetchCategories();
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalMode('add');
        setEditProductId(null);
        // Reset input fields
        setSelectedCategory('');
        setServiceDetails('');
        setPrice('');
      };
    
      const handleAddProduct = async () => {
        if (!selectedCategory || !serviceDetails || !price) {
            alert('All fields are required.');
            return;
        }
    
        try {
            if (modalMode === 'add') {
                await axios.post(`${BASE_URL}/transport/addService`, {
                    user_id: vendorUser.id,
                    category: selectedCategory,
                    vehicle_service: serviceDetails,
                    rate: price
                });
    
                handleCloseModal();
            } else if (modalMode === 'edit' && editProductId) {
                await axios.put(`${BASE_URL}/transport/vendorServices/${vendorUser.id}/${editProductId}`, {
                    user_id: vendorUser.id,
                    category: selectedCategory,
                    vehicle_service: serviceDetails,
                    rate: price
                });
            }
    
            fetchProducts();
    
            handleCloseModal();
        } catch (error) {
            console.error('Error adding/editing service:', error);
            alert('Failed to add/edit service. Please try again.');
        }
    };
    
      const handleEditProduct = (service) => {
        setModalMode('edit');
        setEditProductId(service.id);
        // Set initial values based on the selected product
        setSelectedCategory(service.category);
        setServiceDetails(service.vehicle_service);
        setPrice(service.rate);
        handleShowModal();
      };

      const handleDeleteProduct = async (productId) => {
        try {
          await axios.delete(`${BASE_URL}/transport/vendorServices/${vendorUser.id}/${productId}`);
          fetchProducts();
        } catch (error) {
          console.error('Error deleting product:', error);
          alert('Failed to delete product. Please try again.');
        }
      };
    
    return (
        <div>
            <button onClick={handleShowModal} className='veg-add-product-btn'>
                Add Service
            </button>
            {Object.entries(categorizedServices).map(([category, categoryServices]) => (
        <div key={category} className='vendorProduct-scroll'>
          <div className='vendorProduct-containerbox'>
            <h2 className="vendorProduct-title mx-auto">{category}</h2>
            <div className="vendorProduct-scroller mt-3">
              {categoryServices.map((service) => (
                <Col key={service.id} md={4}>
                  <TransportProductCard
                    service={service}
                    onEdit={handleEditProduct}
                    onDelete={handleDeleteProduct}
                  />
                </Col>
              ))}
            </div>
          </div>
        </div>
      ))}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="categoryDropdown">
                            <Form.Label>Select Vehicle</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="">Select a Vehicle</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.vehicleCategory}>
                                        {category.vehicleCategory}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="serviceDetails">
                            <Form.Label>Service Details</Form.Label>
                            <Form.Control
                                type="text"
                                value={serviceDetails}
                                onChange={(e) => setServiceDetails(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="price">
                            <Form.Label>Price</Form.Label>
                            <Form.Control
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='bg-danger' onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button className='bg-primary' onClick={handleAddProduct}>
            {modalMode === 'add' ? 'Add Product' : 'Edit Product'}
          </Button>
                </Modal.Footer>
               
            </Modal>
        </div>
    );
};

export default AddTransportService;
