import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  FaEdit,
  FaCarSide,
  FaCalendarCheck,
  MdOutlineDeleteSweep,
  IoMdTimer,
  MdOutlineLocationCity,
  MdEmail,
  MdReviews,
  MdDescription,
  TbPasswordUser,
  IoMdArrowRoundBack,
  FaUserCircle,
  MdOutlineSmartphone,
  FaLocationDot,
  BiSolidCategoryAlt,
  MdAssistantDirection,
  AdminTransportStoryModal,
} from "../../../../imports";
import axios from "axios";
import { BASE_URL } from "../../../../env";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../AdminDashboard.css";

const TransportVerifiedDetailingPage = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { profile } = location.state || {};
  const [categories, setCategories] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [deletingImage, setDeletingImage] = useState("");
  const [imgDelModal, setimgDelModal] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [storyViewModal, setstoryViewModal] = useState(false);

  const [editedValues, setEditedValues] = useState({
    user_id: profile.user_id,
    name: profile.name,
    phoneNumber: profile.phoneNumber,
    password: profile.password,
    email: profile.email,
    address: profile.address,
    description: profile.description,
    city: profile.city,
    vehicleType: profile.vehicle_type,
    vehicleModel: profile.vehicle_model,
    vehicleNo: profile.vehicle_registration_number,
    experience: profile.years_of_driving_experience,
    days_and_hours_available: profile.days_and_hours_available,
    category: profile.category,
    direction: profile.location,
    reviews: profile.reviews,
    verification: profile.verification,
  });

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/transport/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchImagePaths = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/transport/serviceimage/${profile.user_id}`
      );
      // console.log(response, "response for image path")
      setImagePaths(response.data.imagePaths || []);
    } catch (error) {
      console.error("Error fetching image paths:", error);
    }
  };

  useEffect(() => {
    fetchImagePaths();
    fetchCategories();
  }, []);

  // delete image

  const handleDeleteImage = async (imageName) => {
    setDeletingImage(imageName);
    handleDeleteModal();
  };
  const handleDeleteModal = () => {
    setimgDelModal(true);
  };

  const handleCancelDelete = () => {
    setDeletingImage("");
    setimgDelModal(false);
  };
  // deleting image
  const handleConfirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/transportservice/image/${profile.user_id}/${deletingImage}`
      );
      // console.log('Response after deleting image:', response.data);
      if (response.status === 200) {
        handleCancelDelete();
        fetchImagePaths();
      }
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // edit modal
  const handleEditModalShow = () => setEditModalShow(true);

  const handleClose = () => {
    setEditModalShow(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/transport/edit`,
        editedValues
      );

      // console.log('Vegetable data updated:', response.data.message);
      handleClose();
    } catch (error) {
      console.error("Error updating vegetable data:", error);
    }
  };

  // story modal

  const openStoryViewPopup = () => {
    setstoryViewModal(!storyViewModal);
  };
  return (
    <div>
      <div className="container">
        <div className="">
          <div
            className="d-flex justify-content-between mt-3"
            style={{ cursor: "pointer" }}
          >
            <IoMdArrowRoundBack
              className="back-option"
              onClick={() => navigation("/admin/verified/transport/list")}
            />
            <div className="head-options">
              <button
                onClick={openStoryViewPopup}
                className="admin-choose-poster-btn"
              >
                View Stories
              </button>

              <FaEdit className="edit-options" onClick={handleEditModalShow} />
            </div>
          </div>

          {/* designing basic info */}
          <div className="container">
            <div className=" ">
              {profile ? (
                <div className="unverified-detailing row ">
                  <div className="col-lg-6 col-sm-12">
                    <div className="unverified-row">
                      <p className="detail-heading ">
                        <FaUserCircle className="mx-2" /> Name&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">{editedValues.name}</span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdEmail className="mx-2" />
                        Email Id&nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">{editedValues.email}</span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        {" "}
                        <FaCarSide className="mx-2" />
                        Vehicle Type&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.vehicleType}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <FaCarSide className="mx-2" />
                        Vehicle No &nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.vehicleNo}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <FaLocationDot className="mx-2" />
                        Location &nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.address}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdReviews className="mx-2" />
                        Reviews&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.reviews}
                      </span>
                    </div>
                    {/* <div className="unverified-row">
                <p className="detail-heading">
                  <IoIosPricetag className="mx-2" />
                  Price &nbsp;:&nbsp;
                </p>
                <span className="detail-point">{editedValues.price}</span>
              </div> */}

                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdOutlineLocationCity className="mx-2" />
                        City &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">{editedValues.city}</span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdOutlineSmartphone className="mx-2" /> Phone
                        Number&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.phoneNumber}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <TbPasswordUser className="mx-2" />
                        Password &nbsp;:&nbsp;
                      </p>
                      <span className="detail-point">
                        {editedValues.password}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <FaCarSide className="mx-2" />
                        VehicleModel&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.vehicleModel}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <FaCalendarCheck className="mx-2" />
                        Years of Experience&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.experience}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <IoMdTimer className="mx-2" />
                        Day and Hours Available&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.days_and_hours_available}
                      </span>
                    </div>

                    <div className="unverified-row">
                      <p className="detail-heading">
                        <BiSolidCategoryAlt className="mx-2" />
                        Category&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.category}
                      </span>
                    </div>

                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdDescription className="mx-2" />
                        Desription&nbsp;:&nbsp;{" "}
                      </p>
                      <span className="detail-point">
                        {editedValues.description}
                      </span>
                    </div>
                    <div className="unverified-row">
                      <p className="detail-heading">
                        <MdAssistantDirection className="mx-2" />
                        Direction&nbsp;:&nbsp;{" "}
                      </p>
                      <a
                        href={`https://www.google.com/maps?q=${editedValues.direction}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="detail-point text-primary"
                      >
                        {" "}
                        open in google map
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Transport details available.</p>
              )}
            </div>
            <div className="detail-point-img">
              {imagePaths.map((imagePath, index) => (
                <div key={index} className="image-container-admin">
                  <div className="d-flex justify-content-between">
                    <div
                      className="delete-button"
                      onClick={() => handleDeleteImage(imagePath)}
                    >
                      <MdOutlineDeleteSweep />
                    </div>
                  </div>

                  <img
                    src={`${BASE_URL}/vegetablestoreimages/${imagePath}`}
                    alt={`Image ${index}`}
                    className="image-preview img-fluid"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal show={editModalShow} onHide={handleClose} centered>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                name="name"
                value={editedValues.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter phone number"
                name="phoneNumber"
                value={editedValues.phoneNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formpassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Password"
                name="password"
                value={editedValues.password}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formemail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Email Id"
                name="email"
                value={editedValues.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Address"
                name="address"
                value={editedValues.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                name="description"
                value={editedValues.description}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter City"
                name="city"
                value={editedValues.city}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formVehicleType">
              <Form.Label>Vehicle Type:</Form.Label>
              {categories.length > 0 ? (
                <Form.Control
                  as="select"
                  value={editedValues.vehicleType}
                  onChange={(e) =>
                    setEditedValues({
                      ...editedValues,
                      vehicleType: e.target.value,
                    })
                  }
                >
                  <option value="" disabled>
                    Select a Vehicle Type
                  </option>
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.vehicleCategory}>
                      {cat.vehicleCategory}
                    </option>
                  ))}
                </Form.Control>
              ) : (
                <span className="detail-point">Loading categories...</span>
              )}
            </Form.Group>
            <Form.Group controlId="formVehicleModel">
              <Form.Label>Vehicle Model</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vehicle Model"
                name="vehicleModel"
                value={editedValues.vehicleModel}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formVehicleNo">
              <Form.Label>Vehicle Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Vehicle Number"
                name="vehicleNo"
                value={editedValues.vehicleNo}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formExperience">
              <Form.Label>Years of Driving Experience</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Experience"
                name="experience"
                value={editedValues.experience}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formDaysAndHours">
              <Form.Label>Days and Hours Available</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Availability"
                name="days_and_hours_available"
                value={editedValues.days_and_hours_available}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formReviews">
              <Form.Label>Reviews</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Reviews"
                name="reviews"
                value={editedValues.reviews}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClose} className="cancel-btn-edit">
            Cancel
          </button>
          <button onClick={handleEdit} className="save-btn-edit">
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={imgDelModal}
        onHide={handleCancelDelete}
        centered
        className="admin-del-modal"
      >
        <div className="admin-del-point">
          <p>Are you sure you want to delete this image? </p>
        </div>
        <div className="d-flex justify-content-between mt-5">
          <p onClick={handleCancelDelete} className="cancel-btn-edit">
            Cancel
          </p>
          <p
            variant="danger"
            onClick={handleConfirmDelete}
            className="save-btn-edit"
          >
            Delete
          </p>
        </div>
      </Modal>
      {storyViewModal && (
        <AdminTransportStoryModal
          onClose={openStoryViewPopup}
          userId={editedValues.user_id}
        />
      )}
      {/* user del modal */}
      {/* <Modal show={delUserModalShow} onHide={handleUserModalCancel} centered>
       
      </Modal> */}
    </div>
  );
};

export default TransportVerifiedDetailingPage;
