import './App.css';
import { Route,Routes } from 'react-router-dom';
import { Navbar,Categories,Vegetables,Header,Login,OnboardForm,InstaStories, Transport, Pharmacy,Hotels,
  Professionals,VendorRoutes, SuccessModal,FailureModal,AdminRoutes, Preloader } from './imports';
  import Footer from './components/Footer/Footer';
  import React ,{useState,useEffect} from 'react';
// import Preloader from './Preloader';


function App() {
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 2000);
  }, []);
  return (
    <div>
    {screenLoading ? (
      <Preloader />
    ) : <>
    <div className="App">
     
     {/* <Navbar/>
     <Categories/> */}
    
      <Routes>
          <Route path="/" element={
            <>
              <Navbar />
              <Categories />
              <Footer/>
            </>}>
          </Route>
          <Route path="/vegetable" element={
            <>
            <Header/>
            <Vegetables/></>
         }>
          </Route>
          <Route path="/transport" element={
            <>
            <Header/>
            <Transport/></>
         }>
          </Route>
          <Route path="/pharmacy" element={
            <>
            <Header/>
            <Pharmacy/></>
         }>
          </Route>
          <Route path="/hotels" element={
            <>
            <Header/>
            <Hotels/></>
         }>
          </Route>
          <Route path="/professionals" element={
            <>
            <Header/>
            <Professionals/></>
         }>
          </Route>
          <Route path="/login" element={ 
            <Login/>
         }>
          </Route>
          <Route path="/userregitration" element={ 
            <OnboardForm/>
         }>
          </Route>
          <Route path="/instastory" element={ 
            <InstaStories/>
         }>
          </Route>
          <Route
            path="/vendor/*"
            element={<VendorRoutes />}
          ></Route>
          {/* admin routes */}
          <Route
            path="/admin/*"
            element={<AdminRoutes />}
          ></Route>
            <Route
            path="/success"
            element={<SuccessModal />}
          ></Route>
             <Route
            path="/failure"
            element={<FailureModal />}
          ></Route>
      </Routes>
     
    </div> 
    </>}
   </div>
  );
}

export default App;
