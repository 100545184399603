import React, { useState, useEffect } from "react";
import transport from "../../../assets/image/home.png";
// import auto from "../../../assets/image/transport/car.png";
import "./TransportForm.css";
import { useFormik } from "formik";
import {
  SuccessModal,
  FailureModal,
  auto,
  bus,
  car,
  transportProfileFormValidation,
} from "../../../imports";
import auto1 from "../../../assets/image/transport/auto.png";
import axios from "axios";
import { BASE_URL } from "../../../env";
import { useVendorAuth } from "../VendorAuthContext";

function TransportVendorProfile() {
  const { vendorUser } = useVendorAuth();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/transport/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const formik = useFormik({
    initialValues: {
      vehicleType: "",
      vehicleModel: "",
      vehicleRegistrationNumber: "",
      yearsOfDrivingExperience: "",
      daysAndHoursAvailable: "",
    },
    validationSchema: transportProfileFormValidation,
    onSubmit: async (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const formData = {
        ...values,
        user_id: vendorUser.id,
        category: vendorUser.category,
      };
      const response = await axios.post(
        `${BASE_URL}/transport/profile/create`,
        formData
      );

      if (response.status === 200) {
        console.log("Profile created successfully");
        setSuccessMessage(response.data.message);
        setSuccessModalOpen(true);
      } else {
        console.error("Error creating profile:", response.statusText);
        setErrorMessage("Error in Creating Profile. Please try again.");
        setFailureModalOpen(true);
      }
    } catch (error) {
      console.error("Error creating profile:", error.message);
    }
  };
  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);
  };

  return (
    <div>
      <div className="row g-0 transport-contain ">
        <div className="col-12 col-lg-6 trans-bg order-1 ">
          <div className="row trans-bg-svg-crds ">
            <div className="col col-lg-12 ">
              <div className="trans-card-profile  bounce-top d-block my-5">
                <img
                  className="trans-card-img mx-auto transport-prf-crd"
                  src={auto1}
                  alt="Transport"
                />
                <div
                  className="col-10 mx-auto "
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <p className="profile-frm-p">
                    Start using
                    <span className="profile-frm-p-span">
                      {" "}
                      &nbsp;Whistles
                    </span>{" "}
                    for your transport business and attract numerous customers
                    Efficiently
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-lg-6 transport-form order-2 py-5 "
          style={{ overflowY: "auto" }}
        >
          {successModalOpen && (
            <SuccessModal
              onClose={closeSuccessModal}
              message={successMessage}
              redirectTo="/vendor/transport/image/uploading"
            />
          )}
          {failureModalOpen && (
            <FailureModal onClose={closeFailureModal} message={errorMessage} />
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="transport-form-fields">
              {/* <p
                  className="col-lg-8 col-6 text-center "
                  style={{ color: "black", fontSize: "18px" }}
                >
                  Register your service with Webble Way and discover a whole new
                  level of success!{" "}
                </p> */}
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <p className=" col-lg-11 col-sm-8 col-md-11  text-center trans-bg-svg-crds-p trans-bg-svg-crds-para " >
                  Elevate your transport business with
                  <span className="trans-bg-svg-crds-p-span">
                    &nbsp;Whistles
                  </span>
                  <br></br>and soar to new heights of success!{" "}
                </p>
              </div>

              <select
                className="transport-input my-3"
                name="vehicleType"
                value={formik.values.vehicleType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onClick={()=> fetchCategories()}
              >
                <option value="" label="Select Vehicle Type" />
                {categories.map((category) => (
                  <option key={category.id} value={category.vehicleCategory}>
                    {category.vehicleCategory}
                  </option>
                ))}
              </select>
              <div className="error-div">
                {formik.touched.vehicleType && formik.errors.vehicleType && (
                  <div className="frm-error">{formik.errors.vehicleType}</div>
                )}
              </div>
              <br />

              <input
                className="transport-input my-3"
                type="text"
                placeholder="Vehicle Model"
                name="vehicleModel"
                value={formik.values.vehicleModel}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.vehicleModel && formik.errors.vehicleModel && (
                  <div className="frm-error">{formik.errors.vehicleModel}</div>
                )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="text"
                placeholder="Vehicle Registration Number"
                name="vehicleRegistrationNumber"
                value={formik.values.vehicleRegistrationNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.vehicleRegistrationNumber &&
                  formik.errors.vehicleRegistrationNumber && (
                    <div className="frm-error">
                      {formik.errors.vehicleRegistrationNumber}
                    </div>
                  )}
              </div>
              <br />
              <input
                className="transport-input my-3"
                type="number"
                placeholder="Years of Driving Experience"
                name="yearsOfDrivingExperience"
                value={formik.values.yearsOfDrivingExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="error-div">
                {formik.touched.yearsOfDrivingExperience &&
                  formik.errors.yearsOfDrivingExperience && (
                    <div className="frm-error">
                      {formik.errors.yearsOfDrivingExperience}
                    </div>
                  )}
              </div>
              <br />

              <select
                className="transport-input my-3"
                name="daysAndHoursAvailable"
                value={formik.values.daysAndHoursAvailable}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="" label="Select Availability" />
                <option value="24*7">24*7</option>
                <option value="Day Shift">Day Shift</option>
                <option value="Night Shift">Night Shift</option>
              </select>
              <div className="error-div">
                {formik.touched.daysAndHoursAvailable &&
                  formik.errors.daysAndHoursAvailable && (
                    <div className="frm-error">
                      {formik.errors.daysAndHoursAvailable}
                    </div>
                  )}
              </div>

              <br />

              <button type="submit" className="my-3 text-white p-2 w-50 rounded"  style={{backgroundColor:"#2653c6"}}>
                Register
              </button>
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TransportVendorProfile;
