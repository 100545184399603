import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import './ProfessionalHeader.css';
import { useVendorAuth } from "../../VendorAuthContext";
import { VendorProfessionalAccountModal } from "../../../../imports";

const ProfessionalHeader = () => {
  const { vendorLogout, vendorUser } = useVendorAuth();
  const navigate = useNavigate();
  const getTopNav = () => {
    return [
      { id: 1, label: "Home", to: "/vendor/professional/homepage" },
    { id: 2, label: "Customers", to: "/vendor/professional/homepage" },
    { id: 3, label: "Profile", to: "/vendor/professional/profile" },
    { id: 4, label: "Account", onClick: () => setIsAccountModalOpen(true) },
    { id: 5, label: "Logout", to: "" }
    ];
  };
 

  const [navItems, setNavItems] = useState([]);
  const [collapse, setCollapse] = useState("professional-navbar__menu");
  const [toggleIcon, setToggleIcon] = useState("professional-toggler__icon");
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false); 

  
  useEffect(() => {
    setNavItems(getTopNav());
  }, []);

  const onToggle = () => {
    collapse === "professional-navbar__menu"
      ? setCollapse("professional-navbar__menu professional-navbar__collapse")
      : setCollapse("professional-navbar__menu");

    toggleIcon === "professional-toggler__icon"
      ? setToggleIcon("professional-toggler__icon professional-toggle")
      : setToggleIcon("professional-toggler__icon");
  };

  
  const handleNavigation = (to) => {
    setIsAccountModalOpen(false); 
    navigate(to);
  };

  const handleLogout = () => {
    vendorLogout();
    navigate("/vendor/login");
  };

  return (
    <div className="professional-navbar__wrapper">
      <div className="professional-container">
        <div className="professional-navbar">
          <Link to="/" className="professional-navbar__brand">
          Whistles
          </Link>
          <ul className={collapse}>
            {navItems.map((item) => (
              <li key={item.id} className="professional-navbar__item">
                {item.label === "Logout" ? (
                  <button className="professional-navbar__link" onClick={handleLogout}>
                    {item.label}
                  </button>
                ) : (
                  <Link to={item.to} className="professional-navbar__link"  
                  onClick={() => (item.onClick ? item.onClick() : handleNavigation(item.to))}
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <div className={toggleIcon} onClick={onToggle}>
            <div className="line__1"></div>
            <div className="line__2"></div>
            <div className="line__3"></div>
          </div>
        </div>
      </div>
      {isAccountModalOpen && (
        <VendorProfessionalAccountModal
          vendorInfo={vendorUser}
          onClose={() => setIsAccountModalOpen(false)}
        />
      )}
    </div>
  );
}

export default ProfessionalHeader;
