import React, { useState } from "react";
import "./VendorLogin.css";
import { useFormik } from "formik";
import { useVendorAuth } from "../VendorAuthContext";
import {
  vendorLoginSchema,
  SuccessModal,
  FailureModal,
  FaEye,
  FaEyeSlash,
} from "../../../imports";
import { BASE_URL } from "../../../env";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VendorLogin = () => {
  const { vendorLogin } = useVendorAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      category: "",
      phoneNumber: "",
      password: "",
    },
    validationSchema: vendorLoginSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [failureModalOpen, setFailureModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [responseCategory, setresponseCategory] = useState("");
  const closeSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const closeFailureModal = () => {
    setFailureModalOpen(false);
  };
  const handleSubmit = (formData) => {


    axios
      .post(`${BASE_URL}/vendor/login`, formData)
      .then((response) => {
        // console.log("Success:", response.data.info);
        const { info, message, token } = response.data;
        // console.log(token,"vendor login token");
        setresponseCategory(response.data.info.category);
        localStorage.setItem("vendorToken", token);
        localStorage.setItem("vendorInfo", JSON.stringify(info));
        vendorLogin(info);
        setSuccessMessage(message);
        setSuccessModalOpen(true);

        formik.resetForm();
      })
      .catch((error) => {
        console.error("Error:", error.response.data.message);
        setErrorMessage(error.response.data.message);
        setFailureModalOpen(true);
      });
  };

  const getRedirectRoute = (message) => {
    if (message === "Login Successful") {
      if (responseCategory === "vegetables") {
        return "/vendor/vegetable/home";
      } else if (responseCategory === "professionals") {
        return "/vendor/professional/homepage";
      } else if (responseCategory === "transport") {
        return "/vendor/transport/home";
      } else {
        return "/";
      }
    } else if (message === "Welcome to Whistles") {
      if (responseCategory === "vegetables") {
        return "/vendor/store/detail";
      } else if (responseCategory === "professionals") {
        return "/vendor/professional/detail";
      } else if (responseCategory === "transport") {
        return "/vendor/transport/profile/detail";
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  };

  return (
    <div>
      <div className="vendor-login">
        {successModalOpen && (
          <SuccessModal
            onClose={closeSuccessModal}
            message={successMessage}
            redirectTo={getRedirectRoute(successMessage)}
          />
        )}
        {failureModalOpen && (
          <FailureModal onClose={closeFailureModal} message={errorMessage} />
        )}

        <div className="container d-flex justify-content-center">
          <div className="d-flex flex-column justify-content-between">
            <div className="card mt-3 p-5">
              {/* <div className="logo mb-3"><img src="https://imgur.com/zydrQCr.png" /></div> */}
              <div>
                <p className="mb-1">Start managing your</p>
                <h4 className="mb-5 text-white">Business with us!</h4>
              </div>
              <button
                className="btn btn-primary btn-lg"
                onClick={() => navigate("/")}
              >
                <span>&nbsp;Create Account</span>
              </button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div
                className="card two bg-white px-5 py-4 mb-3"
                style={{ borderTopRightRadius: "60px" }}
              >
                <div>
                  <label className="form_group_login">Category</label>
                  <select
                    id="category"
                    name="category"
                    className="form_field_login"
                    value={formik.values.category}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  >
                    <option value="" disabled>
                      Select a category
                    </option>
                    <option value="vegetables">Vegetables</option>
                    <option value="pharmacy">Pharmacy</option>
                    <option value="transport">Transport</option>
                    <option value="hotels">Hotels</option>
                    <option value="professionals">Professionals</option>
                  </select>
                  {formik.touched.category && formik.errors.category && (
                    <div className="error">{formik.errors.category}</div>
                  )}
                </div>
                <div>
                  <label className="form_group_login" htmlFor="phoneNumber">
                    Phone Number
                  </label>

                  <input
                    type="text"
                    className="form_field_login"
                    id="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                  />

                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <div className="error">{formik.errors.phoneNumber}</div>
                  )}
                </div>

                <div className="form_group_login">
                  <label htmlFor="password">Password</label>
                  {/* <input
                  type="password"
                  className="form_field_login"
                  id="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
               
                {formik.touched.password && formik.errors.password && (
                  <div className="error">{formik.errors.password}</div>
                )} */}
                  <div className="password-input-container d-flex">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form_field_login"
                      id="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    <button
                      type="button"
                      className="toggle-password-button"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className="error">{formik.errors.password}</div>
                  )}
                </div>

                <button
                  className=" text-white p-3 rounded"
                  style={{ backgroundColor: "#2653c6" }}
                  type="submit"
                >
                  <span>
                    Get started
                    <i className="fas fa-long-arrow-alt-right ml-2" />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorLogin;
