import React, { useState } from "react";
import { BASE_URL } from "../../../env";
import  EditMainCategoryModal from "./EditMainCategoryModal";
import axios from "axios";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import ReactSwitch from "react-switch";
import { useNavigate } from "react-router-dom";

export default function VegetableMainCategoryListing({ categories,fetchCategories  }) {
  const [categoryToggles, setCategoryToggles] = useState({});
  const [editcategory, setEditcategory] = useState(null);

  const handleEdit = (selectedcategory) => {
    setEditcategory(selectedcategory);
  };
  const handleCancelEdit = () => {
    setEditcategory(null);
  };



  const navigate = useNavigate();
  const handleChange = (categoryId) => async (val) => {
    setCategoryToggles((prevToggles) => ({
      ...prevToggles,
      [categoryId]: val,
    }));

    try {
      await axios.put(`${BASE_URL}/admin/vegetable-categories/${categoryId}`, {
        active: val,
      });
      fetchCategories();
      console.log(`Category with ID ${categoryId} updated successfully.`);
    } catch (error) {
      console.error(`Error updating category with ID ${categoryId}:`, error);
    }
  };


  const handleCardClick = (categoryId) => {
    navigate("/admin/master/vegetable/subcategory", { state: { categoryId } });
  };
  const handleDeleteCategory = async (categoryId) => {
    try {
      await axios.delete(`${BASE_URL}/vegetable/delete/category/${categoryId}`);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  return (
    <div className="mart-categorycard">
      {categories.map((category) => (
        <div key={category.id} className="mart-card">
          <div className="d-flex m-2 ">
            <MdOutlineEdit
              className="vendorProduct-edit "
              onClick={() => handleEdit(category)}
            />
            <MdOutlineDelete
              className="vendorProduct-delete mx-2"
              onClick={() =>handleDeleteCategory(category.id)}
            />
            <div className="mx-2">
              <ReactSwitch
                checked={categoryToggles[category.id] || category.active === 1}
                onChange={handleChange(category.id)}
                height={20}
                width={40}
              />
            </div>
          </div>

          <img
            src={`${BASE_URL}/vegetable/category/images/${category.image_name}`}
            alt={category.vegcategory}
            className="mart-category-img img-fluid mt-2"
            onClick={() => handleCardClick(category.id)}
          />
          <div className="mt-2">
            <h3 className="font-bold text-capitalize text-center">
              {category.vegcategory}
            </h3>
          </div>
        </div>
      ))}

{editcategory && (
        < EditMainCategoryModal
          selectedcategory={editcategory}
          onCancel={handleCancelEdit}
          fetchCategories={fetchCategories}
        />
      )}
    </div>
  );
}
