import React from "react";
import { Card, Button } from "react-bootstrap";
import "../ProfileCreation.css";
import { MdOutlineDelete } from "react-icons/md";
import { MdOutlineEdit } from "react-icons/md";
import { FcCurrencyExchange } from "react-icons/fc";
import { FcBookmark } from "react-icons/fc";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";

const VegetableProductCard = ({ product, onEdit, onDelete }) => {
  const hasDiscount = product.discount > 0;
  const discountedAmount = hasDiscount
    ? (product.rate * product.discount) / 100
    : 0;
  const discountedPrice = hasDiscount ? product.rate - discountedAmount : null;

  return (
    <div>
      <div className="vendorProduct-box">
        <div className="vendorProduct-icon">
          <MdOutlineEdit
            className="vendorProduct-edit "
            onClick={() => onEdit(product)}
          />
          <MdOutlineDelete
            className="vendorProduct-delete"
            onClick={() => onDelete(product.id)}
          />
        </div>

        <h6 className="vendorProduct-heading d-flex gap-1">
          <span className="service-heading-vendor d-flex gap-1">
            <MdOutlineProductionQuantityLimits
              className="vendorProduct-iconbar"
              color="#FDBF60"
            />
            {product.product_name}
          </span>
        </h6>

        <div className=" d-flex gap-1 p-1">
          <span>
            <FcCurrencyExchange className="vendorProduct-iconbar" />
          </span>
          <span>
            <span className="service-heading-vendor">Price : </span>
            {hasDiscount
              ? `${product.rate} - ${product.discount}% = ${discountedPrice}`
              : product.rate}
          </span>
        </div>

        {hasDiscount && (
          <div className=" d-flex gap-1 p-1">
            <span>
              <FcBookmark className="vendorProduct-iconbar" />
            </span>
            <span>
              <span className="service-heading-vendor">Discounted Price :</span>
              {discountedPrice}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VegetableProductCard;
