import * as Yup from 'yup';

export const vendorRegistrationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  phoneNumber: Yup.number()
  .required('Phone Number is required')
  .test('len', 'Phone Number must be exactly 10 digits', val => val && val.toString().length === 10),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  location: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  category: Yup.string().required('Category is required'),
  storename: Yup.string().required('Store Name is required'),
  description: Yup.string().required('Description is required'),
});


export const vendorLoginSchema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  phoneNumber: Yup.number()
    .required("Phone Number is required")
    .test(
      'len',
      'Phone Number must be exactly 10 digits',
      (val) => val && val.toString().length === 10
    ),
  password: Yup.string().required("Password is required"),
});


export const storeDetailsValidationSchema = Yup.object().shape({
  storename: Yup.string().required('Store Name is required'),
  reviews: Yup.number()
    .required('Reviews is required')
    .min(0, 'Minimum rating is 0')
    .max(5, 'Maximum rating is 5')
    .test('no-e', 'Only numbers are allowed', value => {
      if (typeof value === 'number') {
      
        return !String(value).includes('e');
      }
    
      return value !== 'e';
    }),
  serviceDetails: Yup.string().required('Service Details is required'),
  // price: Yup.number().required('Price is required'),
  location: Yup.string().required('Shop Location is required'),
  number: Yup.number()
  .required('Phone Number is required')
  .test('len', 'Phone Number must be exactly 10 digits', (val) => val && val.toString().length === 10)
  .typeError('Invalid phone number')
  .transform((value, originalValue) => {
   
    if (originalValue && originalValue.toString().length > 10) {
      return Number(originalValue.toString().slice(0, 10));
    }
    return value;
  }),
  direction: Yup.string().required('Google Maps Direction Link is required'),
});


export const professionalProfileValidationSchema = Yup.object().shape({
  professionalcategory: Yup.string().required('Professional Category is required'),
  reviews: Yup.number()
    .required('Reviews is required')
    .min(0, 'Minimum rating is 0')
    .max(5, 'Maximum rating is 5')
    .test('no-e', 'Only numbers are allowed', value => {
      if (typeof value === 'number') {
        return !String(value).includes('e');
      }
      return value !== 'e';
    }),
  price: Yup.number().required('Price is required'),
  serviceDetails: Yup.string().required('Service Details is required'),
  your_location: Yup.string().required('Your Location is required'),
  number: Yup.number()
    .required('Phone Number is required')
    .test('len', 'Phone Number must be exactly 10 digits', (val) => val && val.toString().length === 10)
    .typeError('Invalid phone number')
    .transform((value, originalValue) => {
      if (originalValue && originalValue.toString().length > 10) {
        return Number(originalValue.toString().slice(0, 10));
      }
      return value;
    }),
  direction: Yup.string().required('Google Maps Direction Link is required'),
  years_of_experience: Yup.number().required('Years of Experience is required'),
});


export const adminLoginValidation = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .required('Password is required'),
});


export const transportFormValidation=Yup.object().shape({
  name: Yup.string().required('Name is required'),
  contactNumber: Yup.string()
  .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
  .required('Contact Number is required'),
  emailAddress: Yup.string().email('Invalid email address').required('Email Address is required'),
  address: Yup.string().required('Address is required'),
  mapLocation: Yup.string().required('Google Map Location is required'),
  city: Yup.string().required('City is required'),
  description: Yup.string().required('Description is required'),
})

export const transportProfileFormValidation = Yup.object({
  vehicleType: Yup.string().required('Vehicle Type is required'),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
  vehicleRegistrationNumber: Yup.string().required('Vehicle Registration Number is required'),
  yearsOfDrivingExperience: Yup.number().required('Years of Driving Experience is required'),
  daysAndHoursAvailable: Yup.string().required('Days and Hours Available is required'),
});