import React, { useState } from "react";
import axios from "axios";
import { MdOutlineSmartphone } from "../../../../imports";
import "../../../Modals/SuccessModal.css";
import { BASE_URL } from "../../../../env";
const CallRequestModal = ({ onClose }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    if (/^\d{10}$/.test(phoneNumber)) {
      try {
        const response = await axios.post(`${BASE_URL}/addPhoneNumber`, {
          phoneNumber: phoneNumber,
        });

        if (response.status === 200) {
          console.log("Phone number added successfully:", phoneNumber);
          setSuccessMessage(
            "Request Submitted Successfully Our team will contact you within 24 hours."
          );
        } else {
          console.error("Failed to add phone number:", response.data.message);
          setErrorMessage("Failed to add phone number. Please try again.");
        }
      } catch (error) {
        console.error("Error adding phone number:", error.message);
        setErrorMessage("Error adding phone number. Please try again.");
      }
    } else {
      setErrorMessage("Please enter a valid 10-digit phone number.");
    }
  };

  return (
    <div className="modal-centering">
      <div className="modal-dialog modal-confirm">
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
              <MdOutlineSmartphone className="modal-icon" />
            </div>
          </div>
          <div className="modal-body">
            {successMessage ? (
              <div>
                <p className="text-justify">{successMessage}</p>
                <button
                  className="btn btn-success btn-block w-100 mt-4"
                  onClick={() => onClose()}
                >
                  Done
                </button>
              </div>
            ) : (
              <>
                <p className="text-justify">
                  We kindly request a valid contact number for further
                  assistance.
                </p>
                <input
                  type="text"
                  className="form-control border mt-4"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  placeholder="Mobile number"
                />
                {errorMessage && <div className="error">{errorMessage}</div>}
              </>
            )}
          </div>
          <div className="modal-footer">
            {!successMessage && (
              <button
                className="btn btn-success btn-block w-100"
                onClick={handleSubmit}
              >
                Done
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallRequestModal;
