import React from 'react'
import "./ResponseModal.css";

const ResponseModal = ({ isOpen, onClose, message, buttonText, imageUrl }) => {
  return (
    <div tabIndex="-1" role="dialog" style={{ display: isOpen ? 'block' : 'none',backgroundColor:"#fff" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <img src={imageUrl} alt="Response Image" />
            <p>{message}</p>
            <button onClick={onClose}>{buttonText}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResponseModal