import React from 'react';
import "../../Modals/ResponseModal.css";
import { useVendorAuth } from '../VendorAuthContext';
import axios from 'axios';
import { BASE_URL } from '../../../env';

const ProfessionalStoryPreviewModal = ({ file, onCancel }) => {
    const { vendorUser } = useVendorAuth();

    const handleUpload = () => {
        const formData = new FormData();
        formData.append('user_id', vendorUser.id);
        formData.append('story', file);
    
        axios.post(`${BASE_URL}/professional/story/upload`, formData)
          .then(response => {
    
            console.log('Upload successful:', response.data);
            onCancel()
    
          })
          .catch(error => {
    
            console.error('Upload failed:', error);
          });
      };
  return (
    <div className='vegetable-story-modal-overlay'>
    
    <div className='story-preview-content'>
      <p className='modal-heading-story'>Preview Your Story</p>
      {file.type.includes('image') ? (
        <img
          src={URL.createObjectURL(file)}
          alt="Selected Image Preview"
          className="story-preview-image mx-auto img-fluid"
          
        />
      ) : (
        <div>
          <video
            controls
            className='story-video-preview mx-auto'
          >
            <source src={URL.createObjectURL(file)} type={file.type} />
            Your browser does not support the video tag.
          </video>
     
        </div>
      )}
     
     <div className='mt-2 d-flex justify-content-center' >
    <button className='store-pto-replace' onClick={onCancel}>Cancel</button>
        <button className='mx-3 store-img-cls' onClick={handleUpload}>Upload</button>
      </div>
    </div>
  
  </div>
  )
}

export default ProfessionalStoryPreviewModal