import React from 'react'
import "./ResponseModal.css";
import ProfileImg from "../../assets/image/stores/vendor-profile.png"

const VendorProfileMessageModal = ({ onClose }) => {
  return (
    <div className='vegetable-modal-overlay'>
      <div className='vegetable-modal-content'>
        <img src={ProfileImg} />

        <p className="vegetable-modal-para">

        "Your registered information will be prominently featured on your professional
        card within the designed interface. To ensure accuracy, 
        kindly update your details as needed. Additionally,
         you have the flexibility to enrich your profile by
         incorporating engaging stories and making edits.
          Enhance your card further by adding new products and services
           to showcase your professional offerings."
        </p>
        <button onClick={onClose}>Done</button>
      </div>
    </div>
  )
}

export default VendorProfileMessageModal