import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../../../env";
import axios from "axios";

export default function EditMainCategoryModal({ selectedcategory, onCancel, fetchCategories }) {
  const [name, setName] = useState(selectedcategory.vegcategory);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showSelectedImageModal, setShowSelectedImageModal] = useState(false);

  const handleSave = async () => {
    try {
      await axios.put(`${BASE_URL}/category/updateName`, {
        categoryId: selectedcategory.id,
        newName: name,
      });
  
      fetchCategories();
      onCancel();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };
  

  const handleImageChange = async () => {
    try {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = "image/*";
      fileInput.onchange = async (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
        setShowSelectedImageModal(true);
      };
      fileInput.click();
    } catch (error) {
      console.error("Error selecting image:", error);
    }
  };

  
  const handleImageUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("replace_img", uploadedFile);
      formData.append("category_id", selectedcategory.id);
      formData.append("selected_image_path", selectedcategory.image_name);

      await axios.post(`${BASE_URL}/vendor/maincategoryImgReplace`, formData);

      setUploadedFile(null);
      setShowSelectedImageModal(false);
      fetchCategories();
      onCancel();
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <>
      <Modal
        show={showSelectedImageModal}
        onHide={() => setShowSelectedImageModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="add-martcategory-title">
            Selected Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadedFile && (
            <img
              src={URL.createObjectURL(uploadedFile)}
              className="img-fluid"
              alt="Selected"
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-danger"
            onClick={() => setShowSelectedImageModal(false)}
          >
            Close
          </Button>
          <Button className="bg-primary" onClick={handleImageUpload}>
            Upload Image
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="add-martcategory-label">Category Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-control"
          />
          <label className="add-martcategory-label">Category Image</label>
          <img
            src={`${BASE_URL}/vegetable/category/images/${selectedcategory.image_name}`}
            alt={selectedcategory.vegcategory}
            className="img-fluid mt-2"
          />
          <Button className="mt-2 bg-warning" onClick={handleImageChange}>
            Replace Image
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button className="bg-danger" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="bg-primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
