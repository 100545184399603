import React from 'react';
import "./ResponseModal.css";


const ConfirmationModal = ({ message, onConfirm, onClose }) => {
  return (
    <div className='vegetable-modal-overlay'>
      <div className='vegetable-modal-content'>
        <p className="vegetable-modal-para">
          <p>{message}</p>
        </p>
        <button onClick={onClose} style={{ backgroundColor: "red" }}>No</button>
        <button onClick={() => { onConfirm(); onClose(); }} className='mx-2'>Yes</button>
      </div>
    </div>
  );
};




export default ConfirmationModal;
