import React, {useState} from 'react';
// import Navbar from "./components/Navbar";
import AdminSidebar from './AdminSidebar';
import {  Route,Navigate,Link } from "react-router-dom";
// import "./style/index.css";

function AdminSidebarMain() {
	const [navVisible, showNavbar] = useState(false);
// console.log(showNavbar,"adminsiden");
	return (
		
			<div >
            <AdminSidebar visible={navVisible} show={showNavbar} />
				
					<Link path="/" element={<Navigate to="/dashboard" />} />
					<Link path='/dashboard' element={
						<div className={!navVisible ? "page" : "page page-with-navbar"}>
							<h1>Dashboard</h1>
						</div>
					} />
					<Link path='/analytics' element={
						<div className={!navVisible ? "page" : "page page-with-navbar"}>
							<h1>Analystics</h1>
						</div>
					}/>
					<Link path='/orders' element={
						<div className={!navVisible ? "page" : "page page-with-navbar"}>
							<h1>Orders</h1>
						</div>
					}/>
					<Link path='/settings' element={
						<div className={!navVisible ? "page" : "page page-with-navbar"}>
							<h1>Settings</h1>
						</div>
					}/>
				
			</div>
		
  );
}

export default AdminSidebarMain;
