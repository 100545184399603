import React, { useState, useEffect } from "react";
import "../../../Modals/SuccessModal.css";
import {
  veg1,
  RiAccountCircleFill,
  MdOutlineSmartphone,
  RiLockPasswordFill,
  MdEmail,
  OtpVerifyModal,
} from "../../../../imports";
import { BASE_URL } from "../../../../env";
import axios from "axios";

const VendorUserAccountModal = ({ onClose, vendorInfo }) => {
  const [password, setPassword] = useState(vendorInfo.password);
  const [verifiedOtp, setVerifiedOtp] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [vegatableDetails, setVegetableDetails] = useState(null);

  const fetchVegtableData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/vegetablestore/profile/detail/${vendorInfo.id}`
      );

      //  console.log("response from vendoruser ",response.data)
      setVegetableDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchVegtableData();
  }, [vendorInfo.id]);
  const handleOtpSending = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/vegetable/vendor/request-password-reset`,
        {
          email: vegatableDetails.email,
        }
      );

      if (response.status === 200) {
        setShowOtpModal(true);
      } else {
        console.error("Error requesting OTP:", response.statusText);
      }
    } catch (error) {
      console.error("Error requesting OTP:", error.message);
    }
  };

  const handleVerifyOtp = async (otp) => {
    try {
      const verifyResponse = await axios.post(
        `${BASE_URL}/vegetable/vendor/verify-otp`,
        {
          email: vegatableDetails.email,
          otp: otp,
        }
      );

      if (verifyResponse.status === 200) {
        setVerifiedOtp(otp);
        setShowOtpModal(false);
        setShowChangePasswordModal(true);
      } else {
        console.error("Error verifying OTP:", verifyResponse.statusText);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error.message);
    }
  };

  const handlePasswordInputChange = (e) => {
    const inputPassword = e.target.value;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    const isValidPassword = passwordRegex.test(inputPassword);

    setPasswordStrength(
      isValidPassword ? "Password is strong!" : "Password is Weak"
    );

    setNewPassword(inputPassword);
  };

  const handleSavePassword = async () => {
    try {
      const response = await axios.put(
        `${BASE_URL}/vegatable/change/password`,
        {
          driverId: vegatableDetails.id,
          newPassword: newPassword,
        }
      );

      if (response.status === 200) {
        fetchVegtableData();
        setPassword(newPassword);
        setShowChangePasswordModal(false);
      } else {
        console.error("Error updating password:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating password:", error.message);
    }
  };

  return (
    <div className="">
      <div className="vendor-account-overlay ">
        <div
          className=" vendor-account-modal "
          style={{ backgroundColor: "#fff" }}
        >
          <div class="account-ds-top"></div>
          <div className="account-vendor-profile">
            {vegatableDetails && (
              <img
                src={`${BASE_URL}/vegetable/posterimage/${vegatableDetails.posterImage}`}
                alt="Vendor Poster Image"
                className="img-fluid"
              />
            )}
          </div>
          {vegatableDetails && (
            <div className="vendor-name">
              <p className="vendor-store-name">{vegatableDetails.storename}</p>
              <div className="vendor-acccout-detail">
                <p
                  className="d-flex align-items-center "
                  style={{ fontSize: "16px" }}
                >
                  <RiAccountCircleFill style={{ color: "var(--blue)" }} />{" "}
                  <span className="vendor-account-personal mx-2">Name :</span>{" "}
                  {vegatableDetails.name}
                </p>
                <p
                  className="d-flex align-items-center mt-3"
                  style={{ fontSize: "16px" }}
                >
                  <MdEmail style={{ color: "var(--blue)" }} />{" "}
                  <span className="vendor-account-personal mx-2">Email :</span>{" "}
                  {vegatableDetails.email}
                </p>
                <p
                  className="d-flex align-items-center mt-3"
                  style={{ fontSize: "16px" }}
                >
                  <MdOutlineSmartphone style={{ color: "var(--blue)" }} />{" "}
                  <span className="vendor-account-personal mx-2">Phone:</span>{" "}
                  {vegatableDetails.phoneNumber}
                </p>
                <p
                  className="d-flex align-items-center mt-3"
                  style={{ fontSize: "16px" }}
                >
                  <RiLockPasswordFill style={{ color: "var(--blue)" }} />{" "}
                  <span className="vendor-account-personal mx-2">
                    Password:
                  </span>{" "}
                  {vegatableDetails.password}
                </p>

                <p
                  className="mt-2 acccount-change-password"
                  style={{ fontSize: "16px", color: "var(--blue)" }}
                  onClick={handleOtpSending}
                >
                  <ins>Change Password ?</ins>
                </p>
                <button
                  className="mt-5 btn bg-primary text-white"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {showOtpModal && (
        <OtpVerifyModal
          onClose={() => setShowOtpModal(false)}
          onVerify={handleVerifyOtp}
        />
      )}
      {showChangePasswordModal && (
        <div
          className="modal fade show"
          role="dialog"
          style={{ display: "block", zIndex: 10000 }}
        >
          <div className="modal-dialog modal-dialog-centered ">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Change Password</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowChangePasswordModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="newPassword">New Password:</label>
                  <input
                    type="text"
                    className="form-control"
                    id="newPassword"
                    value={newPassword}
                    onChange={handlePasswordInputChange}
                  />
                  {passwordStrength && (
                    <p
                      className={
                        passwordStrength.includes("strong")
                          ? "text-success"
                          : "text-danger"
                      }
                    >
                      {passwordStrength}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn bg-danger text-white"
                  onClick={() => setShowChangePasswordModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn bg-primary text-white"
                  onClick={() => handleSavePassword()}
                  disabled={!passwordStrength.includes("strong")}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorUserAccountModal;
