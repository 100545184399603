import React from 'react'
import Dashboard from './Dashboard';
import './AdminDashboard.css';


const AdminDashboard = () => {
  return (
    <div>

      <Dashboard/>
    </div>
  )
}

export default AdminDashboard